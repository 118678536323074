const OPEN_BANKS = 'OPEN_BANKS';
const SELECT_BANK = 'SELECT_BANK';
const GO_BACK = 'GO_BACK';
const GO_HOME = 'GO_HOME';
const CLOSE_WIDGET = 'CLOSE_WIDGET';

const LINK_INTENT_CREATE_SUCCEEDED = 'LINK_INTENT_CREATE_SUCCEEDED';
const LINK_INTENT_CREATE_FAILED = 'LINK_INTENT_CREATE_FAILED';
const RETRY_LINK_INTENT = 'RETRY_LINK_INTENT';
const LINK_INTENT_SUCCESSFUL = 'LINK_INTENT_SUCCESSFUL';
const LINK_INTENT_FAILED = 'LINK_INTENT_FAILED';
const LINK_INTENT_APP_AUTH_REQUIRED = 'LINK_INTENT_APP_AUTH_REQUIRED';
const LINK_INTENT_CAPTCHA_AUTH_REQUIRED = 'LINK_INTENT_CAPTCHA_AUTH_REQUIRED';
const LINK_INTENT_DEVICE_AUTH_REQUIRED = 'LINK_INTENT_DEVICE_AUTH_REQUIRED';
const LINK_INTENT_CARD_AUTH_REQUIRED = 'LINK_INTENT_CARD_AUTH_REQUIRED';
const LINK_INTENT_SMS_AUTH_REQUIRED = 'LINK_INTENT_SMS_AUTH_REQUIRED';
const LOADING_LINK_INTENT_SUCCEEDED = 'LOADING_LINK_INTENT_SUCCEEDED';
const LOADING_LINK_INTENT_FAILED = 'LOADING_LINK_INTENT_FAILED';

const SUBSCRIPTION_CONFIRMED_SUCCEEDED = 'SUBSCRIPTION_CONFIRMED_SUCCEEDED';
const SUBSCRIPTION_CONFIRMED_FAILED = 'SUBSCRIPTION_CONFIRMED_FAILED';
const LOADING_SUBSCRIPTION_SUCCEEDED = 'LOADING_SUBSCRIPTION_SUCCEEDED';
const LOADING_SUBSCRIPTION_FAILED = 'LOADING_SUBSCRIPTION_FAILED';
const SUBSCRIPTIONS_NO_RETRY_ERROR = 'SUBSCRIPTIONS_NO_RETRY_ERROR';
const SUBSCRIPTION_APP_AUTH_REQUIRED = 'SUBSCRIPTION_APP_AUTH_REQUIRED';
const SUBSCRIPTION_DEVICE_AUTH_REQUIRED = 'SUBSCRIPTION_DEVICE_AUTH_REQUIRED';
const SUBSCRIPTION_CARD_AUTH_REQUIRED = 'SUBSCRIPTION_CARD_AUTH_REQUIRED';
const SUBSCRIPTION_SMS_AUTH_REQUIRED = 'SUBSCRIPTION_SMS_AUTH_REQUIRED';
const SECOND_FACTOR_SUCCEEDED = 'SECOND_FACTOR_SUCCEEDED';
const SECOND_FACTOR_FAILED = 'SECOND_FACTOR_FAILED';
const WAITING_ACTION_SUCCEEDED = 'WAITING_ACTION_SUCCEEDED';
const WAITING_ACTION_FAILED = 'WAITING_ACTION_FAILED';
const PROCESSING_ACTION_SUCCEEDED = 'PROCESSING_ACTION_SUCCEEDED';
const PROCESSING_ACTION_FAILED = 'PROCESSING_ACTION_FAILED';

const PAYMENTS_NO_RETRY_ERROR = 'PAYMENTS_NO_RETRY_ERROR';
const PAYMENTS_INSUFFICIENT_FUNDS_FAILED = 'PAYMENTS_INSUFFICIENT_FUNDS_FAILED';
const PAYMENTS_ACCOUNT_SUCCEEDED = 'PAYMENTS_ACCOUNT_SUCCEEDED';
const PAYMENTS_ACCOUNT_FAILED = 'PAYMENTS_ACCOUNT_FAILED';
const PAYMENTS_CONFIRMED_SUCCEEDED = 'PAYMENTS_CONFIRMED_SUCCEEDED';
const PAYMENTS_CONFIRMED_FAILED = 'PAYMENTS_CONFIRMED_FAILED';

const PROCESSING_PAYMENTS_SUCCEEDED = 'PROCESSING_PAYMENTS_SUCCEEDED';
const PROCESSING_PAYMENTS_FAILED = 'PROCESSING_PAYMENTS_FAILED';
const PAYMENTS_SELECTING_AUTH = 'PAYMENTS_SELECTING_AUTH';
const PAYMENTS_SELECTING_SUCCEEDED = 'PAYMENTS_SELECTING_SUCCEEDED';
const PAYMENTS_SELECTING_FAILED = 'PAYMENTS_SELECTING_FAILED';
const PAYMENTS_APP_AUTH_REQUIRED = 'PAYMENTS_APP_AUTH_REQUIRED';
const PAYMENTS_DEVICE_AUTH_REQUIRED = 'PAYMENTS_DEVICE_AUTH_REQUIRED';
const PAYMENTS_CARD_AUTH_REQUIRED = 'PAYMENTS_CARD_AUTH_REQUIRED';
const PAYMENTS_SMS_AUTH_REQUIRED = 'PAYMENTS_SMS_AUTH_REQUIRED';
const PAYMENTS_EMAIL_AUTH_REQUIRED = 'PAYMENTS_EMAIL_AUTH_REQUIRED';
const PAYMENTS_CREATED = 'PAYMENTS_CREATED';
const PAYMENT_DATA_VALIDATION_FOR_SELECTED_BANK_FAILED = 'PAYMENT_DATA_VALIDATION_FOR_SELECTED_BANK_FAILED';

const OPEN_FISCAL_AUTHORITIES = 'OPEN_FISCAL_AUTHORITIES';
const SELECT_FISCAL_AUTHORITY = 'SELECT_FISCAL_AUTHORITY';

const REFRESH_INTENT_SUCCESSFUL = 'REFRESH_INTENT_SUCCESSFUL';
const REFRESH_INTENT_FAILED = 'REFRESH_INTENT_FAILED';
const REFRESH_INTENT_APP_AUTH_REQUIRED = 'REFRESH_INTENT_APP_AUTH_REQUIRED';
const REFRESH_INTENT_CAPTCHA_AUTH_REQUIRED = 'REFRESH_INTENT_CAPTCHA_AUTH_REQUIRED';
const REFRESH_INTENT_DEVICE_AUTH_REQUIRED = 'REFRESH_INTENT_DEVICE_AUTH_REQUIRED';
const REFRESH_INTENT_CARD_AUTH_REQUIRED = 'REFRESH_INTENT_CARD_AUTH_REQUIRED';
const REFRESH_INTENT_SMS_AUTH_REQUIRED = 'REFRESH_INTENT_SMS_AUTH_REQUIRED';

const OPEN_TOKEN_HELPER = 'OPEN_TOKEN_HELPER';
const OPEN_NEW_PASSWORD_HELPER = 'OPEN_NEW_PASSWORD_HELPER';
const OPEN_RESET_PASSWORD_HELPER = 'OPEN_RESET_PASSWORD_HELPER';

export default {
  OPEN_BANKS,
  SELECT_BANK,
  LINK_INTENT_CREATE_SUCCEEDED,
  LINK_INTENT_CREATE_FAILED,
  LOADING_SUBSCRIPTION_FAILED,
  LOADING_SUBSCRIPTION_SUCCEEDED,
  SUBSCRIPTION_APP_AUTH_REQUIRED,
  RETRY_LINK_INTENT,
  LINK_INTENT_SUCCESSFUL,
  LINK_INTENT_FAILED,
  GO_BACK,
  GO_HOME,
  CLOSE_WIDGET,
  SUBSCRIPTION_CARD_AUTH_REQUIRED,
  SUBSCRIPTION_CONFIRMED_SUCCEEDED,
  SUBSCRIPTION_CONFIRMED_FAILED,
  SUBSCRIPTION_DEVICE_AUTH_REQUIRED,
  SUBSCRIPTION_SMS_AUTH_REQUIRED,
  SUBSCRIPTIONS_NO_RETRY_ERROR,
  SECOND_FACTOR_SUCCEEDED,
  SECOND_FACTOR_FAILED,
  WAITING_ACTION_SUCCEEDED,
  WAITING_ACTION_FAILED,
  PROCESSING_ACTION_SUCCEEDED,
  PROCESSING_ACTION_FAILED,
  OPEN_FISCAL_AUTHORITIES,
  SELECT_FISCAL_AUTHORITY,
  PAYMENTS_NO_RETRY_ERROR,
  PAYMENTS_INSUFFICIENT_FUNDS_FAILED,
  PAYMENTS_ACCOUNT_SUCCEEDED,
  PAYMENTS_ACCOUNT_FAILED,
  PAYMENTS_CONFIRMED_SUCCEEDED,
  PAYMENTS_CONFIRMED_FAILED,
  PROCESSING_PAYMENTS_SUCCEEDED,
  PROCESSING_PAYMENTS_FAILED,
  PAYMENTS_SELECTING_AUTH,
  PAYMENTS_SELECTING_SUCCEEDED,
  PAYMENTS_SELECTING_FAILED,
  PAYMENTS_APP_AUTH_REQUIRED,
  PAYMENTS_DEVICE_AUTH_REQUIRED,
  PAYMENTS_CARD_AUTH_REQUIRED,
  PAYMENTS_SMS_AUTH_REQUIRED,
  PAYMENTS_EMAIL_AUTH_REQUIRED,
  PAYMENTS_CREATED,
  PAYMENT_DATA_VALIDATION_FOR_SELECTED_BANK_FAILED,
  LINK_INTENT_APP_AUTH_REQUIRED,
  LINK_INTENT_CAPTCHA_AUTH_REQUIRED,
  LINK_INTENT_DEVICE_AUTH_REQUIRED,
  LINK_INTENT_CARD_AUTH_REQUIRED,
  LINK_INTENT_SMS_AUTH_REQUIRED,
  LOADING_LINK_INTENT_SUCCEEDED,
  LOADING_LINK_INTENT_FAILED,
  REFRESH_INTENT_SUCCESSFUL,
  REFRESH_INTENT_FAILED,
  REFRESH_INTENT_APP_AUTH_REQUIRED,
  REFRESH_INTENT_CAPTCHA_AUTH_REQUIRED,
  REFRESH_INTENT_DEVICE_AUTH_REQUIRED,
  REFRESH_INTENT_CARD_AUTH_REQUIRED,
  REFRESH_INTENT_SMS_AUTH_REQUIRED,
  OPEN_TOKEN_HELPER,
  OPEN_NEW_PASSWORD_HELPER,
  OPEN_RESET_PASSWORD_HELPER,
};
