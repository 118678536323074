<template>
  <widget-layout>
    <div class="outer-container px-4">
      <div class="flex flex-col h-full items-center justify-center space-y-8 px-4">
        <EntityLogo />
        <Error class="min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />
        <div id="seTitleText" class="font-M font-700 color-txt-heading text-center">
          {{ errorTitle }}
        </div>
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Comenzar de nuevo"
          @click.native="transition()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import Error from '../icons/Error.vue';
import EntityLogo from '../layout/EntityLogo.vue';
import { buildErrorMessage } from '../../utils/subscription/SubscriptionIntentError.constants';

export default {
  methods: {
    ...mapActions(['transitionToNextState']),
    transition() {
      this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
    },
  },
  computed: {
    ...mapState({
      errorType: (state) => state.widget.errorType,
    }),
    errorMessageData() {
      return {
        errorType: this.errorType,
      };
    },
    errorTitle() {
      return buildErrorMessage(this.errorMessageData).title;
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Error,
    EntityLogo,
  },
};
</script>
