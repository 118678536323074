<template>
  <div>
    <div
      v-if="!anonimizedEntities"
      :is="currentComponent"
      class="w-40 min-w-40 h-16 min-h-16"
    />
    <anonimized-logo v-if="anonimizedEntities"/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AnonimizedLogo from './AnonimizedEntityLogo.vue';
import Azteca from '../icons/Banks/Azteca.vue';
import Banamex from '../icons/Banks/BanamexInverted.vue';
import Banorte from '../icons/Banks/Banorte.vue';
import Bbva from '../icons/Banks/BbvaInverted.vue';
import Bci from '../icons/Banks/Bci.vue';
import Bci360 from '../icons/Banks/Bci360.vue';
import BancoDeChileBiz from '../icons/Banks/BancoDeChileBusiness.vue';
import BancoDeChileInd from '../icons/Banks/BancoDeChileIndividual.vue';
import Bice from '../icons/Banks/Bice.vue';
import Estado from '../icons/Banks/Estado.vue';
import Falabella from '../icons/Banks/Falabella.vue';
import HeyBanco from '../icons/Banks/HeyBanco.vue';
import Hsbc from '../icons/Banks/Hsbc.vue';
import Itau from '../icons/Banks/Itau.vue';
import Ripley from '../icons/Banks/Ripley.vue';
import Santander from '../icons/Banks/Santander.vue';
import SantanderPyme from '../icons/Banks/SantanderPyme.vue';
import Scotiabank from '../icons/Banks/Scotiabank.vue';
import Security from '../icons/Banks/Security.vue';
import Sii from '../icons/FiscalAuthority/Sii.vue';
import Sat from '../icons/FiscalAuthority/Sat.vue';

export default {
  computed: {
    ...mapState({
      anonimizedEntities: (state) => state.config.anonimizedEntities,
      holderType: (state) => state.config.holderType,
      entityId: (state) => state.widget.entityIdSelected || state.refreshIntent.institutionId,
    }),
    currentComponent() {
      switch (this.entityId) {
        case 'cl_banco_bci': {
          return 'bci';
        }
        case 'cl_banco_bci_360': {
          return 'bci-360';
        }
        case 'cl_banco_bice': {
          return 'bice';
        }
        case 'cl_banco_de_chile': {
          if (this.holderType === 'BUSINESS') return 'banco-de-chile-biz';
          return 'banco-de-chile-ind';
        }
        case 'cl_banco_estado': {
          return 'estado';
        }
        case 'cl_banco_falabella': {
          return 'falabella';
        }
        case 'cl_banco_itau': {
          return 'itau';
        }
        case 'cl_banco_ripley': {
          return 'ripley';
        }
        case 'cl_banco_santander': {
          return 'santander';
        }
        case 'cl_banco_santander_pyme': {
          return 'santander-pyme';
        }
        case 'cl_banco_scotiabank': {
          return 'scotiabank';
        }
        case 'cl_banco_security': {
          return 'security';
        }
        case 'cl_fiscal_sii': {
          return 'sii';
        }
        case 'mx_banco_banamex': {
          return 'banamex';
        }
        case 'mx_banco_bbva': {
          return 'bbva';
        }
        case 'mx_hey_banco': {
          return 'hey-banco';
        }
        case 'mx_banco_azteca': {
          return 'azteca';
        }
        case 'mx_banco_santander': {
          return 'santander';
        }
        case 'mx_banco_hsbc': {
          return 'hsbc';
        }
        case 'mx_banco_banorte': {
          return 'banorte';
        }
        case 'mx_fiscal_sat': {
          return 'sat';
        }
        default: {
          return 'anonimizedLogo';
        }
      }
    },
  },
  components: {
    AnonimizedLogo,
    Azteca,
    Banamex,
    Banorte,
    Bbva,
    BancoDeChileBiz,
    BancoDeChileInd,
    Bice,
    Bci,
    Bci360,
    Estado,
    Falabella,
    HeyBanco,
    Hsbc,
    Itau,
    Ripley,
    Santander,
    SantanderPyme,
    Scotiabank,
    Security,
    Sii,
    Sat,
  },
};
</script>
