import states from '../constants/states';

export default function buildEventMessage(eventType) {
  switch (eventType) {
    case states.INTRODUCTION:
      return 'opened';
    case states.BANK_SELECTION:
      return 'on_available_institutions';
    case states.FISCAL_AUTHORITIES:
      return 'on_available_institutions';
    case states.LOGIN:
      return 'on_authentication_form';
    case states.LOADING_LINK_INTENT:
      return 'creating_link';
    case states.LINK_CREATED:
      return 'link_created';
    case states.WIDGET_CLOSED:
      return 'closed';
    case states.SUBSCRIPTION_CONFIRMATION_REQUIRED:
      return 'selecting_account';
    case states.LOADING_SUBSCRIPTION:
      return 'creating_subscription';
    case states.APP_AUTH_REQUIRED:
      return 'app_authentication_required';
    case states.CAPTCHA_AUTH_REQUIRED:
      return 'captcha_authentication_required';
    case states.DEVICE_AUTH_REQUIRED:
      return 'device_authentication_required';
    case states.CARD_AUTH_REQUIRED:
      return 'card_authentication_required';
    case states.SMS_AUTH_REQUIRED:
      return 'sms_authentication_required';
    case states.EMAIL_AUTH_REQUIRED:
      return 'email_authentication_required';
    case states.SUBSCRIPTION_CREATED:
      return 'subscription_created';
    case states.SUBSCRIPTION_ABORTED:
      return 'subscription_aborted';
    case states.SUBSCRIPTION_ERROR:
      return 'on_error';
    case states.LINK_INTENT_PROCESSING_ACTION:
      return 'validating_second_factor';
    case states.REFRESH_INTENT_CREATED:
      return 'refresh_intent_created';
    case states.REFRESH_INTENT_PROCESSING_ACTION:
      return 'validating_second_factor';
    case states.REFRESH_INTENT_FETCHING_DATA:
      return 'fetching_institution_data';
    case states.REFRESH_INTENT_SUCCEEDED:
      return 'refresh_intent_succeeded';
    case states.REFRESH_INTENT_ERROR:
      return 'refresh_intent_error';
    case states.PAYMENTS_PROCESSING_ACTION:
      return 'validating_second_factor';
    case states.SUBSCRIPTION_PROCESSING_ACTION:
      return 'validating_second_factor';
    case states.ERROR_VIEW:
      return 'on_error';
    case states.PAYMENTS_CONFIRMATION_REQUIRED:
      return 'payment_intent_confirmation_required';
    case states.PROCESSING_PAYMENTS:
      return 'payment_in_progress';
    case states.PAYMENTS_CREATED:
      return 'payment_created';
    case states.PAYMENTS_ERROR:
      return 'payment_error';
    case states.PAYMENTS_SELECTING_AUTH:
      return 'payment_selecting_auth';
    case states.PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK:
      return 'payment_invalid_data_for_selected_bank';
    case states.TOKEN_HELPER:
      return 'token_helper';
    case states.NEW_PASSWORD_HELPER:
      return 'new_password_helper';
    case states.RESET_PASSWORD_HELPER:
      return 'reset_password_helper';
    default:
      throw new Error(`Invalid state: ${eventType}. Add event to parser.`);
  }
}
