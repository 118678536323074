<template>
  <div
    @wheel='handleWheel'
    @touchstart='handleTouch'
  >
    <div
      id="fintoc-widget"
      class="fixed w-full h-full
            top-0 left-0 flex items-center justify-center"
    >
      <Overlay class="z-0"/>
      <div v-if="!loaded" class="loader ease-linear
        rounded-full border-2 border-t-2 h-6 w-6" />
      <Widget v-else ref='widget'></Widget>
    </div>
    <Sandbox v-if="!isWindowHeightSmall" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Overlay from '../components/Overlay.vue';
import Sandbox from '../components/Sandbox.vue';
import Widget from '../components/Widget.vue';

export default {
  computed: {
    ...mapGetters(['widgetConfigurated', 'isWindowHeightSmall']),
    ...mapState({
      isOpen: (state) => state.widget.open,
    }),
    loaded() {
      return this.isOpen && this.widgetConfigurated;
    },
  },
  components: {
    Overlay,
    Sandbox,
    Widget,
  },
  methods: {
    mustAllowWheelScrollY(element, directionToTop) {
      if (directionToTop) {
        return element.scrollTop !== 0;
      }

      return element.scrollHeight - element.scrollTop > element.clientHeight;
    },
    handleWheel(event) {
      if (event.deltaY === 0) {
        return;
      }

      let mustAllow = false;
      let targetElement = event.target;
      const myElement = this.$el;
      const directionToTop = event.deltaY < 0;
      do {
        mustAllow = this.mustAllowWheelScrollY(targetElement, directionToTop);
        targetElement = targetElement.parentNode;
      } while (!myElement.isEqualNode(targetElement) && targetElement !== null && !mustAllow);

      if (!mustAllow) {
        event.preventDefault();
      }
    },
    isSingleTouch(event) {
      return event.targetTouches.length === 1;
    },
    handleTouch(event) {
      if (!this.isSingleTouch(event)) {
        return;
      }

      let mustAllow = false;
      let targetElement = event.target;
      const myElement = this.$el;
      const widgetElement = this.$refs.widget?.$el;

      do {
        mustAllow = widgetElement?.isEqualNode(targetElement);
        targetElement = targetElement.parentNode;
      } while (!myElement.isEqualNode(targetElement) && targetElement !== null && !mustAllow);

      if (!mustAllow) {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.loader {
  border-color: #475FF1;
  border-top-color: #6A8DF9;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
