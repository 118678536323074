import Vue from 'vue';
import Vuex from 'vuex';
import products from '../../sharedTypes/products';

Vue.use(Vuex);

const requireModule = require.context('.', false, /\.store\.js$/);
const modules = {};

requireModule.keys().forEach((filename) => {
  const moduleName = filename.replace(/(\.\/|\.store\.js)/g, '');
  modules[moduleName] = requireModule(filename).default || requireModule(filename);
});

export default new Vuex.Store({
  state: {
  },
  getters: {
    resourceStatus(_state, _getters, rootState) {
      const { product } = rootState.config;
      if (product === products.MOVEMENTS) return rootState.link.status;
      if (product === products.REFRESH_INTENT) return rootState.refreshIntent.status;
      return null;
    },
    resourceFailureReason(_state, _getters, rootState) {
      const { product } = rootState.config;
      if (product === products.MOVEMENTS) return rootState.link.reason;
      if (product === products.REFRESH_INTENT) return rootState.refreshIntent.errorType;
      return null;
    },
  },
  mutations: {
  },
  actions: {
    async getResource({ rootState, dispatch }) {
      const { product } = rootState.config;
      if (product === products.MOVEMENTS) dispatch('getLinkIntent');
      if (product === products.REFRESH_INTENT) dispatch('getRefreshIntent');
    },
  },
  modules,
});
