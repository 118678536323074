<template>
  <div class="w-full">
    <LegalTerms v-if="legalTerms" class="py-3 px-4"/>
    <PoweredBy v-else class="relative py-1 px-0 h-12 min-h-12 max-h-12 mx-auto" :class="{'cursor-pointer': isPayment}" @click.native="showFintocDrawer"/>
    <Sandbox v-if="isWindowHeightSmall"/>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import AnalyticsEvents from '../../constants/analytics_events';
import Tracking from '../../helpers/tracking';
import PoweredBy from '../icons/PoweredBy.vue';
import Sandbox from '../Sandbox.vue';
import LegalTerms from '../views.common/Home/LegalTerms.vue';

export default {
  props: {
    legalTerms: Boolean,
  },
  components: {
    PoweredBy,
    Sandbox,
    LegalTerms,
  },
  computed: {
    ...mapGetters(['isWindowHeightSmall', 'isPayment']),
  },
  methods: {
    ...mapActions(['toggleFintocDrawer']),
    showFintocDrawer() {
      if (this.isPayment) {
        Tracking.trackEvent(
          AnalyticsEvents.DRAWER_OPENED,
          { drawer: 'footer' },
        );
        this.toggleFintocDrawer();
      }
    },
  },
};
</script>
