import { decamelize } from 'humps';
import * as Sentry from '@sentry/vue';
import * as messages from '../widget_api/messages';
import utils from '../helpers/utils';
import api from '../api';
import products from '../../sharedTypes/products';
import optimizely from '../helpers/optimizely';
import { checkHexDark, hexToRGBA, hexify } from '../helpers/style';
import {
  FINTOC_PRIMARY_MAIN_COLOR,
  FINTOC_PRIMARY_SURFACE_COLOR,
  FINTOC_PRIMARY_FOCUS_COLOR,
  RAPPI_PRIMARY_COLOR,
  MIGRANTE_PRIMARY_COLOR,
  TENPO_PRIMARY_COLOR,
  ASESORSEGUROS_PRIMARY_COLOR,
  DALE_PRIMARY_COLOR,
} from '../constants/widget';

const initialState = {
  anonimizedEntities: true,
  requireAmountLimit: false,
  banks: null,
  callbackUrl: null,
  fiscalAuthorities: null,
  holderType: null,
  mode: null,
  product: null,
  productConfig: {},
  publicKey: null,
  selectedInstitution: null,
  selectedCountry: null,
  widgetToken: null,
  resourceId: null,
  username: null,
  organizationName: null,
  organizationId: null,
  mfaConfig: null,
  hotjarEnabled: false,
  variants: [],
  primaryColor: '',
};

const getters = {
  widgetConfigurated(state) {
    const callbackUrlIsNotNeeded = [products.PAYMENTS, products.SUBSCRIPTION_INTENTS].includes(state.product);
    return (
      utils.valueExists(state.holderType)
      && utils.valueExists(state.product)
      && utils.valueExists(state.publicKey)
      && (callbackUrlIsNotNeeded || utils.valueExists(state.callbackUrl))
      && utils.valueExists(state.mode)
      && (utils.valueExists(state.banks) || utils.valueExists(state.fiscalAuthorities))
    );
  },
  isPrimaryColorDark(state) {
    return checkHexDark(state.primaryColor);
  },
  isPrimaryMainFintoc(state) {
    return state.primaryColor === FINTOC_PRIMARY_MAIN_COLOR;
  },
  isSubscription(state) {
    return state.product === products.SUBSCRIPTION;
  },
  isSubscriptionIntent(state) {
    return state.product === products.SUBSCRIPTION_INTENTS;
  },
  isPayment(state) {
    return state.product === products.PAYMENTS;
  },
  isLinkIntent(state) {
    return state.product === products.MOVEMENTS;
  },
  isRefreshIntent(state) {
    return state.product === products.REFRESH_INTENT;
  },
};

const mutations = {
  loadConfigFrozenState(state, payload) {
    Object.assign(state, {
      otherBankCommission: 0,
      paymentMinimumAmount: 0,
      mfaConfig: {
        app: { }, sms: { }, device: { }, coordinates: { }, email: { authLength: 6 },
      },
      ...(payload || {}),
    });
  },
  configurateWidget(state, {
    holderType, mode, publicKey, webhookUrl, widgetToken, anonimizedEntities, requireAmountLimit, selectedInstitution, country, defaultCountry, username, companyName, productConfig, companyId, hotjarEnabled,
  }) {
    Object.assign(state, {
      holderType,
      mode,
      publicKey,
      callbackUrl: webhookUrl,
      anonimizedEntities,
      requireAmountLimit,
      username,
      widgetToken,
      resourceId: utils.getResourceId(widgetToken),
      organizationName: companyName,
      organizationId: companyId,
      productConfig,
      selectedCountry: defaultCountry,
      hotjarEnabled,
    });
    if (country !== undefined && country !== null && country !== '') {
      state.selectedCountry = country;
    }
    if (selectedInstitution !== undefined && selectedInstitution !== null && selectedInstitution !== '') {
      state.selectedInstitution = selectedInstitution;
    }
  },
  setProduct(state, product) {
    state.product = product;
  },
  setVariant(state, variant) {
    state.variants.push(variant);
  },
  getBanks(state, payload) {
    let banks = payload;
    if (state.anonimizedEntities) {
      banks = banks.filter((bank) => !bank.publicId.includes('bci'));
    }
    if (state.selectedCountry) {
      banks = banks.filter((bank) => bank.publicId.startsWith(state.selectedCountry));
    }
    Object.assign(state, { banks });
  },
  getFiscalAuthorities(state, payload) {
    let { fiscalAuthorities } = payload;
    if (state.selectedCountry) {
      fiscalAuthorities = fiscalAuthorities.filter((authority) => authority.publicId.startsWith(state.selectedCountry));
    }
    Object.assign(state, { fiscalAuthorities });
  },
  getProductBanksConfigs(state, payload) {
    const banksConfigs = {};
    Object.keys(payload).forEach((bankIdCamelCase) => {
      banksConfigs[decamelize(bankIdCamelCase)] = payload[bankIdCamelCase];
    });
    Object.assign(state, { banksConfigs });
  },
  setProductBankConfig(state, { entityIdSelected }) {
    const {
      app, sms, device, coordinates, email, commission, paymentMinimumAmount,
    } = state.banksConfigs[entityIdSelected];

    Object.assign(state, {
      mfaConfig: {
        app, sms, device, coordinates, email,
      },
      otherBankCommission: commission,
      paymentMinimumAmount,
    });
  },
  setPrimaryColors(state) {
    const primaryColors = {
      org_aQXRmZV6F2VZWoxd: RAPPI_PRIMARY_COLOR,
      org_DjWBZ0XFYOXZ48Nm: MIGRANTE_PRIMARY_COLOR,
      org_p8r3MWPF8gWvlq57: TENPO_PRIMARY_COLOR,
      org_jqdnMBOF5QLvgJxK: TENPO_PRIMARY_COLOR,
      eBw18kwJFrevzNLj: ASESORSEGUROS_PRIMARY_COLOR,
      org_oaOxM3EFxpxkmg30: DALE_PRIMARY_COLOR,
    };
    const primaryColor = primaryColors[state.organizationId] ?? FINTOC_PRIMARY_MAIN_COLOR;
    document.documentElement.style.setProperty('--color-primary-main', primaryColor);
    state.primaryColor = primaryColor;

    const isPrimaryMainFintoc = state.primaryColor === FINTOC_PRIMARY_MAIN_COLOR;
    if (isPrimaryMainFintoc) {
      document.documentElement.style.setProperty('--color-primary-surface', FINTOC_PRIMARY_SURFACE_COLOR);
      document.documentElement.style.setProperty('--color-primary-focus', FINTOC_PRIMARY_FOCUS_COLOR);
      document.documentElement.style.setProperty('--color-bg-account-info', FINTOC_PRIMARY_SURFACE_COLOR);
    } else {
      const surfaceRGBA = hexToRGBA(state.primaryColor, 0.1);
      const surfaceColor = hexify(surfaceRGBA);
      document.documentElement.style.setProperty('--color-primary-surface', surfaceColor);

      const focusRGBA = hexToRGBA(state.primaryColor, 0.15);
      const focusColor = hexify(focusRGBA);
      document.documentElement.style.setProperty('--color-primary-focus', focusColor);

      const isPrimaryColorDark = checkHexDark(state.primaryColor);
      const accountInfoAlpha = isPrimaryColorDark ? 0.05 : 0.1;
      const accountInfoRGBA = hexToRGBA(state.primaryColor, accountInfoAlpha);
      const accountInfoBGColor = hexify(accountInfoRGBA);
      document.documentElement.style.setProperty('--color-bg-account-info', accountInfoBGColor);
    }
  },
};

const actions = {
  async configurateWidget({ commit, dispatch, state }, payload) {
    let responseAuth;
    try {
      responseAuth = await api.auth.get(payload);
    } catch (err) {
      Sentry.captureException(err);
      const { error } = err.response.data;
      if (error.code === 'invalid_api_key') {
        throw new Error('Invalid API Key');
      }
    }

    const responseConfig = await api.config.getConfig(payload);
    const product = responseConfig.data.product || payload.product;

    if (product === 'PAYMENTS') {
      const { publicKey, widgetToken } = payload;
      const response = await api.payments.getStatus({
        widgetToken,
        publicKey,
      });
      commit('getPaymentStatus', response.data);
      const paymentIntentResponse = await api.payments.get({ widgetToken, publicKey });
      commit('getPaymentIntent', paymentIntentResponse.data);
    }

    optimizely.init({
      userId: responseConfig.data.userId,
      companyName: responseConfig.data.companyName,
      defaultCountry: responseConfig.data.defaultCountry,
      product,
    });

    commit('setProduct', product);
    commit('configurateWidget', {
      ...payload, ...responseAuth.data, ...responseConfig.data,
    });

    commit('setVariant', { name: 'progressBarVariant', value: optimizely.progressBarVariant });

    window.analytics.identify(responseConfig.data.userId);

    if (['PAYMENTS'].includes(product)) {
      await dispatch('getProductBanksConfigs');
    }

    if (payload.selectedInstitution !== null && payload.selectedInstitution !== undefined) {
      commit('updateEntitySelected', { id: payload.selectedInstitution });
      if (['PAYMENTS'].includes(product)) {
        dispatch('setProductBankConfig');
      }
    }

    dispatch('setStateMachine', { product });

    if (product === products.SUBSCRIPTION_INTENTS) {
      await dispatch('getBanks').then(() => {
        utils.validateSelectedInstitution(
          state.selectedInstitution, state.banks,
          products.SUBSCRIPTION, state.holderType, state.selectedCountry,
        );
      });
    }

    if (['MOVEMENTS', 'SUBSCRIPTION', 'PAYMENTS', 'REFRESH_INTENT'].includes(product)) {
      await dispatch('getBanks').then(() => {
        utils.validateSelectedInstitution(
          state.selectedInstitution, state.banks,
          product, state.holderType, state.selectedCountry,
        );
      });
    }
    if (['INVOICES', 'TAX_RETURNS', 'INCOME', 'TAX_STATEMENTS'].includes(product)) {
      await dispatch('getFiscalAuthorities').then(() => {
        utils.validateSelectedInstitution(
          state.selectedInstitution, state.fiscalAuthorities,
          product, state.holderType, state.selectedCountry,
        );
      });
    }

    if (
      responseConfig.data.requireAmountLimit
      && (payload.productConfig.subscription.amountLimit === null || payload.productConfig.subscription.amountLimit === undefined)
    ) {
      dispatch('widgetClosed');
      throw new Error('Subscription amountLimit required');
    }

    messages.widgetReady();

    if (product === products.SUBSCRIPTION_INTENTS) {
      await dispatch('getSubscriptionIntentStatus');
      await dispatch('getSubscriptionIntent');
    }
  },
  async getBanks({ commit, state }) {
    const { publicKey } = state;
    const response = await api.config.getBanks({ publicKey });
    commit('getBanks', response.data);
  },

  async getFiscalAuthorities({ commit, state }) {
    const { publicKey } = state;
    const response = await api.config.getFiscalAuthorities({ publicKey });
    commit('getFiscalAuthorities', response.data);
  },
  sendLoadMessageToParent() {
    messages.widgetLoaded();
  },
  async getProductBanksConfigs({ commit, state }) {
    const { publicKey, widgetToken, holderType } = state;
    const response = await api.config.getProductBanksConfigs(
      widgetToken, publicKey, holderType,
    );
    commit('getProductBanksConfigs', response.data);
  },
  setProductBankConfig({ commit, rootState }) {
    commit('setProductBankConfig', { entityIdSelected: rootState.widget.entityIdSelected });
  },
  setPrimaryColors({ commit }) {
    commit('setPrimaryColors');
  },
};

export default {
  state: { ...initialState },
  getters,
  mutations,
  actions,
};
