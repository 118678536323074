<template>
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col items-center w-full px-5 text-center h-full">
        <PaymentInfo class="mt-1"/>
        <Error class='mt-4 min-w-14 min-h-14' backgroundColor="#FFF2E7" circleColor="#EA780E" />
        <div id="pbidHeaderText" class="font-M font-700 color-txt-body mt-4"> {{ validationTitle }} </div>
        <div id="pbidSubText" class="font-S font-400 color-txt-body mt-4 mx-4"> {{ validationDescription }} </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Seleccionar otro banco"
          @click.native="transition()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import constants from '../../constants/index';
import WidgetLayout from '../layout/WidgetLayout.vue';
import PaymentInfo from './PaymentInfo.vue';
import Error from '../icons/Error.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import PaymentsMixin from '../mixins/payments';
import { ErrorMessages } from '../../utils/payment/PaymentInvalidDataForBank.constants';

export default {
  mixins: [PaymentsMixin],
  methods: {
    ...mapGetters(['bankTextEntitySelected']),
    ...mapActions(['transitionToNextState']),
    transition() {
      if (this.preSelectedBank !== null) {
        return this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
      }

      return this.transitionToNextState({ transition: constants.transitions.GO_BACK });
    },
  },
  computed: {
    ...mapState({
      paymentMinimumAmount: (state) => state.config.paymentMinimumAmount,
      preSelectedBank: (state) => state.config.selectedInstitution,
    }),
    validationTitle() {
      const errorCode = this.getPaymentDataValidationErrorForBank();
      switch (errorCode) {
        case 'amount_below_minimum':
          return ErrorMessages[errorCode].title();
        default:
          return ErrorMessages[errorCode].title();
      }
    },
    validationDescription() {
      const errorCode = this.getPaymentDataValidationErrorForBank();
      switch (errorCode) {
        case 'amount_below_minimum':
          return ErrorMessages[errorCode].description(this.bankTextEntitySelected(), this.parsedAmount(this.paymentMinimumAmount));
        default:
          return ErrorMessages[errorCode].description(this.bankTextEntitySelected());
      }
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Error,
    PaymentInfo,
  },
};
</script>
