import api from '../api';

const initialState = {
  challenges: null,
  secondFactorType: null,
  status: null,
  amount: null,
  datetime: null,
  accountNumber: null,
  accountName: null,
  accountOfficialName: null,
  subscriptionIntent: null,
  errorReason: null,
  voucherUrl: null,
};

const mutations = {
  loadSubscriptionIntentsFrozenState(state, payload) {
    Object.assign(state, { ...(payload || {}) });
  },
  getSubscriptionIntent(state, payload) {
    Object.assign(state, { subscriptionIntent: payload });
  },
  getSubscriptionIntentStatus(state, payload) {
    const {
      status, amount, nextAction, referenceId, errorReason, createdAt,
    } = payload;
    Object.assign(state, {
      status,
      amount,
      datetime: createdAt,
      secondFactorType: nextAction ? nextAction.type : null,
      referenceId,
      errorReason,
      challenges: nextAction ? nextAction.challenges : null,
    });
  },
  confirmAccount(state, payload) {
    state.status = payload.state;
  },
  setAccountNumber(state, number) {
    state.accountNumber = number;
  },
  getSubscriptionIntentVoucherUrl(state, payload) {
    const {
      url,
    } = payload;
    Object.assign(state, {
      voucherUrl: url,
    });
  },
};

const actions = {
  async getSubscriptionIntentStatus({ rootState, commit }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.subscriptionIntents.getStatus({
      widgetToken,
      publicKey,
    });
    commit('getSubscriptionIntentStatus', response.data);
  },
  async getSubscriptionIntent({ commit, rootState }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.subscriptionIntents.get({ widgetToken, publicKey });
    commit('getSubscriptionIntent', response.data);
  },
  async confirmSubscriptionIntentAccount({ commit, rootState }, { account, idempotencyKey }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.subscriptionIntents.confirmAccount({
      widgetToken,
      publicKey,
      accountId: account.id,
      idempotencyKey,
    });
    commit('confirmAccount', response.data);
    commit('setAccountNumber', account.number);
  },
  async sendSubscriptionIntentSecondAuth({ rootState }, { secondFactor, idempotencyKey }) {
    const { publicKey, widgetToken } = rootState.config;
    await api.subscriptionIntents.send2FA({
      widgetToken,
      publicKey,
      secondFactor,
      idempotencyKey,
    });
  },
  async getSubscriptionIntentVoucherUrl({ rootState, commit }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.subscriptionIntents.getVoucherUrl({
      widgetToken,
      publicKey,
    });
    commit('getSubscriptionIntentVoucherUrl', response.data);
  },
};

export default {
  state: initialState,
  mutations,
  actions,
};
