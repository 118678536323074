<template >
  <widget-layout>
    <div class="outer-container">
    <div class="content-div">
      <CheckVerified class="check-background"/>
      <div class="intro-div">
        <div class="font-L font-700 color-txt-heading text-center"> ¡Suscripción exitosa! </div>
        <div class="font-S font-400 color-txt-body text-center"> Detalles del PAC </div>
      </div>
      <div class="info-div">
        <div class="field-sc">
          <div class="font-S font-400 color-txt-sec-and-cap"> PAC firmado con </div>
          <div class="font-S font-400 color-txt-heading"> {{ organizationName }} </div>
        </div> <div class="underline" />
        <div class="field-sc">
          <div class="font-S font-400 color-txt-sec-and-cap"> Banco </div>
          <div class="font-S font-400 color-txt-heading"> {{ institutionName }} </div>
        </div> <div class="underline" />
        <div class="field-sc">
          <div class="font-S font-400 color-txt-sec-and-cap"> Cuenta </div>
          <div class="font-S font-400 color-txt-heading"> {{ accountNumber }} </div>
        </div> <div class="underline" />
        <div class="field-sc">
          <div class="font-S font-400 color-txt-sec-and-cap"> Fecha y hora </div>
          <div class="font-S font-400 color-txt-heading"> {{ parsedDateTime(this.subscriptionDateTime) }} </div>
        </div> <div class="underline" />
      </div>
    </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          v-if="isVoucherAvailable"
          data-test='voucher-button'
          class="mb-2"
          :secondary="true"
          text="Descargar comprobante"
          @click.native="downloadVoucher()"
        />
        <ActionButton
          text="Terminar"
          @click.native="finishSubscriptionIntent()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';
import CheckVerified from '../icons/CheckVerified.vue';
import AnalyticsEvents from '../../constants/analytics_events';

export default {
  computed: {
    ...mapGetters(['institutionName']),
    ...mapState({
      organizationName: (state) => state.config.organizationName,
      accountNumber: (state) => state.subscriptionIntents.accountNumber,
      subscriptionDateTime: (state) => state.subscriptionIntents.datetime,
      voucherUrl: (state) => state.subscriptionIntents.voucherUrl,
    }),
    isVoucherAvailable() {
      return !!this.voucherUrl;
    },
  },
  methods: {
    ...mapActions(['widgetFinished', 'getSubscriptionIntentVoucherUrl', 'getSubscriptionIntent']),
    async finishSubscriptionIntent() {
      await this.getSubscriptionIntent();
      this.widgetFinished();
    },
    parsedDateTime(dateTime) {
      const date = new Date(dateTime);
      const dayOfMonth = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = String(date.getHours()).padStart(2, '0');
      const min = String(date.getMinutes()).padStart(2, '0');
      const parsedDate = `${dayOfMonth}/${month}/${year} – ${hour}:${min}`;
      return parsedDate;
    },
    async requestSubscriptionIntentVoucher() {
      try {
        await this.getSubscriptionIntentVoucherUrl();
      } catch (error) {
        Sentry.captureException(error);
        // We were only console logging this and ignoring cancel axios request (error.__CANCEL__)
      }
    },
    downloadVoucher() {
      if (this.isVoucherAvailable) {
        window.analytics.track(
          AnalyticsEvents.SUBSCRIPTION_INTENT_VOUCHER_CLICKED, this.analyticsEventProperties,
        );
        window.open(this.voucherUrl, 'Download');
      }
    },
  },
  created() {
    this.requestSubscriptionIntentVoucher();
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    CheckVerified,
  },
};

</script>

<style scoped>
.text-body-tc {
  width: 200px;
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  text-align: center;
  letter-spacing: -0.006em;

  /* Text/Body */

  color: #4A4672;
}

.field-sc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  width: calc(100vw - 40px);
  max-width: 320px;
}

.underline {
margin: 5px 0px;
width: calc(100vw - 40px);
max-width: 320px;
border: 1px solid #E2E1E9;
box-sizing: border-box;
}

.info-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  margin-top: 20px;
  background: #FFFFFF;
  border-radius: 6px;
}

.intro-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 20px;
}

.content-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px 20px;
  overflow: auto;
}

.actions-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
}
</style>
