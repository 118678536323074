<template>
  <div class="anonimized-bank cursor-pointer">
    <default class="anonimized-bank-icon"></default>
    <span class="anonimized-bank-text"> {{bankName}} </span>
  </div>
</template>

<script>
import Default from '../icons/Banks/Default.vue';

export default {
  props: {
    bankName: String,
  },
  components: {
    Default,
  },
};
</script>

<style scoped>
  .anonimized-bank {
    display: flex;
    flex-direction: row;
    position: static;
    align-items: center;
    width: 320px;
    background: #FFFFFF;
    box-shadow: 0px 20px 30px rgba(7, 3, 61, 0.05), 0px 5px 8px rgba(7, 3, 61, 0.05);
    border-radius: 8px;
    padding: 16px 24px;
  }

  .anonimized-bank-icon {
    margin-right: 16px;
  }

  .anonimized-bank-text {
    line-height: 24px;
    font-weight: 500;
    font-family: Inter;
  }
</style>
