import store from '../store';
import Analytics from './analytics';
import events from '../constants/analytics_events';

function trackState(currentState, rootState) {
  const event = Analytics.stateToEvent(currentState);
  if (event) {
    window.analytics.track(
      event,
      {
        ...Analytics.getEventPropertiesFromRootState(rootState),
        ...Analytics.getMfaTypeIfNecessary(currentState),
      },
    );
  }
}

function trackWidgetFinished(rootState) {
  window.analytics.track(
    events.WIDGET_FINISH_CLICKED,
    Analytics.getEventPropertiesFromRootState(rootState),
  );
}

function trackEvent(event, additionalState) {
  window.analytics.track(
    event,
    {
      ...Analytics.getEventPropertiesFromRootState(store.state),
      ...(additionalState || {}),
    },
  );
}

export default {
  trackState,
  trackWidgetFinished,
  trackEvent,
};
