<template>
  <div class="flex flex-col w-full space-y-8 px-2">
    <div class="flex w-full space-x-4 items-center">
      <div class="flex w-6 h-6 bg-primary-surface rounded-2xl justify-center items-center text-heading-colo text-xs font-bold">1</div>
      <div
        class="flex text-secondary-color items-center flex-wrap"
      >
        <span class="font-bold text-heading-color">Autoriza</span>&nbsp;con tu&nbsp;
        <span
          :class="`flex font-bold select-none ${authAppNameStyling}`"
          @click="openAuthApp"
        >
          <span :class="{ underline: authAppIsOpenable }">
            {{ authAppName }}
          </span>
          &nbsp;<AuthAppLogo class="w-6 h-6"/>
          <template v-if="authAppIsOpenable">
            &nbsp;<DownArrowSvg class="my-auto w-4 h-4 -rotate-90" />
          </template>
        </span>
      </div>
    </div>
    <div class="flex w-full space-x-4 items-center">
      <div class="flex w-6 h-6 bg-primary-surface rounded-2xl justify-center items-center text-heading-colo text-xs font-bold">2</div>
      <div
        class="flex text-secondary-color max-w-60 items-center"
        data-test="second-step-text"
      >
        <p>
          <span class="font-bold text-heading-color">Vuelve</span> a esta app {{ secondStepText }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { useDevice } from '../../../composables/device';
import { applicationIsAvailable, openApplication } from '../../../helpers/deep-links';
import Tracking from '../../../helpers/tracking';
import AnalyticsEvents from '../../../constants/analytics_events';
import constants from '../../../constants';
import AuthAppLogo from '../../layout/AuthAppLogo.vue';
import DownArrowSvg from '../../icons/DownArrow.vue';

export default {
  setup() {
    const { os } = useDevice();
    return { os };
  },
  computed: {
    ...mapGetters([
      'authAppName',
    ]),
    ...mapState({
      stateContext: (state) => state.widget.currentStateContext,
      entityId: (state) => state.widget.entityIdSelected,
    }),
    secondStepText() {
      if (this.stateContext === constants.state_context.PAYMENT_AUTH) {
        return 'para recibir tu comprobante';
      }
      return 'para terminar el proceso de pago';
    },
    authAppIsOpenable() {
      return applicationIsAvailable(this.os, this.entityId);
    },
    authAppNameStyling() {
      return (
        this.authAppIsOpenable
          ? 'text-primary-main cursor-pointer'
          : 'text-heading-color'
      );
    },
  },
  methods: {
    openAuthApp() {
      if (this.authAppIsOpenable) {
        Tracking.trackEvent(
          AnalyticsEvents.MFA_APPLICATION_OPENED_FROM_WIDGET,
          { os: this.os, entityId: this.entityId },
        );
        openApplication(this.os, this.entityId);
      }
    },
  },
  components: {
    AuthAppLogo,
    DownArrowSvg,
  },
};
</script>
