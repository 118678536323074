/* eslint-disable no-param-reassign */

// Determines if the passed element is overflowing its bounds vertically.
// Will temporarily modify the "overflow" style to detect this
// if necessary.
function checkOverflow(element) {
  const currentOverflow = element.style.overflow;

  if (!currentOverflow || currentOverflow === 'visible') { element.style.overflow = 'hidden'; }

  const isOverflowing = element.clientHeight < element.scrollHeight;

  element.style.overflow = currentOverflow;

  return isOverflowing;
}

function checkIsAtScrollableBottom(element) {
  const { scrollTop, offsetHeight, scrollHeight } = element;
  const isAtBottom = (scrollTop + offsetHeight + 1) >= scrollHeight;
  return isAtBottom;
}

function checkHexDark(color) {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness < 50;
}

function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  }
  return `rgb(${r}, ${g}, ${b})`;
}

function hexify(color) {
  const values = color
    .replace(/rgba?\(/, '')
    .replace(/\)/, '')
    .replace(/[\s+]/g, '')
    .split(',');
  const a = parseFloat(values[3] || 1);
  const r = Math.floor(a * parseInt(values[0], 10) + (1 - a) * 255);
  const g = Math.floor(a * parseInt(values[1], 10) + (1 - a) * 255);
  const b = Math.floor(a * parseInt(values[2], 10) + (1 - a) * 255);
  return `#${
    (`0${r.toString(16)}`).slice(-2)
  }${(`0${g.toString(16)}`).slice(-2)
  }${(`0${b.toString(16)}`).slice(-2)}`;
}

export {
  checkOverflow,
  checkIsAtScrollableBottom,
  checkHexDark,
  hexToRGBA,
  hexify,
};
