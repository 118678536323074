<template>
  <widget-layout :goBackFunc="prevStep">
    <div class="step-container">
      <div class="justify-self-auto" v-for="step in Array(totalSteps).keys()" v-bind:key="step">
        <div :class="currentStep >= step + 1 ? 'steps bg-primary-main': 'steps bg-divider-color'" />
        <div class="step-label text-primary-main" v-if="currentStep === step + 1">Paso {{step + 1}}</div>
      </div>
    </div>

    <div class="outer-container">
    <div class="text-instructions" v-if="currentStep === 1">
      <p>Haz <a href="https://a1.bbvanet.com.mx/portalblg/portalblg/public/registrousuario?cloud=true" class="font-bold text-primary-main" target="_blank">click aquí</a> y se abrirá esta vista. Ingresa tu</p>
      <p><strong>Número de tarjeta de débito</strong> y presiona</p>
      <p class="font-bold italic">Continuar</p>
    </div>

    <div class="text-instructions" v-if="currentStep === 2">
      <p>Ingresa el <strong>NIP de cajero</strong> de tu tarjeta y presiona</p>
      <p class="font-bold italic">Continuar</p>
    </div>

    <div class="text-instructions" v-if="currentStep === 3">
      <p>1 - Ingresa tu correo electrónico</p>
      <p>2 - Ingresa la contraseña que deseas crear</p>
      <p>3 - Click en el box para aceptar TyC</p>
      <p>4 - Presiona <span class="font-bold italic">Contratar</span></p>
    </div>

    <div class="-mb-14">
      <img :src="require('@/assets/images/new_password_helper1.png')" v-if="currentStep === 1"/>
      <img :src="require('@/assets/images/new_password_helper2.png')" v-if="currentStep === 2"/>
      <img :src="require('@/assets/images/new_password_helper3.png')" v-if="currentStep === 3"/>
    </div>

    <div class="buttons-container">
      <ActionButton
        class="w-full"
        :text="currentStep === totalSteps ? '¡Listo!' : 'Siguiente'"
        @click.native="nextStep"
      />
    </div>
    </div>
  </widget-layout>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetLayout from '../../../layout/WidgetLayout.vue';
import ActionButton from '../../../layout/ActionButton.vue';
import constants from '../../../../constants';

export default {
  data() {
    return {
      currentStep: 1,
      totalSteps: 3,
    };
  },
  methods: {
    closeHelper() {
      this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
    },
    nextStep() {
      if (this.currentStep === this.totalSteps) this.closeHelper();
      this.currentStep += 1;
    },
    prevStep() {
      if (this.currentStep === 1) this.closeHelper();
      this.currentStep -= 1;
    },
    ...mapActions(['transitionToNextState']),
  },
  components: {
    WidgetLayout,
    ActionButton,
  },
};
</script>

<style scoped>

.steps {
  position: static;
  width: 117.33px;
  height: 3px;
  left: 0px;
  top: 0px;
  z-index: 1;
}

</style>
