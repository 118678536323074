import api from '../api';

const initialState = {
  challenges: null,
  secondFactorType: null,
  captchaURL: null,
  status: null,
  refreshIntentId: null,
  institutionId: null,
  errorType: null,
};

const getters = {};

const mutations = {
  loadRefreshIntentsFrozenState(state, payload) {
    Object.assign(state, { ...(payload || {}) });
  },
  getRefreshIntent(state, payload) {
    const {
      status, nextAction, institutionId, errorType,
    } = payload;
    Object.assign(state, {
      status,
      secondFactorType: nextAction ? nextAction.type : null,
      institutionId,
      errorType,
    });
    if (state.challenges === null) {
      Object.assign(state, {
        challenges: nextAction ? nextAction.challenges : null,
      });
    }
    if (state.captchaURL === null) {
      Object.assign(state, {
        captchaURL: nextAction ? nextAction.captchaURL : null,
      });
    }
  },
};

const actions = {
  async getRefreshIntent({ rootState, commit }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.refreshIntent.get({
      publicKey, widgetToken,
    });
    commit('getRefreshIntent', response.data);
  },
  async sendRefreshIntentSecondAuth({ rootState }, { secondFactor, idempotencyKey }) {
    const { publicKey, widgetToken } = rootState.config;
    await api.refreshIntent.send2FA({
      widgetToken,
      publicKey,
      secondFactor,
      idempotencyKey,
    });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
