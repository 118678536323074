<template>
  <widget-layout>
  </widget-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constants from '../../../constants';
import WidgetLayout from '../../layout/WidgetLayout.vue';

export default {
  computed: {
    ...mapState({
      product: (state) => state.config.product.toLowerCase(),
      holderType: (state) => state.config.holderType.toLowerCase(),
      fiscalAuthorities: (state) => state.config.fiscalAuthorities,
      country: (state) => state.config.selectedCountry,
    }),
    fiscalAuthoritiesList() {
      return this.fiscalAuthorities
        .filter((authority) => authority.products[this.product] && authority.products[this.product][this.holderType]);
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'updateFiscalAuthoritySelected']),
  },
  created() {
    const fiscalAuthority = this.fiscalAuthorities.find((authority) => authority.publicId.startsWith(this.country));
    if (fiscalAuthority) {
      this.updateFiscalAuthoritySelected({ fiscalAuthorityId: fiscalAuthority.publicId });
    }
    this.transitionToNextState({ transition: constants.transitions.SELECT_FISCAL_AUTHORITY });
  },
  components: {
    WidgetLayout,
  },
};
</script>
