<template >
  <widget-layout>
    <div class="outer-container">
      <PaymentInfo
        v-if="!isProgressBarVariant"
        class="mt-2"
      />
      <div class="flex flex-col w-screen max-w-90 items-center justify-center h-full space-y-8 px-4 pb-8">
        <div class="mt-8 text-center w-full">
          <div class="font-M font-700 color-txt-heading"> {{ title }} </div>
          <div class="font-S font-400 color-txt-body"> Esto demora unos segundos </div>
        </div>

        <CheckVerified
          v-if="isSimulatingContactStep2"
          class="check-animation mt-8 w-14 h-14 min-w-14 min-h-14"
          :isSuccess="false"
        />
        <Spinner v-else />
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Continuar"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import utils from '../../helpers/utils';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';
import Spinner from '../layout/Spinner.vue';
import PaymentsMixin from '../mixins/payments';
import CheckVerified from '../icons/CheckVerified.vue';
import PaymentInfo from './PaymentInfo.vue';
import optimizelyFlags from '../../helpers/optimizely';

const { setFrozenFriendlyTimeout, sleep } = utils;

export default {
  mixins: [PaymentsMixin],
  data() {
    return {
      polling: null,
      isSimulatingContactStep1: false,
      isSimulatingContactStep2: false,
      isSimulatingContactStep3: false,
    };
  },
  computed: {
    ...mapState({
      paymentStatus: (state) => state.payments.status,
      secondFactorType: (state) => state.payments.secondFactorType,
      organizationName: (state) => state.config.organizationName,
      amount: (state) => state.payments.amount.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' }),
      contactMfaCounter: (state) => state.payments.contactMfaCounter,
      paymentMfaCounter: (state) => state.payments.paymentMfaCounter,
      simulateCheckContact: (state) => state.payments.simulateCheckContact,
      isPaymentSelectingAuthSeen: (state) => state.widget.isPaymentSelectingAuthSeen,
    }),
    title() {
      if (this.isSimulatingContactStep1) {
        return 'Conectando con el destinatario';
      }
      if (this.isSimulatingContactStep2) {
        return 'Destinatario encontrado';
      }
      if (this.isSimulatingContactStep3) {
        return 'Buscando tus métodos de autenticación';
      }
      return 'Procesando transferencia...';
    },
    isProgressBarVariant() {
      return optimizelyFlags.progressBarVariant;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'getPaymentStatus', 'setSimulateCheckContact']),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    transitionToFailed() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PROCESSING_PAYMENTS_FAILED,
      });
    },
    async transitionToSucceeded() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PROCESSING_PAYMENTS_SUCCEEDED,
      });
    },
    async transitionToSelectingAuth({ stateContext }) {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PAYMENTS_SELECTING_AUTH,
        stateContext,
      });
    },
    async requestPaymentsStatus() {
      try {
        await this.getPaymentStatus();
      } catch (error) {
        Sentry.captureException(error);
        clearInterval(this.polling);
        this.transitionToFailed();
      }
    },
    pollingTimeout() {
      if (this.polling !== null) {
        this.transitionToFailed();
      }
    },
    pollPaymentIntent() {
      this.polling = setInterval(() => {
        this.requestPaymentsStatus();
      }, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_RATE_SEC);
      setFrozenFriendlyTimeout(this.pollingTimeout, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_TIMEOUT_SEC);
    },
    async simulateCheckingContact() {
      this.setSimulateCheckContact({ value: true });
      this.isSimulatingContactStep1 = true;
      await sleep(1000);
      this.isSimulatingContactStep1 = false;
      this.isSimulatingContactStep2 = true;
      await sleep(800);
      this.isSimulatingContactStep1 = false;
      this.isSimulatingContactStep2 = false;
      this.isSimulatingContactStep3 = true;
      await sleep(1000);
      this.setSimulateCheckContact({ value: false });
    },
    async checkIfSimulateContact() {
      if (this.contactMfaCounter === 0 && this.paymentMfaCounter === 0 && !this.isPaymentSelectingAuthSeen && this.isProgressBarVariant) {
        await this.simulateCheckingContact();
      }
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    this.pollPaymentIntent();
  },
  watch: {
    async paymentStatus(status) {
      if (status === 'succeeded') {
        this.transitionToSucceeded();
      }

      if (status === 'failed') {
        this.transitionToFailed();
      }

      if (status === 'unknown') {
        this.transitionToFailed();
      }

      if (this.isPaymentSelectingContactAuthStatus(status)) {
        this.transitionToSelectingAuth({ stateContext: constants.state_context.CONTACT_AUTH });
      }

      if (this.isPaymentSelectingTransactionAuthStatus(status)) {
        await this.checkIfSimulateContact();
        this.transitionToSelectingAuth({ stateContext: constants.state_context.PAYMENT_AUTH });
      }

      if (this.isPaymentContactAuthStatus(status)) {
        this.clearPolling();
        this.transitionToPayment2FA({ stateContext: constants.state_context.CONTACT_AUTH });
      }

      if (this.isPaymentTransactionAuthStatus(status)) {
        this.clearPolling();
        await this.checkIfSimulateContact();
        this.transitionToPayment2FA({ stateContext: constants.state_context.PAYMENT_AUTH });
      }
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Spinner,
    CheckVerified,
    PaymentInfo,
  },
};
</script>

<style>
.check-animation {
  animation: scale-zero-to-one .75s;
}

@keyframes scale-zero-to-one {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
</style>
