import auth from './auth';
import config from './config';
import linkIntent from './linkIntent';
import subscription from './subscription';
import subscriptionIntents from './subscriptionIntents';
import payments from './payments';
import refreshIntent from './refreshIntent';

export default {
  auth,
  config,
  linkIntent,
  subscription,
  subscriptionIntents,
  payments,
  refreshIntent,
};
