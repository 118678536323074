<template>
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.0761719 28.103C0.0761719 12.6391 12.6122 0.103027 28.0762 0.103027C43.5401 0.103027 56.0762 12.6391 56.0762 28.103C56.0762 43.567 43.5401 56.103 28.0762 56.103C12.6122 56.103 0.0761719 43.567 0.0761719 28.103Z" :fill="backgroundColor"/>
<g clip-path="url(#clip0_281_4907)">
<path d="M28.0762 8.10303C17.0293 8.10303 8.07617 17.0562 8.07617 28.103C8.07617 39.1499 17.0293 48.103 28.0762 48.103C39.123 48.103 48.0762 39.1499 48.0762 28.103C48.0762 17.0562 39.123 8.10303 28.0762 8.10303ZM26.2012 19.978C26.2012 18.9468 27.0449 18.103 28.0762 18.103C29.1074 18.103 29.9512 18.9429 29.9512 19.978L29.9512 29.978C29.9512 31.0132 29.1113 31.853 28.0762 31.853C27.041 31.853 26.2012 31.0171 26.2012 29.978L26.2012 19.978ZM28.0762 39.353C26.7199 39.353 25.6199 38.253 25.6199 36.8968C25.6199 35.5405 26.7191 34.4405 28.0762 34.4405C29.4332 34.4405 30.5324 35.5405 30.5324 36.8968C30.5293 38.2515 29.4355 39.353 28.0762 39.353Z" :fill="circleColor"/>
</g>
<defs>
<clipPath id="clip0_281_4907">
<rect width="40" height="40" fill="white" transform="translate(8.07617 8.10303)"/>
</clipPath>
</defs>
</svg>

</template>

<script>
export default {
  name: 'Error',
  props: {
    backgroundColor: String,
    circleColor: String,
  },
};
</script>
