const ANDROID_DEEP_LINKS = {
  cl_banco_estado: {
    packageName: 'net.veritran.becl.prod',
    scheme: 'bancoestado',
  },
  cl_banco_itau: {
    packageName: 'com.konylabs.ItauMobileBank',
    scheme: 'itaucl',
  },
  cl_banco_scotiabank: {
    packageName: 'cl.scotiabank.keypass',
    scheme: 'keypass',
  },
};

const generateAndroidIntentUrl = (entityId) => (
  `intent://#Intent;package=${ANDROID_DEEP_LINKS[entityId].packageName};`
  + `scheme=${ANDROID_DEEP_LINKS[entityId].scheme};end;`
);

export const applicationIsAvailable = (os, entityId) => {
  if (os === 'android') {
    return ANDROID_DEEP_LINKS[entityId] !== undefined;
  }
  return false;
};

export const openApplication = (os, entityId) => {
  if (!applicationIsAvailable(os, entityId)) {
    return;
  }
  if (os === 'android') {
    const androidIntentUrl = generateAndroidIntentUrl(entityId);
    window.location.replace(androidIntentUrl);
  }
};
