export default {
  methods: {
    isControlOrCommandKey(key) {
      return (key === 'Meta' || key === 'Control');
    },
    isCtrlV(keyCode, ctrlDown) {
      return ((keyCode === 86) && (ctrlDown === true));
    },
    isBackspaceOrDelete(keyCode) {
      return (keyCode === 8 || keyCode === 46);
    },
    isWindowsLeftKey(keyCode) {
      return keyCode === 91;
    },
    isNum(x) {
      return (/^\d+$/.test(x));
    },
    isLeftArrowKey(keyCode) {
      return (keyCode === 37);
    },
    isRightArrowKey(keyCode) {
      return (keyCode === 39);
    },
    isLengthValid(valueLength, inputLength) {
      return (valueLength === inputLength);
    },
    inputValueExists(inputValues, index) {
      return inputValues[index] && inputValues[index] !== '';
    },
    inputValueDoesntExist(inputValues, index) {
      return !inputValues[index] || inputValues[index] === '';
    },
  },
};
