<template>
  <div
    class="flex flex-col justify-between overflow-x-hidden p-0 max-w-90 min-w-70 h-full max-h-170 w-screen bg-white cursor-default relative top-0"
    :class="{ 'rounded-xl': isWindowWidthBig }"
  >
    <Header :goBackFunc="goBackFunc"/>
    <div class="flex flex-col max-w-90 items-center overflow-y-scroll h-full" ref="mainContentContainer" @scroll="onScroll">
      <slot/>
    </div>
    <div class="h-0" v-if="canScrollToBottom && includeScrollArrow">
      <DownArrow class="relative bottom-4 w-full h-3 animate-bounce text-disabled-color"/>
    </div>
    <div class="bg-white w-full" :class="{'shadow': canScrollToBottom}">
      <slot name="footer"/>
    </div>
    <div class="absolute bottom-0 w-full h-full" :class="{'invisible': !showDrawer || showFintocDrawer}" data-test="component-drawer">
      <slot name="drawer" />
    </div>
    <div class="absolute bottom-0 w-full h-full z-10" :class="{'invisible': !showFintocDrawer}" data-test="fintoc-drawer">
      <FintocDrawer />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Header from './Header.vue';
import StylesMixin from '../mixins/styles';
import DownArrow from '../icons/DownArrow.vue';
import FintocDrawer from './FintocDrawer.vue';

export default {
  mixins: [StylesMixin],
  computed: {
    ...mapState({
      showFintocDrawer: (state) => state.widget.showFintocDrawer,
    }),
    isWindowWidthBig() {
      return (this.windowWidth > 360);
    },
  },
  methods: {
    ...mapActions(['updateScreenDimensions', 'setPrimaryColors']),
  },
  props: {
    goBackFunc: Function,
    includeScrollArrow: Boolean,
    showDrawer: Boolean,
  },
  components: {
    Header,
    DownArrow,
    FintocDrawer,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
      this.windowHeight = window.innerHeight;
      this.updateScreenDimensions({ width: window.innerWidth, height: window.innerHeight });
    };
  },
  created() {
    this.setPrimaryColors();
    this.windowWidth = window.innerWidth;
    this.windowHeight = window.innerHeight;
    this.updateScreenDimensions({ width: window.innerWidth, height: window.innerHeight });
  },
};
</script>
