import constants from '../../constants/index';

function buildErrorMessage(data) {
  if (data.errorType === constants.errors.LINK_INTENT_REJECTED_CREDENTIALS_LOCKED) {
    return errorMessages().linkLockedCredentials;
  }
  if (data.errorType === constants.errors.INVALID_MFA) {
    return errorMessages().invalidMFA;
  }
  return errorMessages().defaultError;
}

function errorMessages() {
  return {
    invalidMFA: {
      title: 'El código del segundo factor ingresado es incorrecto',
    },
    linkLockedCredentials: {
      title: 'La clave ingresada se encuentra bloqueada',
    },
    defaultError: {
      title: 'Tuvimos un problema conectándonos',
    },
  };
}

// eslint-disable-next-line import/prefer-default-export
export { buildErrorMessage };
