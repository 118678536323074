import optimizelySdk from '@optimizely/optimizely-sdk';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';

const getUserId = () => {
  try {
    const key = 'fintocUserId';
    let userId = localStorage.getItem(key);
    if (!userId) {
      userId = uuidv1();
      localStorage.setItem(key, userId);
    }
    return userId;
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

const createOptimizelyInstance = () => {
  try {
    return optimizelySdk.createInstance({
      sdkKey: process.env.VUE_APP_OPTIMIZELY_SDK_KEY,
      datafileOptions: {
        autoUpdate: true,
        updateInterval: 5 * 60 * 1000,
      },
      logLevel: 'off',
    });
  } catch (e) {
    Sentry.captureException(e);
    return null;
  }
};

class OptimizelyFlags {
  constructor() {
    if (typeof OptimizelyFlags.instance === 'object') {
      return OptimizelyFlags.instance;
    }

    this.userId = null;
    this.companyName = null;
    this.product = null;
    this.defaultCountry = null;
    this.user = null;
    this.progressBarVariant = false;
    this.optimizelyInstance = createOptimizelyInstance();

    OptimizelyFlags.instance = this;
    return this;
  }

  init({
    userId, companyName, product, defaultCountry,
  }) {
    this.setUserAttributes({
      userId, companyName, product, defaultCountry,
    });
    if (this.optimizelyInstance) {
      this.optimizelyInstance.onReady().then(() => this.updatePage());
      this.optimizelyInstance.notificationCenter.addNotificationListener(
        optimizelySdk.enums.NOTIFICATION_TYPES.OPTIMIZELY_CONFIG_UPDATE,
        this.updatePage(),
      );
    }
  }

  setUserAttributes({
    userId, companyName, product, defaultCountry,
  }) {
    this.userId = userId;
    this.companyName = companyName;
    this.product = product;
    this.defaultCountry = defaultCountry;
  }

  getUserAttributes() {
    return {
      userId: this.userId,
      companyName: this.companyName,
      product: this.product,
      defaultCountry: this.defaultCountry,
    };
  }

  updatePage() {
    const userId = getUserId();
    if (userId) {
      this.user = this.optimizelyInstance.createUserContext(userId, this.getUserAttributes());
      this.progressBarVariant = this.user.decide('progress_bar_variant')?.enabled || false;
      this.loginQuestionsVariationKey = this.user.decide('login_questions')?.variationKey || 'off';
    }
  }

  trackEvent(event) {
    if (this.user) {
      this.user.trackEvent(event);
    }
  }
}

const optimizelyFlags = new OptimizelyFlags();

export default optimizelyFlags;
