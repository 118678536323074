import axiosInstance from './axiosInstance';

function confirm({
  widgetToken, publicKey, accountId, idempotencyKey,
}) {
  return axiosInstance
    .patch('/internal/v1/payment_intents/confirm', {
      accountId,
      widget_token: widgetToken,
    }, {
      headers: {
        Authorization: publicKey,
        'Idempotency-Key': idempotencyKey,
      },
    });
}

function selectAuth({
  widgetToken, publicKey, auth, idempotencyKey,
}) {
  return axiosInstance
    .patch('/internal/v1/payment_intents/selected_auth', {
      selectedAuth: auth,
      widget_token: widgetToken,
    }, {
      headers: {
        Authorization: publicKey,
        'Idempotency-Key': idempotencyKey,
      },
    });
}

function get({ publicKey, widgetToken }) {
  return axiosInstance
    .get('/internal/v1/payment_intents/', {
      headers: {
        Authorization: publicKey,
      },
      params: { widget_token: widgetToken },
    });
}

function getStatus({ publicKey, widgetToken }) {
  return axiosInstance
    .get('/internal/v1/payment_intents/status', {
      headers: {
        Authorization: publicKey,
      },
      params: { widget_token: widgetToken },
    });
}

function send2FA({
  publicKey, widgetToken, secondFactor, idempotencyKey,
}) {
  return axiosInstance
    .patch('/internal/v1/payment_intents/second_factor', {
      secondFactor,
      widget_token: widgetToken,
    }, {
      headers: {
        Authorization: publicKey,
        'Idempotency-Key': idempotencyKey,
      },
    });
}

function getVoucherUrl({ publicKey, widgetToken }) {
  return axiosInstance
    .get('/internal/v1/payment_intents/voucher_url', {
      headers: {
        Authorization: publicKey,
      },
      params: { widget_token: widgetToken },
    });
}

export default {
  confirm,
  selectAuth,
  get,
  getStatus,
  send2FA,
  getVoucherUrl,
};
