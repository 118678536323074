import axiosInstance from './axiosInstance';

function create({
  holderId, username, password, publicKey, holderType, institutionId, mode, callbackUrl, product, widgetToken, idempotencyKey,
}) {
  return axiosInstance
    .post('/internal/v1/link_intents/widget', {
      linkData: {
        holderId,
        username,
        password,
        holderType: holderType.toLowerCase().replace(/\./gi, '').replace('-', ''),
        institutionId,
        mode,
        product: product.toLowerCase(),
      },
      callbackUrl,
    },
    {
      headers: {
        Authorization: publicKey,
        'Idempotency-Key': idempotencyKey,
      },
      params: { widget_token: widgetToken },
    });
}

function get({ linkIntentId, publicKey }) {
  return axiosInstance.get(`/internal/v1/link_intents/widget/${linkIntentId}`,
    {
      headers: {
        Authorization: publicKey,
      },
    });
}

function update({
  linkIntentId, secondFactor, publicKey,
}) {
  return axiosInstance
    .patch(`/internal/v1/link_intents/widget/${linkIntentId}`, {
      secondFactor,
    }, {
      headers: { Authorization: publicKey },
    });
}

export default {
  create,
  get,
  update,
};
