import * as Sentry from '@sentry/vue';
import messageTypes from '../../sharedTypes/messages';
import buildEventMessage from '../helpers/parse-events';
import utils from '../helpers/utils';

function getTimestamp() {
  try {
    const timestamp = Date.now();
    if (timestamp === undefined) {
      return Date.getTime();
    }
    return timestamp;
  } catch (err) {
    Sentry.captureException(err);
    return Date.getTime();
  }
}

export function widgetLoaded() {
  window.parent.postMessage({
    type: messageTypes.WIDGET_READY,
  }, utils.widgetParentOrigin());
}

export function widgetClosed() {
  window.parent.postMessage({
    type: messageTypes.WIDGET_CLOSED,
    metadata: {
      time: getTimestamp(),
    },
  }, utils.widgetParentOrigin());
}

export function sendLinkMessage(link) {
  const {
    id,
    holderId,
    username,
    holderType,
    createdAt,
    institution,
    mode,
    active,
  } = link;

  window.parent.postMessage({
    type: messageTypes.WIDGET_SUCCEEDED,
    link: {
      id,
      username,
      holder_id: holderId,
      holder_type: holderType,
      created_at: createdAt,
      institution,
      mode,
      active,
      link_token: null,
      accounts: null,
    },
    metadata: {
      timestamp: getTimestamp(),
    },
  }, utils.widgetParentOrigin());
}

export function sendRefreshIntentMessage(refreshIntent) {
  window.parent.postMessage({
    type: messageTypes.WIDGET_SUCCEEDED,
    refreshIntent,
    metadata: {
      timestamp: getTimestamp(),
    },
  }, utils.widgetParentOrigin());
}

export function sendSubscriptionIntentMessage(subscriptionIntent) {
  window.parent.postMessage({
    type: messageTypes.WIDGET_SUCCEEDED,
    subscriptionIntent,
    metadata: {
      timestamp: getTimestamp(),
    },
  }, utils.widgetParentOrigin());
}

export function sendPaymentIntentMessage(paymentIntent) {
  window.parent.postMessage({
    type: messageTypes.WIDGET_SUCCEEDED,
    paymentIntent,
    metadata: {
      timestamp: getTimestamp(),
    },
  }, utils.widgetParentOrigin());
}

export function widgetFinished({
  link, refreshIntent, paymentIntent, subscriptionIntent,
}) {
  if (paymentIntent !== undefined && paymentIntent !== null) { return sendPaymentIntentMessage(paymentIntent); }
  if (subscriptionIntent !== undefined && subscriptionIntent !== null) { return sendSubscriptionIntentMessage(subscriptionIntent); }
  if (link) { return sendLinkMessage(link); }
  if (refreshIntent) { return sendRefreshIntentMessage(refreshIntent); }

  throw new Error('Widget message not implemented');
}

export function widgetEvent(eventType) {
  const eventName = buildEventMessage(eventType);
  window.parent.postMessage({
    type: messageTypes.WIDGET_EVENT,
    eventName,
    metadata: {
      timestamp: getTimestamp(),
    },
  }, utils.widgetParentOrigin());
}

export function widgetReady() {
  window.parent.postMessage({
    type: messageTypes.WIDGET_CONFIGURATION_DONE,
  }, utils.widgetParentOrigin());
}

export function widgetConfigurationInvalid(error) {
  window.parent.postMessage({
    type: messageTypes.WIDGET_CONFIGURATION_INVALID,
    error,
  }, utils.widgetParentOrigin());
}
