<template>
  <widget-layout includeScrollArrow>
    <div class="outer-container px-4 justify-start space-y-8">
      <AuthInfo />
      <div class="flex flex-col w-full items-center space-y-4">
        <AuthDescription />
        <Timer :input-time="showTimeout" />
      </div>
      <AuthAppSteps />
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          loading
          text="Esperando tu autorización"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import utils from '../../../helpers/utils';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import constants from '../../../constants';
import Footer from '../../layout/Footer.vue';
import ActionButton from '../../layout/ActionButton.vue';
import Timer from '../../lib/Timer.vue';
import PaymentsMixin from '../../mixins/payments';
import AuthInfo from '../AuthInfo.vue';
import AuthDescription from '../AuthDescription.vue';
import AuthAppSteps from './AuthAppSteps.vue';
import optimizelyFlags from '../../../helpers/optimizely';

const POLLING_TIMEOUT = parseInt(process.env.VUE_APP_SUBSCRIPION_WAITING_FOR_ACTION_TIMEOUT_SEC, 10);

const { setFrozenFriendlyTimeout } = utils;

export default {
  mixins: [PaymentsMixin],
  data() {
    return {
      loading: false,
      polling: null,
    };
  },
  computed: {
    ...mapGetters(['isPayment', 'isSubscription', 'isSubscriptionIntent', 'isLinkIntent', 'isRefreshIntent']),
    ...mapState({
      subscriptionStatus: (state) => state.subscription.status,
      subscriptionIntentStatus: (state) => state.subscriptionIntents.status,
      paymentStatus: (state) => state.payments.status,
      stateContext: (state) => state.widget.currentStateContext,
      linkIntentStatus: (state) => state.link.status,
      refreshIntentStatus: (state) => state.refreshIntent.status,
      product: (state) => state.config.product,
      mfaConfig: (state) => state.config.mfaConfig,
      secondFactorType: (state) => state.payments.secondFactorType,
    }),
    showTimeout() {
      if (this.mfaConfig) {
        const { timeout } = this.mfaConfig.app;
        return timeout;
      }
      return POLLING_TIMEOUT;
    },
    processTimeout() {
      if (this.mfaConfig) {
        const { timeout, delay } = this.mfaConfig.app;
        return timeout + delay;
      }
      return POLLING_TIMEOUT;
    },
  },
  methods: {
    ...mapActions([
      'transitionToNextState', 'getSubscription', 'getPaymentStatus',
      'getLinkIntent', 'getRefreshIntent', 'getSubscriptionIntentStatus',
      'setPaymentMfaCounter', 'setContactMfaCounter',
    ]),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    transitionToFailed() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.WAITING_ACTION_FAILED,
      });
    },
    trackMfaAttempted() {
      if (this.isPayment) {
        optimizelyFlags.trackEvent('mfa_attempted');
      }
    },
    async transitionToSucceeded() {
      this.clearPolling();
      this.trackMfaAttempted();
      this.transitionToNextState({
        transition: constants.transitions.WAITING_ACTION_SUCCEEDED,
      });
    },
    async transitionToAppAuthSucceeded() {
      this.clearPolling();
      this.trackMfaAttempted();
      if (this.stateContext === constants.state_context.PAYMENT_AUTH) {
        this.setPaymentMfaCounter();
      } else if (this.stateContext === constants.state_context.CONTACT_AUTH) {
        this.setContactMfaCounter();
      }
      this.transitionToNextState({
        transition: constants.transitions.APP_AUTH_SUCCEEDED,
      });
    },
    async refreshStatusObject() {
      if (this.isSubscription) {
        return this.getSubscription();
      }
      if (this.isSubscriptionIntent) {
        return this.getSubscriptionIntentStatus();
      }
      if (this.isPayment) {
        return this.getPaymentStatus();
      }
      if (this.isLinkIntent) {
        return this.getLinkIntent();
      }
      if (this.isRefreshIntent) {
        return this.getRefreshIntent();
      }
      throw new Error(`Second factor for product: ${this.product} unavailable.`);
    },
    async requestStatus() {
      try {
        await this.refreshStatusObject();
      } catch (error) {
        Sentry.captureException(error);
        this.clearPolling();
        this.transitionToFailed();
      }
    },
    pollingTimeout() {
      if (this.polling !== null) {
        this.transitionToFailed();
      }
    },
    pollIntent() {
      this.polling = setInterval(() => {
        this.requestStatus();
      }, process.env.VUE_APP_SUBSCRIPTION_INTENT_POLLING_RATE_SEC);
      setFrozenFriendlyTimeout(this.pollingTimeout, this.processTimeout * 1000);
    },
    transitionToResultStatus(status) {
      if (status === 'succeeded') {
        this.transitionToSucceeded();
      }

      if (status === 'failed') {
        this.transitionToFailed();
      }

      if (status === 'rejected') {
        this.transitionToFailed();
      }
    },
    async transitionToSelectingAuth({ stateContext }) {
      this.clearPolling();
      this.trackMfaAttempted();
      this.transitionToNextState({
        transition: constants.transitions.PAYMENTS_SELECTING_AUTH,
        stateContext,
      });
    },
  },
  created() {
    this.pollIntent();
  },
  beforeDestroy() {
    this.clearPolling();
  },
  watch: {
    subscriptionStatus(status) {
      if (this.isSubscription) {
        this.transitionToResultStatus(status);
      }
    },
    subscriptionIntentStatus(status) {
      if (this.isSubscriptionIntent) {
        this.transitionToResultStatus(status);
      }
    },
    paymentStatus(status) {
      if (!this.isPayment) return;
      if (this.isPaymentSelectingAuthStatus(status)) {
        if (this.isPaymentSelectingContactAuthStatus(status)) {
          this.transitionToSelectingAuth({ stateContext: constants.state_context.CONTACT_AUTH });
        } else {
          this.transitionToSelectingAuth({ stateContext: constants.state_context.PAYMENT_AUTH });
        }
      } else if (this.isPaymentAuthStatus(status)) {
        if (this.isPaymentContactAuthStatus(status)) {
          this.clearPolling();
          this.transitionToPayment2FA({ stateContext: constants.state_context.CONTACT_AUTH });
        } else {
          this.clearPolling();
          this.transitionToPayment2FA({ stateContext: constants.state_context.PAYMENT_AUTH });
        }
      } else if (this.isExecutingPaymentStatus(status)) {
        this.transitionToAppAuthSucceeded();
      } else {
        this.transitionToResultStatus(status);
      }
    },
    linkIntentStatus(status) {
      if (this.isLinkIntent) {
        this.transitionToResultStatus(status);
      }
    },
    refreshIntentStatus(status) {
      if (this.isRefreshIntent) {
        this.transitionToResultStatus(status);
      }
    },
  },
  components: {
    WidgetLayout,
    Footer,
    ActionButton,
    Timer,
    AuthInfo,
    AuthDescription,
    AuthAppSteps,
  },
};
</script>
