import { useDevice } from '../composables/device';
import { applicationIsAvailable } from './deep-links';
import states from '../constants/states';
import events from '../constants/analytics_events';
import products from '../../sharedTypes/products';

const stateToEventHash = {
  // General
  [states.INTRODUCTION]: events.INTRODUCTION_SCREEN_VIEWED,
  [states.BANK_SELECTION]: events.INSTITUTIONS_SCREEN_VIEWED,
  [states.FISCAL_AUTHORITIES]: events.INSTITUTIONS_SCREEN_VIEWED,
  [states.LOGIN]: events.LOGIN_SCREEN_VIEWED,
  [states.ERROR_VIEW]: events.ERROR_SCREEN_VIEWED,
  [states.WIDGET_CLOSED]: events.WIDGET_CLOSED,
  // Link Intent
  [states.LOADING_LINK_INTENT]: events.LOADING_SCREEN_VIEWED,
  [states.LINK_CREATED]: events.SUCCESS_SCREEN_VIEWED,
  [states.LINK_INTENT_PROCESSING_ACTION]: events.LOADING_SCREEN_VIEWED,
  // Auth
  [states.APP_AUTH_REQUIRED]: events.MFA_SCREEN_VIEWED,
  [states.DEVICE_AUTH_REQUIRED]: events.MFA_SCREEN_VIEWED,
  [states.CARD_AUTH_REQUIRED]: events.MFA_SCREEN_VIEWED,
  [states.SMS_AUTH_REQUIRED]: events.MFA_SCREEN_VIEWED,
  [states.EMAIL_AUTH_REQUIRED]: events.MFA_SCREEN_VIEWED,
  [states.CAPTCHA_AUTH_REQUIRED]: events.CAPTCHA_SCREEN_VIEWED,
  // Subscriptions
  [states.SUBSCRIPTION_CONFIRMATION_REQUIRED]: events.ACCOUNTS_SCREEN_VIEWED,
  [states.SUBSCRIPTION_PROCESSING_ACTION]: events.LOADING_SCREEN_VIEWED,
  [states.LOADING_SUBSCRIPTION]: events.LOADING_SCREEN_VIEWED,
  [states.SUBSCRIPTION_CREATED]: events.SUCCESS_SCREEN_VIEWED,
  [states.SUBSCRIPTION_ERROR]: events.ERROR_SCREEN_VIEWED,
  [states.SUBSCRIPTION_ABORTED]: events.ABORT_SCREEN_VIEWED,
  // Payments
  [states.PAYMENTS_CONFIRMATION_REQUIRED]: events.ACCOUNTS_SCREEN_VIEWED,
  [states.PROCESSING_PAYMENTS]: events.LOADING_SCREEN_VIEWED,
  [states.PAYMENTS_CREATED]: events.SUCCESS_SCREEN_VIEWED,
  [states.PAYMENTS_PROCESSING_ACTION]: events.LOADING_SCREEN_VIEWED,
  [states.PAYMENTS_ERROR]: events.ERROR_SCREEN_VIEWED,
  [states.PAYMENTS_SELECTING_AUTH]: events.MFA_SELECTION_SCREEN_VIEWED,
  [states.PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK]: events.ERROR_SCREEN_VIEWED,
  // Refresh Intents
  [states.REFRESH_INTENT_CREATED]: events.LOADING_SCREEN_VIEWED,
  [states.REFRESH_INTENT_PROCESSING_ACTION]: events.LOADING_SCREEN_VIEWED,
  [states.REFRESH_INTENT_FETCHING_DATA]: events.LOADING_SCREEN_VIEWED,
  [states.REFRESH_INTENT_SUCCEEDED]: events.SUCCESS_SCREEN_VIEWED,
  [states.REFRESH_INTENT_ERROR]: events.ERROR_SCREEN_VIEWED,
  // Helpers
  [states.TOKEN_HELPER]: events.HELPER_SCREEN_VIEWED,
  [states.NEW_PASSWORD_HELPER]: events.HELPER_SCREEN_VIEWED,
  [states.RESET_PASSWORD_HELPER]: events.HELPER_SCREEN_VIEWED,
};

const stateToMfaTypeHash = {
  [states.APP_AUTH_REQUIRED]: 'app',
  [states.DEVICE_AUTH_REQUIRED]: 'device',
  [states.CARD_AUTH_REQUIRED]: 'card',
  [states.SMS_AUTH_REQUIRED]: 'sms',
  [states.EMAIL_AUTH_REQUIRED]: 'email',
  [states.CAPTCHA_AUTH_REQUIRED]: 'captcha',
};

function stateToEvent(state) {
  return stateToEventHash[state] || null;
}

function getEventPropertiesFromRootState(rootState) {
  const { os } = useDevice();
  return {
    username: rootState.link?.username || null,
    resource_id: rootState.config?.resourceId || null,
    link_id: rootState.link?.link?.id || null,
    product: rootState.config?.product || null,
    institution_id: rootState.widget?.entityIdSelected || null,
    current_state: rootState.widget?.currentState || null,
    mode: rootState.config?.mode || null,
    current_state_context: rootState.widget?.currentStateContext || null,
    variants: rootState.config?.variants || null,
    error_type: rootState.widget?.errorType || null,
    error_reason: getErrorReason(rootState) || null,
    mfa_application_can_be_opened: (
      rootState.widget?.entityIdSelected
        ? applicationIsAvailable(os, rootState.widget?.entityIdSelected)
        : null
    ),
  };
}

function getMfaTypeIfNecessary(state) {
  const mfaType = stateToMfaTypeHash[state];
  if (mfaType) {
    return { mfa_type: mfaType };
  }
  return {};
}

function getErrorReason(state) {
  switch (state.config?.product) {
    case products.PAYMENTS:
      return state.payments?.errorReason;
    case products.MOVEMENTS:
      return state.link?.reason;
    case products.SUBSCRIPTION:
      return state.subscription?.errorReason;
    case products.SUBSCRIPTION_INTENTS:
      return state.subscriptionIntents?.errorReason;
    default:
      return null;
  }
}

export default {
  stateToEvent,
  getEventPropertiesFromRootState,
  getMfaTypeIfNecessary,
};
