const SMS = 'SMS';
const DEVICE = 'DEVICE';
const CARD = 'CARD';
const EMAIL = 'EMAIL';
const APP = 'APP';
const CAPTCHA = 'CAPTCHA';

export default {
  SMS,
  DEVICE,
  CARD,
  EMAIL,
  APP,
  CAPTCHA,
};
