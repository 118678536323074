<template>
  <div class="flex flex-col items-center justify-between px-4 pt-4 space-y-4 w-screen max-w-90">
    <component :is="authIcon" />
    <div class="space-y-2 text-center">
      <div
        data-test="auth-info-title"
        class="text-body-color"
      >
        {{ title }}
      </div>
      <div
        data-test="auth-info-subtitle"
        class="text-heading-color font-bold"
      >
        {{ subtitle }}
      </div>
    </div>
    <InfoOneLine
      v-if="showComissionInfo"
      :text="'Banco Estado te cobrará una comisión de $' + calculateCommission"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InfoOneLine from '../views.common/InfoOneLine.vue';
import ContactIcon from '../icons/Auth/reason/ContactIcon.vue';
import SubscriptionIcon from '../icons/Auth/reason/SubscriptionIcon.vue';
import TransactionIcon from '../icons/Auth/reason/TransactionIcon.vue';
import EntityLogo from '../layout/EntityLogo.vue';
import products from '../../../sharedTypes/products';
import constants from '../../constants';

export default {
  computed: {
    ...mapGetters([
      'isBancoEstadoComission',
      'parsedAmountWithComission',
      'calculateCommission',
      'isPayment',
      'isBancoBBVA',
      'isBancoBanorte',
      'isBancoFalabella',
    ]),
    ...mapState({
      organizationName: (state) => state.config.organizationName,
      recipientHolderName: (state) => state.payments.recipientHolderName,
      stateContext: (state) => state.widget.currentStateContext,
      product: (state) => state.config.product,
      currentState: (state) => state.widget.currentState,
      challenges: (state) => state.subscription.challenges || state.payments.challenges || state.link.challenges || state.refreshIntent.challenges,
    }),
    showComissionInfo() {
      return this.isPayment && this.isBancoEstadoComission;
    },
    authIcon() {
      switch (this.product) {
        case products.MOVEMENTS: {
          return EntityLogo;
        }
        case products.REFRESH_INTENT: {
          return EntityLogo;
        }
        case products.SUBSCRIPTION: {
          return SubscriptionIcon;
        }
        case products.SUBSCRIPTION_INTENTS: {
          return SubscriptionIcon;
        }
        case products.PAYMENTS: {
          if (this.stateContext === constants.state_context.CONTACT_AUTH) {
            return ContactIcon;
          }
          return TransactionIcon;
        }
        default: {
          return TransactionIcon;
        }
      }
    },
    title() {
      switch (this.product) {
        case products.MOVEMENTS: {
          if (this.isBancoBBVA) {
            return 'Ingresa el token de tu app BBVA para';
          }
          if (this.isBancoBanorte) {
            return 'Para iniciar sesión, genera tu token con el código';
          }
          return 'Ingresa las coordenadas para';
        }
        case products.REFRESH_INTENT: {
          return 'Ingresa las coordenadas para';
        }
        case products.SUBSCRIPTION: {
          return 'Autoriza tu suscripción a la empresa';
        }
        case products.SUBSCRIPTION_INTENTS: {
          return 'Autoriza tu suscripción a la empresa';
        }
        case products.PAYMENTS: {
          if (this.currentState === constants.states.PAYMENTS_SELECTING_AUTH) {
            return 'Selecciona el método';
          }
          if (this.stateContext === constants.state_context.CONTACT_AUTH) {
            return 'Autoriza el nuevo destinatario';
          }
          return 'Autoriza la transferencia';
        }
        default: {
          return 'Autoriza la operación';
        }
      }
    },
    subtitle() {
      switch (this.product) {
        case products.MOVEMENTS: {
          if (this.isBancoBanorte) {
            return this.challenges ? this.challenges[0] : '- - - - - -';
          }
          return 'Iniciar Sesión';
        }
        case products.REFRESH_INTENT: {
          return 'Iniciar Sesión';
        }
        case products.SUBSCRIPTION: {
          return this.organizationName;
        }
        case products.SUBSCRIPTION_INTENTS: {
          return this.organizationName;
        }
        case products.PAYMENTS: {
          if (this.currentState === constants.states.PAYMENTS_SELECTING_AUTH) {
            if (this.stateContext === constants.state_context.CONTACT_AUTH) {
              return 'para autorizar el nuevo destinatario';
            }
            return 'para autorizar la transferencia';
          }
          if (this.stateContext === constants.state_context.CONTACT_AUTH) {
            return this.recipientHolderName;
          }
          return this.parsedAmountWithComission;
        }
        default: {
          return '';
        }
      }
    },
  },
  components: {
    InfoOneLine,
    EntityLogo,
  },
};
</script>
