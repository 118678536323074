<template>
  <div>
    <div class="top-container-mfa-1">
      <div class="top-left-container-mfa-1">
        <div class="font-XS font-400 color-txt-sec-and-cap text-left"> Destinatario: </div>
        <div :class="`font-${recipientHolderNameFontSize} font-700 color-txt-heading text-left`"> {{ truncateRecipientHolderName(recipientHolderName) }} </div>
      </div>
      <div class="top-right-container-mfa-1">
        <div class="font-XS font-400 color-txt-sec-and-cap text-right"> Monto total a pagar: </div>
        <div class="font-L font-700 color-txt-heading text-right"> {{ parsedAmountWithComission }} </div>
      </div>
    </div>
    <InfoOneLine class="mt-2" v-if="isBancoEstadoComission" :text="'Banco Estado te cobrará una comisión de $' + calculateCommission"/>
    <hr :class="isBancoEstadoComission? 'divider' : 'divider pb-4'" size="1" width="100%">
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InfoOneLine from '../views.common/InfoOneLine.vue';

const MAX_RECIPIENT_HOLDER_NAME_LENGTH = 32;

export default {
  computed: {
    ...mapGetters(['isBancoEstadoComission', 'parsedAmountWithComission', 'calculateCommission']),
    ...mapState({
      recipientHolderName(state) {
        if (state.payments.recipientHolderName.match(/cruzada spa/i)) {
          return 'Paga en línea';
        }
        return state.payments.recipientHolderName;
      },
    }),
    recipientHolderNameFontSize() {
      if (this.recipientHolderName.length >= 19) {
        return 'S';
      }
      return 'M';
    },
  },
  components: {
    InfoOneLine,
  },
  methods: {
    truncateRecipientHolderName(recipientHolderName) {
      if (recipientHolderName.length > MAX_RECIPIENT_HOLDER_NAME_LENGTH) {
        return `${recipientHolderName.slice(0, MAX_RECIPIENT_HOLDER_NAME_LENGTH)}...`;
      }
      return recipientHolderName;
    },
  },
};
</script>

<style scoped>

.top-right-container-mfa-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;

  position: static;
  width: 48%;
}

.top-left-container-mfa-1 {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 0px;

  position: static;
  width: 48%;
}

.top-container-mfa-1 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
  min-height: 46px;
  width: calc(100vw - 40px);
  max-width: 320px;
  min-width: 260px;
}

.text-left {
  position: static;
  width: 100%;
}

.text-right {
  position: static;
  width: 100%;
  align-items: flex-end;
  align-content: flex-end;
}

.divider {
  margin: 8px 0px 0px 0px;
}

</style>
