import moment from 'moment';
import api from '../api';

const initialState = {
  challenges: null,
  secondFactorType: null,
  status: null,
  errorReason: null,
  subscriptionId: null,
  amountLimit: null,
  accountNumber: null,
  dateTime: null,
};

const getters = {

};

const mutations = {
  loadSubscriptionsFrozenState(state, payload) {
    Object.assign(state, { ...(payload || {}) });
  },
  createSubscriptionIntent(state, payload) {
    Object.assign(state, {
      subscriptionId: payload.id,
    });
  },
  getSubscription(state, payload) {
    const {
      status, nextAction, createdAt, errorReason,
    } = payload;
    Object.assign(state, {
      status,
      secondFactorType: nextAction ? nextAction.type : null,
      errorReason,
      dateTime: createdAt || moment().format('DD MMM YYYY HH:mm'),
      // temporal hack until the backend responds with the creation date
    });
    if (state.challenges === null) {
      Object.assign(state, {
        challenges: nextAction ? nextAction.challenges : null,
      });
    }
  },
  setSubscriptionAccountNumber(state, accountNumber) {
    state.accountNumber = accountNumber;
  },
};

const actions = {
  async getSubscription({ rootState, commit, state }) {
    const { publicKey } = rootState.config;
    const { temporaryLinkToken } = rootState.link;
    const { subscriptionId } = state;
    const response = await api.subscription.get({
      subscriptionId, linkToken: temporaryLinkToken, publicKey,
    });
    commit('getSubscription', response.data);
  },
  async createSubscriptionIntent({ commit, rootState }, { account }) {
    const { config, link } = rootState;
    const { username, temporaryLinkToken } = link;
    const { publicKey, callbackUrl, productConfig } = config;
    let amountLimit = null;
    let customerId = username.replace(/\./gi, '').replace('-', '');
    if (productConfig && productConfig.subscription) {
      const { subscription } = productConfig;
      amountLimit = subscription.amountLimit ? subscription.amountLimit : null;
      customerId = subscription.customerId || customerId;
    }
    const response = await api.subscription.create({
      accountId: account.id,
      customerId,
      amountLimit,
      linkToken: temporaryLinkToken,
      callbackUrl,
      publicKey,
    });
    commit('createSubscriptionIntent', response.data);
    commit('setSubscriptionAccountNumber', account.number);
  },
  async sendSubscriptionSecondAuth({ rootState, state }, { secondFactor }) {
    const { publicKey } = rootState.config;
    const { temporaryLinkToken } = rootState.link;
    const { subscriptionId } = state;
    await api.subscription.update({
      subscriptionId,
      secondFactor,
      linkToken: temporaryLinkToken,
      publicKey,
    });
  },

};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
