<template>
<svg width="102" height="102" viewBox="0 0 102 102" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_dd_488_9000)">
<path d="M87 46C87 26.1178 70.8823 10 51 10C31.1178 10 15 26.1178 15 46C15 65.8823 31.1178 82 51 82C70.8823 82 87 65.8823 87 46Z" :fill="backgroundColor ? backgroundColor : 'white'"/>
</g>
<defs>
<filter id="filter0_dd_488_9000" x="0" y="0" width="102" height="102" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="5"/>
<feGaussianBlur stdDeviation="3"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0235294 0 0 0 0 0.00784314 0 0 0 0 0.207843 0 0 0 0.03 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_488_9000"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="5"/>
<feGaussianBlur stdDeviation="7.5"/>
<feColorMatrix type="matrix" values="0 0 0 0 0.0235294 0 0 0 0 0.00784314 0 0 0 0 0.207843 0 0 0 0.07 0"/>
<feBlend mode="normal" in2="effect1_dropShadow_488_9000" result="effect2_dropShadow_488_9000"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_488_9000" result="shape"/>
</filter>
</defs>
</svg>
</template>

<script>
export default {
  props: {
    backgroundColor: String,
  },
};
</script>
