<template>
  <div class="field color-bg-neutral focus-within:border-primary-main">
    <div class="field-elements w-full">
      <div class="frame-field-text">
        <div id=label class="font-XS font-500 color-txt-sec-and-cap">
          {{ label }}
        </div>
        <input class="font-M font-500 color-txt-body input w-full"
          :class="applyInputStyle(valid)"
          v-model.trim="value"
          v-on:input="signalChange"
          :type="type"
          :textType="textType"
          :fixedLength="fixedLength"
          :maxlength="maxlength"
          v-on:keyup.enter="signalOnEnter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import validations from '../../mixins/field-validators';

export default {
  mixins: [validations],
  data() {
    return {
      value: '',
      valid: true,
      previousValue: '',
    };
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    textType: {
      type: String,
      default: null,
    },
    fixedLength: {
      type: Number,
      default: 0,
    },
    maxlength: {
      type: Number,
      default: 524288,
    },
  },
  methods: {
    getField() {
      return this.fieldValue;
    },
    validateRestrictionsOnValue() {
      const fieldConfig = {
        textType: this.textType,
        fixedLength: this.fixedLength,
      };
      const validationResult = this.hasValidFieldValueStructure(fieldConfig, this.value);
      this.previousValue = this.value;
      this.valid = validationResult.valid;
      return validationResult;
    },
    applyInputStyle(valid) {
      if (valid) {
        return '';
      }
      return 'input-invalid-structure';
    },
    signalChange() {
      this.valid = true;
    },
    signalOnEnter() {
      this.$emit('onEnter');
    },
  },
};
</script>

<style scoped>

.input:focus {
  outline: none;
}

.input-invalid-structure {
  border: 1.5px solid red;
  background-color:pink;
}

.field {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 0px 10px;
  margin: 4px;
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  border-radius: 6px;
}

</style>
