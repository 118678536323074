<template>
  <widget-layout>
    <div class="outer-container">
    <div class="container-captcha-adr">
      <BusinessInfo v-if="isLinkIntent||isRefreshIntent" />

      <div class="text-center mt-5 mb-12 marginLR-20">
        <div class="font-M font-700 color-txt-heading"> {{ headerText }} </div>
      </div>

      <div class="captcha-form-container">
        <div class="captcha-holder">
            <img v-bind:src="getCaptchaImagePath" class="captcha-image"/>
        </div>
        <InputWithLabel
          ref="captchaInputField"
          v-bind="captchaInputProperties"
          @onEnter="handleCaptchaInputOnEnter"/>
      </div>
    </div>

    <div class="w-full px-5">
      <ActionButton
        :disabled="loading"
        :text="btnText"
        @click.native="sendCode"
      />
      <Footer />
    </div>
    </div>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import constants from '../../../constants';
import products from '../../../../sharedTypes/products';
import Footer from '../../layout/Footer.vue';
import ActionButton from '../../layout/ActionButton.vue';
import BusinessInfo from '../BusinessInfo.vue';
import InputWithLabel from './InputWithLabel.vue';

export default {
  data() {
    return {
      loading: false,
      idempotencyKey: '',
    };
  },
  computed: {
    ...mapState({
      product: (state) => state.config.product,
      stateContext: (state) => state.widget.currentStateContext,
      organizationName: (state) => state.config.organizationName,
      entityId: (state) => state.widget.entityIdSelected || state.refreshIntent.institutionId,
      captchaURL: (state) => state.link.captchaURL || state.refreshIntent.captchaURL,
    }),
    getCaptchaImagePath() {
      return this.captchaURL;
    },
    captchaInputProperties() {
      return {
        label: 'Texto imagen captcha',
        textType: 'alphanumeric',
        maxlength: 5,
        fixedLength: 5,
      };
    },
    isLinkIntent() {
      return this.product === products.MOVEMENTS;
    },
    isRefreshIntent() {
      return this.product === products.REFRESH_INTENT;
    },
    headerText() {
      const linkIntentText = 'Ingresa el texto que ves en la imagen para verificar que no eres un robot';
      if (this.isLinkIntent || this.isRefreshIntent) { return linkIntentText; }
      return 'default';
    },
    btnText() {
      if (this.isLinkIntent || this.isRefreshIntent) return 'Aceptar';
      return 'default';
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'sendLinkIntentSecondAuth',
      'sendRefreshIntentSecondAuth', 'getLinkIntent']),
    handleCaptchaInputOnEnter() {
      this.sendCode();
    },
    validateInputStructure() {
      return this.$refs.captchaInputField.validateRestrictionsOnValue();
    },
    async sendSecondAuth(args) {
      if (this.isLinkIntent) {
        return this.sendLinkIntentSecondAuth(args);
      }
      if (this.isRefreshIntent) {
        return this.sendRefreshIntentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      throw new Error(`Captcha for product: ${this.product} unavailable.`);
    },
    async sendCode() {
      const validationResult = this.validateInputStructure();
      if (!validationResult.valid) {
        return;
      }
      this.loading = true;
      try {
        await this.sendSecondAuth({ secondFactor: this.$refs.captchaInputField.value });
        this.transitionToNextState({
          transition: constants.transitions.SECOND_FACTOR_SUCCEEDED,
        });
      } catch (error) {
        Sentry.captureException(error);
        if (error.__CANCEL__) {
          return;
        }

        this.transitionToNextState({
          transition: constants.transitions.SECOND_FACTOR_FAILED,
        });
      }
      this.loading = false;
    },
  },
  mounted() {
    this.idempotencyKey = uuidv1();
  },
  components: {
    WidgetLayout,
    ActionButton,
    BusinessInfo,
    Footer,
    InputWithLabel,
  },
};
</script>

<style scoped>

.container-captcha-adr {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0px 10px;
  width: 100vw;
  max-width: 360px;
  height: 100%;
  overflow-y: scroll;
  overflow-x: none;
}

.captcha-form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 80px;
  max-width: 320px;
  min-width: 200px;
  width: calc(100vw - 60px);
}

.captcha-holder {
  width: calc(100vw - 60px);
  max-width: 320px;
  min-width: 198px;
  max-height: 64px;
  min-height: 33px;
  background: #ccc;
  margin-bottom: 24px;
  border-radius: 8px;
}

.captcha-image {
  width: 100%;
  border-radius: 8px;
}

.input-captcha-field {
  font-size: 20px;
  letter-spacing: 0px;
  border-width: 1px;
  margin: 0px 15px 0px;
  padding-top: 16px;
}

</style>
