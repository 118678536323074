<template>
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col items-center justify-center h-full space-y-8 px-4 pb-8">
        <EntityLogo />
        <Error class="h-16 min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />
        <div class="flex flex-col items-center space-y-2 text-center">
          <div id="leTitle" data-cy="error-title" class="font-M font-700 color-txt-heading">
            {{ errorTitle }}
          </div>
          <div id="leDesc" class="font-S font-400 color-txt-body">
            {{ errorDescription }}
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Reintentar"
          data-cy="retry-widget"
          @click.native="transition()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import constants from '../../../constants';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import ActionButton from '../../layout/ActionButton.vue';
import Footer from '../../layout/Footer.vue';
import Error from '../../icons/Error.vue';
import EntityLogo from '../../layout/EntityLogo.vue';
import buildErrorMessage from '../../../utils/link_intent/LinkError.constants';

export default {
  methods: {
    ...mapActions(['transitionToNextState']),
    ...mapGetters(['bankTextEntitySelected']),
    transition() {
      this.transitionToNextState({ transition: constants.transitions.RETRY_LINK_INTENT });
    },
  },
  computed: {
    ...mapState({
      errorType: (state) => state.widget.errorType,
      product: (state) => state.config.product,
      entityId: (state) => state.widget.entityIdSelected,
      holderType: (state) => state.config.holderType,
      errorReason: (state) => state.link.reason,
    }),

    errorData() {
      return {
        errorType: this.errorType,
        entityId: this.entityId,
        holderType: this.holderType,
        errorReason: this.errorReason,
        bankTextEntitySelected: this.bankTextEntitySelected(),
      };
    },
    errorTitle() {
      return buildErrorMessage(this.errorData).title;
    },
    errorDescription() {
      return buildErrorMessage(this.errorData).description;
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Error,
    EntityLogo,
  },
};
</script>
