<template>
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col items-center pb-8 px-4 w-screen max-w-90 justify-center h-full space-y-8">
        <EntityLogo />

        <div class="flex flex-col items-center p-0 text-center w-full">
          <span class="font-M font-700 color-txt-heading"> {{ textHeading }} </span>
          <span class="font-S font-400 color-txt-body"> {{ textBody }}</span>
        </div>

        <Spinner class="z-10"/>
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Continuar"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import holderTypes from '../../../../sharedTypes/holderTypes';
import utils from '../../../helpers/utils';
import constants from '../../../constants';
import products from '../../../../sharedTypes/products';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import Footer from '../../layout/Footer.vue';
import EntityLogo from '../../layout/EntityLogo.vue';
import ActionButton from '../../layout/ActionButton.vue';
import Spinner from '../../layout/Spinner.vue';
import PaymentsMixin from '../../mixins/payments';

const { setFrozenFriendlyTimeout } = utils;

export default {
  mixins: [PaymentsMixin],
  data() {
    return {
      polling: null,
    };
  },
  computed: {
    ...mapGetters(['entityName', 'isFiscal']),
    ...mapState({
      linkIntentStatus: (state) => state.link.status,
      linkIntentReason: (state) => state.link.reason,
      secondFactorType: (state) => state.link.secondFactorType,
      product: (state) => state.config.product,
      holderType: (state) => state.config.holderType,
      paymentStatus: (state) => state.payments.status,
      subscriptionIntentStatus: (state) => state.subscriptionIntents.status,
    }),
    textHeading() {
      if (this.linkIntentStatus === 'fetching_movements') return 'Obteniendo lectura de movimientos...';
      if (this.linkIntentStatus === 'processing_link_intent') return this.processingText();
      return 'Conectando';
    },
    textBody() {
      return 'Esto demora unos segundos';
    },
    isSubscriptionIntents() {
      return this.isIndividual() && this.product === products.SUBSCRIPTION_INTENTS;
    },
    isSubscriptionIntentRetryable() {
      return this.subscriptionIntentStatus === 'retryable_failure';
    },
  },
  methods: {
    ...mapActions(['getSubscriptionIntentStatus', 'transitionToNextState', 'getLinkIntent', 'setErrorType', 'getPaymentStatus']),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    processingText() {
      if (this.isFiscal) return 'Obteniendo datos del SII';
      return 'Iniciando sesión';
    },
    isIndividual() {
      return (this.holderType === holderTypes.INDIVIDUAL);
    },
    isPayments() {
      return this.isIndividual() && this.product === products.PAYMENTS;
    },
    async processLinkToSucceeded() {
      if (this.isPayments()) {
        await this.getPaymentStatus();
        if (this.isPaymentError(this.paymentStatus)) {
          return this.transitionToPaymentError();
        }
        if (this.isPaymentRetryable(this.paymentStatus)) {
          return this.transitionToFailed();
        }
      }

      return this.transitionToSucceeded();
    },
    async processLinkToFailed() {
      if (this.isPayments()) {
        await this.getPaymentStatus();
        if (this.isPaymentRetryable(this.paymentStatus)) {
          return this.transitionToFailed();
        }
        return this.transitionToPaymentError();
      }
      if (this.isSubscriptionIntents) {
        await this.getSubscriptionIntentStatus();
        if (this.isSubscriptionIntentRetryable) {
          return this.transitionToFailed();
        }
        return this.transitionToSubscriptionError();
      }

      return this.transitionToFailed();
    },
    async transitionToFailed() {
      this.clearPolling();
      this.transitionToNextState({ transition: constants.transitions.LINK_INTENT_FAILED });
    },
    async transitionToSucceeded() {
      this.transitionToNextState({ transition: constants.transitions.LINK_INTENT_SUCCESSFUL });
    },
    async transitionToPaymentError() {
      this.transitionToNextState({ transition: constants.transitions.PAYMENTS_INSUFFICIENT_FUNDS_FAILED });
    },
    async transitionToSubscriptionError() {
      this.transitionToNextState({ transition: constants.transitions.SUBSCRIPTIONS_NO_RETRY_ERROR });
    },
    async requestLinkIntentStatus() {
      try {
        await this.getLinkIntent();
      } catch (error) {
        Sentry.captureException(error);
        await this.processLinkToFailed();
      }
    },
    async transitionToMFA() {
      this.clearPolling();
      switch (this.secondFactorType) {
        case 'enter_captcha_code': {
          this.transitionToNextState({
            transition: constants.transitions.LINK_INTENT_CAPTCHA_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_device_code': {
          this.transitionToNextState({
            transition: constants.transitions.LINK_INTENT_DEVICE_AUTH_REQUIRED,
          });
          break;
        }
        case 'authorize_in_app': {
          this.transitionToNextState({
            transition: constants.transitions.LINK_INTENT_APP_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_sms_code': {
          this.transitionToNextState({
            transition: constants.transitions.LINK_INTENT_SMS_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_coordinates': {
          this.transitionToNextState({
            transition: constants.transitions.LINK_INTENT_CARD_AUTH_REQUIRED,
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    async pollingTimeout() {
      if (this.polling !== null) {
        await this.processLinkToFailed();
      }
      clearInterval(this.polling);
    },
    pollLinkIntent() {
      this.polling = setInterval(
        this.requestLinkIntentStatus,
        process.env.VUE_APP_LINK_INTENT_POLLING_RATE_SEC,
      );
      setFrozenFriendlyTimeout(this.pollingTimeout, process.env.VUE_APP_LINK_INTENT_TIMEOUT_SEC);
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  async created() {
    this.pollLinkIntent();
  },
  watch: {
    async linkIntentStatus(status) {
      if (status === 'succeeded') {
        await this.processLinkToSucceeded();
      }
      if (status === 'rejected') {
        let errorType;
        if (this.linkIntentReason === 'credentials_locked') {
          errorType = constants.errors.LINK_INTENT_REJECTED_CREDENTIALS_LOCKED;
        } else if (this.linkIntentReason === 'login_mfa_required_error') {
          errorType = constants.errors.LINK_INTENT_MFA_REQUIRED;
        } else {
          errorType = constants.errors.LINK_INTENT_REJECTED_INVALID_CREDENTIALS;
        }
        this.setErrorType({ errorType });
        await this.processLinkToFailed();
      }
      if (status === 'failed') {
        const errorType = constants.errors.LINK_INTENT_FAILED;
        this.setErrorType({ errorType });
        await this.processLinkToFailed();
      }
      if (status === 'requires_action') {
        this.transitionToMFA();
      }
    },
  },
  components: {
    WidgetLayout,
    Footer,
    EntityLogo,
    ActionButton,
    Spinner,
  },
};
</script>
