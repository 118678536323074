import constants from '../../constants/index';
import PaymentsMixin from '../../components/mixins/payments';

function buildErrorMessage(data) {
  if (data.errorType === constants.errors.LINK_INTENT_REJECTED_INVALID_CREDENTIALS) {
    return errorMessages(data).linkInvalidCredentials;
  }
  if (data.errorType === constants.errors.INVALID_MFA || isErrorMfaCoordinatesInvalid(data.errorReason) || isErrorMfaSmsInvalid(data.errorReason) || isErrorMfaDeviceInvalid(data.errorReason)) {
    return errorMessages(data).invalidMFA;
  }
  if (isPaymentRejectedByUserApp(data.errorReason)) {
    return errorMessages(data).paymentRejectedByUserApp;
  }
  if (isPaymentContactRejectedByUserApp(data.errorReason)) {
    return errorMessages(data).paymentContactByUserApp;
  }
  if (isPaymentAppTimeout(data.errorReason)) {
    return errorMessages(data).paymentAppTimeout;
  }
  if (isPaymentContactAppTimeout(data.errorReason)) {
    return errorMessages(data).paymentContactAppTimeout;
  }
  if (isErrorInsufficientBalance(data.errorReason)) {
    return errorMessages(data).insufficientBalance;
  }
  if (isErrorNewContactLimitMax(data.errorReason)) {
    return errorMessages(data).newContactAmountLimitMax;
  }
  if (isErrorDailyLimitMax(data.errorReason)) {
    return errorMessages(data).dailyAmountLimitMax;
  }
  if (isErrorAmountLimitMax(data.errorReason)) {
    return errorMessages(data).amountLimitMax;
  }
  if (isErrorAccountSelectionTimeout(data.errorReason)) {
    return errorMessages(data).accountSelectionTimeout;
  }
  if (isErrorMfaCoordinatesTimeout(data.errorReason) || isErrorMfaSmsTimeout(data.errorReason) || isErrorMfaDeviceTimeout(data.errorReason)) {
    return errorMessages(data).mfaTimeout;
  }
  if (isErrorNewContactTransferLimitReached(data.errorReason)) {
    return errorMessages(data).newContactTransferLimitReached;
  }
  if (isErrorBankUnavailable(data.errorReason)) {
    return errorMessages(data).bankUnavailable;
  }
  if (isErrorBankRetryLater(data.errorReason)) {
    return errorMessages(data).bankRetryLater;
  }
  if (isErrorMfaRequiresBankAssist(data.errorReason)) {
    return errorMessages(data).mfaRequiresBankAssist;
  }
  if (isErrorRecipientAccountPaymentLimit(data.errorReason)) {
    return errorMessages(data).recipientAccountPaymentLimit;
  }
  if (isErrorTransferStatementAsRejected(data.errorReason)) {
    return errorMessages(data).transferStatementAsRejected;
  }
  if (isRecipientAccountIssue(data.errorReason)) {
    return errorMessages(data).recipientAccountIssue;
  }
  if (isTryOtherBankError(data.errorReason)) {
    return errorMessages(data).tryOtherBank;
  }
  if (isTryOtherBankConnectionError(data.errorReason)) {
    return errorMessages(data).tryOtherBankConnection;
  }
  if (isTryOtherAccountError(data.errorReason)) {
    return errorMessages(data).tryOtherAccount;
  }
  if (isMfaNotFoundError(data.errorReason)) {
    return errorMessages(data).mfaNotFound;
  }
  if (isContactNotFoundError(data.errorReason)) {
    return errorMessages(data).contactNotFound;
  }
  if (isTryAgainLaterError(data.errorReason)) {
    return errorMessages(data).tryAgainLater;
  }
  if (isCheckAccountBeforeRetry(data.errorReason)) {
    return errorMessages(data).checkAccountBeforeRetry;
  }
  if (isTimeoutInUserStep(data.errorReason)) {
    return errorMessages(data).timeoutUserStep;
  }
  if (isTimeoutSecurity(data.errorReason)) {
    return errorMessages(data).timeoutSecurity;
  }
  if (data.errorType === constants.errors.LINK_INTENT_FAILED) {
    if (isActiveSessionError(data.errorReason)) {
      return errorMessages(data).activeSession;
    }

    return errorMessages(data).linkIntentFailed;
  }
  if (data.errorType === constants.errors.LINK_INTENT_REJECTED_CREDENTIALS_LOCKED) {
    return errorMessages(data).linkLockedCredentials;
  }

  return errorMessages(data).defaultError;
}

function isErrorInsufficientBalance(errorReason) {
  return errorReason === 'insufficient_funds';
}

function isErrorAccountSelectionTimeout(errorReason) {
  return errorReason === 'account_selection_timeout';
}

function isErrorDailyLimitMax(errorReason) {
  return errorReason === 'daily_amount_limit_reached';
}

function isErrorAmountLimitMax(errorReason) {
  return errorReason === 'maximum_amount_error';
}

function isErrorNewContactLimitMax(errorReason) {
  return errorReason === 'new_contact_amount_limit_reached';
}

function isPaymentRejectedByUserApp(errorReason) {
  return errorReason === 'payment_rejected_by_user_with_app';
}

function isPaymentContactRejectedByUserApp(errorReason) {
  return errorReason === 'payment_contact_rejected_by_user_with_app';
}

function isPaymentAppTimeout(errorReason) {
  return errorReason === 'payment_app_timeout';
}

function isPaymentContactAppTimeout(errorReason) {
  return errorReason === 'payment_contact_app_timeout';
}

function isErrorInternal(errorReason) {
  return errorReason === 'internal_error';
}

function isErrorMfaCoordinatesTimeout(errorReason) {
  return errorReason === 'mfa_coordinates_timeout';
}

function isErrorMfaSmsTimeout(errorReason) {
  return errorReason === 'mfa_sms_timeout';
}

function isErrorMfaDeviceTimeout(errorReason) {
  return errorReason === 'mfa_device_timeout';
}

function isErrorNewContactTransferLimitReached(errorReason) {
  return errorReason === 'new_contact_transfer_number_limit_reached';
}

function isErrorMfaSmsInvalid(errorReason) {
  return errorReason === 'mfa_sms_invalid';
}

function isErrorMfaCoordinatesInvalid(errorReason) {
  return errorReason === 'mfa_coordinates_invalid';
}

function isErrorMfaDeviceInvalid(errorReason) {
  return errorReason === 'mfa_device_invalid';
}

function isErrorBankUnavailable(errorReason) {
  return errorReason === 'bank_unavailable_error' || errorReason === 'bank_error';
}

function isErrorBankRetryLater(errorReason) {
  return errorReason === 'bank_retry_later_error';
}

function isErrorMfaRequiresBankAssist(errorReason) {
  return errorReason === 'mfa_sms_requires_bank_assist' || errorReason === 'mfa_device_requires_bank_assist';
}

function isErrorAccountNotSupported(errorReason) {
  return errorReason === 'account_not_supported';
}

function isErrorInvalidRecipientAccount(errorReason) {
  return errorReason === 'invalid_recipient_account';
}

function isErrorRecipientAccountPaymentLimit(errorReason) {
  return errorReason === 'recipient_account_payment_limit';
}

function isErrorTransferStatementAsRejected(errorReason) {
  return errorReason === 'found_in_transfers_statement_as_rejected';
}

function isRecipientAccountIssue(errorReason) {
  return isErrorAccountNotSupported(errorReason) || isErrorInvalidRecipientAccount(errorReason);
}

function isTryOtherBankError(errorReason) {
  return errorReason === 'internal_error_try_other_bank';
}

function isTryOtherBankConnectionError(errorReason) {
  return errorReason === 'internal_error_try_other_bank_connection';
}

function isTryOtherAccountError(errorReason) {
  return errorReason === 'internal_error_try_other_account';
}

function isMfaNotFoundError(errorReason) {
  return errorReason === 'mfa_no_auth_found';
}

function isContactNotFoundError(errorReason) {
  return errorReason === 'internal_error_contact_not_found';
}

function isTryAgainLaterError(errorReason) {
  return errorReason === 'internal_error_try_again_later';
}

function isCheckAccountBeforeRetry(errorReason) {
  return errorReason === 'internal_error_check_account_before_retry';
}

function isTimeoutInUserStep(errorReason) {
  return errorReason === 'timeout_in_user_step';
}

function isTimeoutSecurity(errorReason) {
  return errorReason === 'timeout_security_reason';
}

function isTimeoutError(errorReason) {
  return errorReason && errorReason.includes('timeout');
}

function isActiveSessionError(errorReason) {
  return errorReason === 'active_session';
}

function newContactMax(entityId, accountOfficialName) {
  if (entityId === 'cl_banco_estado') {
    let max = 0;
    if (accountOfficialName === 'Cuenta Rut') {
      max = 100000;
    } else {
      max = 250000;
    }
    return PaymentsMixin.methods.parsedAmount(max);
  }
  return '$250.000';
}

function dailyLimitMax(entityId, accountOfficialName) {
  if (entityId === 'cl_banco_estado') {
    let max = 0;
    if (accountOfficialName === 'Cuenta Rut') {
      max = 1000000;
    } else {
      max = 5000000;
    }
    return PaymentsMixin.methods.parsedAmount(max);
  }
  return '$1.000.000';
}

function bankAppName(entityId, bankTextEntitySelected) {
  if (entityId === 'cl_banco_estado') {
    return 'BE Pass';
  }

  if (entityId === 'cl_banco_de_chile') {
    return 'Mi Pass';
  }

  if (entityId === 'cl_banco_santander') {
    return 'Santander Pass';
  }

  if (entityId === 'cl_banco_santander_pyme') {
    return 'Santander Pass';
  }

  if (entityId === 'cl_banco_scotiabank') {
    return 'Key Pass';
  }
  return `la app de ${bankTextEntitySelected}`;
}

function errorMessages(data) {
  return {
    transferStatementAsRejected: {
      title: 'La transferencia fue rechazada por el banco',
      description: 'Por favor comienza de nuevo el proceso de pago',
    },
    linkInvalidCredentials: {
      title: 'Las credenciales ingresadas son incorrectas',
      description: 'Utilizaste tu único intento. Por seguridad no se pudo generar el pago, por favor comienza de nuevo el proceso de pago',
    },
    linkLockedCredentials: {
      title: 'La clave ingresada se encuentra bloqueada.',
      description: 'Puedes desbloquear tu cuenta en la página web de tu institución o contactándolos directamente.',
    },
    invalidMFA: {
      title: 'El código del segundo factor ingresado es incorrecto',
      description: 'Debes ingresar el segundo factor correctamente para realizar la transferencia',
    },
    paymentRejectedByUserApp: {
      title: 'Transferencia rechazada',
      description: 'La transferencia fue rechazada desde la APP',
    },
    paymentContactByUserApp: {
      title: 'Contacto nuevo rechazado',
      description: 'Contacto nuevo fue rechazado desde la APP',
    },
    paymentAppTimeout: {
      title: `Ha superado el tiempo para autorizar la transferencia con ${bankAppName(data.entityId, data.bankTextEntitySelected)}`,
      description: 'Por favor comienza de nuevo el proceso de pago',
    },
    paymentContactAppTimeout: {
      title: `Ha superado el tiempo para autorizar el nuevo contacto con ${bankAppName(data.entityId, data.bankTextEntitySelected)}`,
      description: 'Por favor comienza de nuevo el proceso de pago',
    },
    insufficientBalance: {
      title: 'No tienes saldo suficiente para realizar el pago por transferencia',
      description: '',
    },
    newContactAmountLimitMax: {
      title: `El monto a transferir supera el monto máximo permitido por ${data.bankTextEntitySelected}`,
      description: `El monto máximo de transferencia es de ${newContactMax(data.entityId, data.accountOfficialName)} la primera vez que le transfieres a ${data.organizationName} desde esta cuenta:`,
    },
    dailyAmountLimitMax: {
      title: `El monto a transferir supera el monto máximo permitido por ${data.bankTextEntitySelected}`,
      description: `El monto máximo de transferencia es de ${dailyLimitMax(data.entityId, data.accountOfficialName)} al día desde esta cuenta:`,
    },
    amountLimitMax: {
      title: `El monto a transferir supera el monto máximo permitido por ${data.bankTextEntitySelected}`,
      description: 'El monto ingresado supera el máximo permitido para transferir desde esta cuenta',
    },
    accountSelectionTimeout: {
      title: 'Ha superado el tiempo para selección de cuenta',
      description: 'Por seguridad no se pudo generar el pago, por favor comienza de nuevo el proceso de pago',
    },
    mfaTimeout: {
      title: 'Ha superado el tiempo para ingresar el código',
      description: 'Por seguridad no se pudo generar el pago, por favor comienza de nuevo el proceso de pago',
    },
    newContactTransferLimitReached: {
      title: 'Has superado la cantidad máxima de transferencias permitidas a un nuevo contacto',
      description: `${data.bankTextEntitySelected} permite transferir sólo una vez a un nuevo contacto las primeras 24 horas`,
    },
    bankRetryLater: {
      title: `${data.bankTextEntitySelected} no pudo procesar el pago`,
      description: 'En estos momentos el banco no puede atender la solicitud, por favor reintente más tarde',
    },
    mfaRequiresBankAssist: {
      title: `${data.bankTextEntitySelected} no pudo procesar el pago`,
      description: 'El segundo factor presenta problemas, por favor contactarse con su banco',
    },
    recipientAccountPaymentLimit: {
      title: 'La cuenta del destinatario alcanzó el límite de abonos, por favor reintentar más tarde',
      description: `Si el problema persiste, comunícate con ${data.organizationName} o el destinatario para resolverlo`,
    },
    bankUnavailable: {
      title: `${data.bankTextEntitySelected} no pudo procesar el pago`,
      description: 'En estos momentos el banco no puede atender la solicitud',
    },
    recipientAccountIssue: {
      title: `Notifica a ${data.organizationName} sobre el error`,
      description: 'Hay un problema con la cuenta que recibirá tu pago.',
    },
    tryOtherBank: {
      title: 'Reintenta el proceso con otro banco',
      description: `Hubo un problema conectándonos con ${data.bankTextEntitySelected}.`,
    },
    tryOtherBankConnection: {
      title: 'Reintenta el proceso con otro banco',
      description: `Hubo un problema conectando ${data.bankTextEntitySelected} con el banco que recibirá la transferencia.`,
    },
    tryOtherAccount: {
      title: 'Reintenta el proceso con otra cuenta',
      description: 'La cuenta que elegiste no está habilitada para hacer transferencias.',
    },
    mfaNotFound: {
      title: 'Reintenta el proceso con otro banco',
      description: 'No está disponible el método de autentificación que está activado para esta cuenta.',
    },
    contactNotFound: {
      title: 'Vuelve a intentarlo con esta misma cuenta',
      description: 'Hubo un problema agregando el destinatario nuevo en tu cuenta.',
    },
    tryAgainLater: {
      title: 'Vuelve a intentarlo más tarde',
      description: 'Estamos temporalmente desconectados, pronto volveremos a estar en línea.',
    },
    checkAccountBeforeRetry: {
      title: 'Revisa tu cuenta de banco antes de reintentar',
      description: 'Hubo un error recibiendo la confirmación de la transferencia. Si tienes un cobro, no te preocupes, nosotros nos encargamos. Si no lo tienes reintenta el proceso.',
    },
    timeoutUserStep: {
      title: 'Vuelve a intentarlo',
      description: 'Se ha agotado el tiempo máximo para realizar este paso.',
    },
    timeoutSecurity: {
      title: 'Vuelve a intentarlo desde el principio',
      description: 'Ha transcurrido el tiempo máximo de seguridad para realizar esta operación.',
    },
    linkIntentFailed: {
      title: 'Reintenta el proceso',
      description: `Hubo un problema accediendo a tu cuenta en ${data.bankTextEntitySelected} en este momento. Te recomendamos intentar de nuevo con este banco.`,
    },
    activeSession: {
      title: 'Vuelve a intentarlo más tarde con esta cuenta',
      description: `Ya hay una sesión activa de tu cuenta en ${data.bankTextEntitySelected}. Te recomendamos intentar de nuevo con este banco en unos minutos.`,
    },
    defaultError: {
      title: 'Vuelve a intentarlo desde el principio',
      description: 'Hubo un problema en el proceso.',
    },
  };
}

export {
  buildErrorMessage,
  isErrorInsufficientBalance,
  isErrorAccountSelectionTimeout,
  isErrorDailyLimitMax,
  isErrorAmountLimitMax,
  isErrorNewContactLimitMax,
  isPaymentRejectedByUserApp,
  isPaymentContactRejectedByUserApp,
  isPaymentAppTimeout,
  isPaymentContactAppTimeout,
  isErrorInternal,
  isErrorMfaCoordinatesTimeout,
  isErrorMfaSmsTimeout,
  isErrorMfaDeviceTimeout,
  isErrorNewContactTransferLimitReached,
  isErrorMfaSmsInvalid,
  isErrorMfaCoordinatesInvalid,
  isErrorMfaDeviceInvalid,
  isErrorBankUnavailable,
  isErrorBankRetryLater,
  isErrorMfaRequiresBankAssist,
  isErrorAccountNotSupported,
  isErrorInvalidRecipientAccount,
  isErrorRecipientAccountPaymentLimit,
  isRecipientAccountIssue,
  isTryAgainLaterError,
  isCheckAccountBeforeRetry,
  isTimeoutError,
};
