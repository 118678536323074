import transitions from '../constants/transitions';

const dontCountTransitions = [
  transitions.LINK_INTENT_FAILED,
  transitions.WAITING_ACTION_FAILED,
  transitions.PAYMENTS_CONFIRMED_FAILED,
  transitions.PAYMENTS_SELECTING_FAILED,
  transitions.PAYMENTS_INSUFFICIENT_FUNDS_FAILED,
  transitions.PROCESSING_PAYMENTS_FAILED,
  transitions.PROCESSING_ACTION_FAILED,
  transitions.SECOND_FACTOR_FAILED,
];

function checkCounterValue(transition) {
  if (!dontCountTransitions.includes(transition)) {
    if (transition !== transitions.GO_BACK && transition !== transitions.RETRY_LINK_INTENT) {
      return 1;
    }
    return -1;
  }
  return 0;
}

export default {
  checkCounterValue,
};
