<template>
  <div>
    <div
      v-if="!anonimizedEntities"
      :is="currentComponent"
      class="w-8 h-8"
    />
    <anonimized-logo v-else/>
  </div>
</template>

<script>
import { mapState } from 'vuex';

import AnonimizedLogo from './AnonimizedEntityLogo.vue';
import AuthAppLogo from './AuthAppLogo.vue';
import Card from '../icons/Auth/type/CardIcon.vue';
import Device from '../icons/Auth/type/DeviceIcon.vue';
import Sms from '../icons/Auth/type/SmsIcon.vue';

export default {
  props: {
    authType: String,
  },
  computed: {
    ...mapState({
      anonimizedEntities: (state) => state.config.anonimizedEntities,
      holderType: (state) => state.config.holderType,
      entityId: (state) => state.widget.entityIdSelected || state.refreshIntent.institutionId,
    }),
    currentComponent() {
      const authTypes = {
        device: 'device',
        app: 'auth-app-logo',
        sms: 'sms',
        coordinates: 'card',
      };
      return authTypes[this.authType];
    },
  },
  components: {
    AnonimizedLogo,
    AuthAppLogo,
    Card,
    Device,
    Sms,
  },
};
</script>
