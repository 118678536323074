import { mapState } from 'vuex';
import { checkOverflow, checkIsAtScrollableBottom } from '../../helpers/style';

export default {
  data() {
    return {
      isMainContentOverflowing: false,
      isAtScrollableBottom: false,
    };
  },
  computed: {
    ...mapState({
      windowHeightState: (state) => state.widget.windowHeight,
    }),
    canScrollToBottom() {
      return this.isMainContentOverflowing && !this.isAtScrollableBottom;
    },
  },
  watch: {
    windowHeightState() {
      const element = this.$refs.mainContentContainer;
      this.isMainContentOverflowing = checkOverflow(element);
      this.isAtScrollableBottom = checkIsAtScrollableBottom(element);
    },
  },
  mounted() {
    const element = this.$refs.mainContentContainer;
    this.isMainContentOverflowing = checkOverflow(element);
  },
  methods: {
    onScroll(event) {
      this.isAtScrollableBottom = checkIsAtScrollableBottom(event.target);
    },
  },
};
