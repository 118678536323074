import { render, staticRenderFns } from "./AnonimizedEntityButton.vue?vue&type=template&id=32da3438&scoped=true&"
import script from "./AnonimizedEntityButton.vue?vue&type=script&lang=js&"
export * from "./AnonimizedEntityButton.vue?vue&type=script&lang=js&"
import style0 from "./AnonimizedEntityButton.vue?vue&type=style&index=0&id=32da3438&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32da3438",
  null
  
)

export default component.exports