import axios from 'axios';
import { camelizeKeys, decamelizeKeys } from 'humps';

const instance = axios.create(
  {
    baseURL: process.env.VUE_APP_BASE_API_HOST,
    transformResponse: [
      ...axios.defaults.transformResponse,
      (data) => camelizeKeys(data),
    ],
    transformRequest: [
      (data) => decamelizeKeys(data),
      ...axios.defaults.transformRequest,
    ],
  },
);

let postAndPatchRequest = {};
const idempotencyKeyStatus = {};

export const cancelAllPostAndPatchRequest = () => {
  Object.values(postAndPatchRequest).forEach((request) => request.cancel());
  postAndPatchRequest = {};
};

export const blockAllIdempotencyKeyNextRequest = () => {
  Object.keys(idempotencyKeyStatus).forEach((key) => {
    idempotencyKeyStatus[key] = false;
  });
};

async function buildCryptoKeyDigest(keyRaw) {
  const keyPromise = crypto.subtle
    .digest('SHA-256', new TextEncoder().encode(keyRaw));
  const key = await keyPromise;
  return key;
}

function keyDigestToString(keyDigest) {
  // crypto return an ArrayBuffer. The documentation says
  // for comparison use it as hex string, so we convert it here
  // https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const hashArray = Array.from(new Uint8Array(keyDigest));
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('');
  return hashHex;
}

async function buildCryptoKeyString(keyRaw) {
  const keyDigest = await buildCryptoKeyDigest(keyRaw);
  const keyString = keyDigestToString(keyDigest);
  return keyString;
}

async function buildRequestKey(request) {
  const keyRaw = `${request.url}&${
    request.method}&${
    JSON.stringify(request.params)}&${
    JSON.stringify(request.data)}&${
    JSON.stringify(getIdempotencyKey(request))}`;
  const key = await buildCryptoKeyString(keyRaw);
  return key;
}

const getIdempotencyKey = (request) => request.headers['Idempotency-Key'];

async function handleRequest(request) {
  if (!mustHandleRequest(request)) {
    return request;
  }

  const idempotencyKey = getIdempotencyKey(request);
  const requestKey = await buildRequestKey(request);
  const axiosSource = axios.CancelToken.source();
  // eslint-disable-next-line no-param-reassign
  request.cancelToken = axiosSource.token;

  if (postAndPatchRequest[requestKey] !== undefined || idempotencyKeyStatus[idempotencyKey] === false) {
    axiosSource.cancel();
  } else {
    postAndPatchRequest[requestKey] = axiosSource;
    idempotencyKeyStatus[idempotencyKey] = true;
  }

  return request;
}

const mustHandleRequest = (request) => isPostOrPatch(request) && hasWidgetToken(request);
const isPostOrPatch = (request) => request.method === 'post' || request.method === 'patch';
const hasWidgetToken = (request) => request.data.widget_token !== undefined && request.data.widget_token !== null && request.data.widget_token !== '';

instance.interceptors.request.use(handleRequest);

export default instance;
