const INTRODUCTION = 'INTRODUCTION';
const BANK_SELECTION = 'BANK_SELECTION';
const FISCAL_AUTHORITIES = 'FISCAL_AUTHORITIES';
const LOGIN = 'LOGIN';
const ERROR_VIEW = 'ERROR_VIEW';
const WIDGET_CLOSED = 'WIDGET_CLOSED';

const LOADING_LINK_INTENT = 'LOADING_LINK_INTENT';
const LINK_CREATED = 'LINK_CREATED';
const LINK_INTENT_PROCESSING_ACTION = 'LINK_INTENT_PROCESSING_ACTION';

const APP_AUTH_REQUIRED = 'APP_AUTH_REQUIRED';
const CAPTCHA_AUTH_REQUIRED = 'CAPTCHA_AUTH_REQUIRED';
const DEVICE_AUTH_REQUIRED = 'DEVICE_AUTH_REQUIRED';
const CARD_AUTH_REQUIRED = 'CARD_AUTH_REQUIRED';
const SMS_AUTH_REQUIRED = 'SMS_AUTH_REQUIRED';
const EMAIL_AUTH_REQUIRED = 'EMAIL_AUTH_REQUIRED';

const SUBSCRIPTION_CONFIRMATION_REQUIRED = 'SUBSCRIPTION_CONFIRMATION_REQUIRED';
const SUBSCRIPTION_PROCESSING_ACTION = 'SUBSCRIPTION_PROCESSING_ACTION';
const LOADING_SUBSCRIPTION = 'LOADING_SUBSCRIPTION';
const SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED';
const SUBSCRIPTION_ABORTED = 'SUBSCRIPTION_ABORTED';
const SUBSCRIPTION_ERROR = 'SUBSCRIPTION_ERROR';

const PAYMENTS_CONFIRMATION_REQUIRED = 'PAYMENTS_CONFIRMATION_REQUIRED';
const PROCESSING_PAYMENTS = 'PROCESSING_PAYMENTS';
const PAYMENTS_CREATED = 'PAYMENTS_CREATED';
const PAYMENTS_PROCESSING_ACTION = 'PAYMENTS_PROCESSING_ACTION';
const PAYMENTS_ERROR = 'PAYMENTS_ERROR';
const PAYMENTS_SELECTING_AUTH = 'PAYMENTS_SELECTING_AUTH';
const PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK = 'PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK';

const REFRESH_INTENT_CREATED = 'REFRESH_INTENT_CREATED';
const REFRESH_INTENT_PROCESSING_ACTION = 'REFRESH_INTENT_PROCESSING_ACTION';
const REFRESH_INTENT_FETCHING_DATA = 'REFRESH_INTENT_FETCHING_DATA';
const REFRESH_INTENT_SUCCEEDED = 'REFRESH_INTENT_SUCCEEDED';
const REFRESH_INTENT_ERROR = 'REFRESH_INTENT_ERROR';

const TOKEN_HELPER = 'TOKEN_HELPER';
const NEW_PASSWORD_HELPER = 'NEW_PASSWORD_HELPER';
const RESET_PASSWORD_HELPER = 'RESET_PASSWORD_HELPER';

export default {
  APP_AUTH_REQUIRED,
  BANK_SELECTION,
  FISCAL_AUTHORITIES,
  CAPTCHA_AUTH_REQUIRED,
  CARD_AUTH_REQUIRED,
  EMAIL_AUTH_REQUIRED,
  LOGIN,
  DEVICE_AUTH_REQUIRED,
  ERROR_VIEW,
  INTRODUCTION,
  LINK_CREATED,
  LOADING_LINK_INTENT,
  LINK_INTENT_PROCESSING_ACTION,
  LOADING_SUBSCRIPTION,
  SUBSCRIPTION_CONFIRMATION_REQUIRED,
  SMS_AUTH_REQUIRED,
  SUBSCRIPTION_CREATED,
  SUBSCRIPTION_ABORTED,
  SUBSCRIPTION_ERROR,
  PAYMENTS_PROCESSING_ACTION,
  SUBSCRIPTION_PROCESSING_ACTION,
  WIDGET_CLOSED,
  PAYMENTS_CONFIRMATION_REQUIRED,
  PROCESSING_PAYMENTS,
  PAYMENTS_CREATED,
  PAYMENTS_ERROR,
  PAYMENTS_SELECTING_AUTH,
  PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK,
  REFRESH_INTENT_CREATED,
  REFRESH_INTENT_PROCESSING_ACTION,
  REFRESH_INTENT_FETCHING_DATA,
  REFRESH_INTENT_SUCCEEDED,
  REFRESH_INTENT_ERROR,
  TOKEN_HELPER,
  NEW_PASSWORD_HELPER,
  RESET_PASSWORD_HELPER,
};
