<template>
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col w-full items-center justify-center h-full space-y-8 px-4">
        <EntityLogo />
        <Error class="min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />
        <div class="flex flex-col text-center">
          <div id="saTitleText" class="font-M font-700 color-txt-heading my-4">
            {{ abortTitle }}
          </div>
          <div id="saDescText" class="font-S font-400 color-txt-body max-w-xs">
            {{ abortDescription }}
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Terminar"
          @click.native="widgetFinished()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WidgetLayout from '../layout/WidgetLayout.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import Error from '../icons/Error.vue';
import EntityLogo from '../layout/EntityLogo.vue';

export default {
  methods: {
    ...mapActions(['widgetFinished']),
  },
  computed: {
    ...mapState({
      errorReason: (state) => state.subscription.errorReason,
      organizationName: (state) => state.config.organizationName,
    }),
    abortTitle() {
      return 'Suscripción abortada';
    },
    abortDescription() {
      if (this.isSubscriptionAlreadyExists) {
        return `Ya tienes un PAC activo con ${this.organizationName}`;
      }
      return '';
    },
    isSubscriptionAlreadyExists() {
      return this.errorReason === 'subscription_already_exists';
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Error,
    EntityLogo,
  },
};
</script>
