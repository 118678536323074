import api from '../api';
import constants from '../constants';

const initialState = {
  challenges: null,
  secondFactorType: null,
  status: null,
  amount: null,
  datetime: null,
  accountNumber: null,
  accountBalance: null,
  accountName: null,
  accountOfficialName: null,
  url: null,
  paymentIntent: null,
  errorReason: null,
  paymentMfaCounter: 0,
  contactMfaCounter: 0,
  authOptions: null,
  simulateCheckContact: false,
};

const getters = {
  isBancoEstadoComission(state, _, rootState) {
    const isEntitySelectedBancoEstado = rootState.widget.entityIdSelected === 'cl_banco_estado';
    const isAccountOfficialNameCuentaRut = state.accountOfficialName?.toLowerCase().includes('rut') || false;
    const isRecipientNotBancoEstado = state.paymentIntent.recipientAccount.institutionId !== 'cl_banco_estado';
    return (isEntitySelectedBancoEstado && isAccountOfficialNameCuentaRut && isRecipientNotBancoEstado);
  },
  isSufficientBalance: (state) => (account, commission) => account.balance.limit - state.amount - commission >= 0,
  calculateCommission(state, gett, rootState) {
    let { otherBankCommission } = rootState.config;
    if (rootState.widget.entityIdSelected === 'cl_banco_estado') {
      otherBankCommission = Number(gett.isBancoEstadoComission && rootState.config.otherBankCommission);
    }
    const isInstitutionSelectedEqualToRecipient = rootState.widget.entityIdSelected === state.paymentIntent.recipientAccount.institutionId;
    return isInstitutionSelectedEqualToRecipient ? 0 : otherBankCommission;
  },
  parsedAmountWithComission(state, gett) {
    const balanceAndComission = state.amount + gett.calculateCommission;
    return balanceAndComission.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
  },
  getPaymentSubText(state, gett, rootState) {
    const contactFirstAuthText = 'Necesitas autorizar el nuevo destinatario porque es la primera vez que le transfieres';
    const contactSecondAuthText = 'Necesitas ingresar el segundo código solicitado para autorizar el nuevo destinatario';
    const paymentFirstAuthText = 'Necesitas autorizar la transferencia';
    const paymentSecondAuthText = 'Necesitas ingresar el segundo código solicitado para autorizar la transferencia';
    const stateContext = rootState.widget.currentStateContext;
    if (stateContext === constants.state_context.CONTACT_AUTH) {
      if (state.contactMfaCounter === 0) {
        return contactFirstAuthText;
      }
      if (state.contactMfaCounter === 1) {
        return contactSecondAuthText;
      }
      return contactFirstAuthText;
    }
    if (stateContext === constants.state_context.PAYMENT_AUTH) {
      if (state.paymentMfaCounter === 0) {
        return paymentFirstAuthText;
      }
      if (state.paymentMfaCounter === 1) {
        return paymentSecondAuthText;
      }
      return paymentFirstAuthText;
    }
    return paymentFirstAuthText;
  },
};

const mutations = {
  loadPaymentsFrozenState(state, payload) {
    Object.assign(state, { ...(payload || {}) });
  },
  getPaymentIntent(state, payload) {
    Object.assign(state, { paymentIntent: payload });
  },
  getPaymentStatus(state, payload) {
    const {
      status, amount, createdAt, nextAction, referenceId, errorReason, authOptions, recipientHolderName,
    } = payload;
    Object.assign(state, {
      status,
      amount,
      datetime: createdAt,
      secondFactorType: nextAction ? nextAction.type : null,
      referenceId,
      errorReason,
      authOptions,
      recipientHolderName,
      challenges: nextAction ? nextAction.challenges : null,
    });
  },
  confirmPaymentIntent(state, payload) {
    state.status = payload.state;
  },
  setAccountNumber(state, number) {
    state.accountNumber = number;
  },
  setAccountBalance(state, balance) {
    state.accountBalance = balance;
  },
  setAccountName(state, name) {
    state.accountName = name;
  },
  setAccountOfficialName(state, officialName) {
    state.accountOfficialName = officialName;
  },
  getPaymentVoucherUrl(state, payload) {
    const {
      url,
    } = payload;
    Object.assign(state, {
      url,
    });
  },
  setPaymentMfaCounter(state) {
    state.paymentMfaCounter += 1;
  },
  setContactMfaCounter(state) {
    state.contactMfaCounter += 1;
  },
  setSimulateCheckContact(state, { value }) {
    state.simulateCheckContact = value;
  },
};

const actions = {
  async getPaymentStatus({ rootState, commit }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.payments.getStatus({
      widgetToken,
      publicKey,
    });
    commit('getPaymentStatus', response.data);
  },
  async sendPaymentSelectedAuth({ rootState }, { auth, idempotencyKey }) {
    const { publicKey, widgetToken } = rootState.config;
    await api.payments.selectAuth({
      widgetToken,
      publicKey,
      auth,
      idempotencyKey,
    });
  },
  async confirmPaymentIntent({ commit, rootState }, { account, idempotencyKey }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.payments.confirm({
      widgetToken,
      publicKey,
      accountId: account.id,
      idempotencyKey,
    });
    commit('confirmPaymentIntent', response.data);
    commit('setAccountNumber', account.number);
    commit('setAccountBalance', account.balance);
    commit('setAccountName', account.name);
    commit('setAccountOfficialName', account.officialName);
  },
  async sendPaymentSecondAuth({ rootState }, { secondFactor, idempotencyKey }) {
    const { publicKey, widgetToken } = rootState.config;
    await api.payments.send2FA({
      widgetToken,
      publicKey,
      secondFactor,
      idempotencyKey,
    });
  },
  async getPaymentVoucherUrl({ rootState, commit }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.payments.getVoucherUrl({
      widgetToken,
      publicKey,
    });
    commit('getPaymentVoucherUrl', response.data);
  },
  async getPaymentIntent({ commit, rootState }) {
    const { publicKey, widgetToken } = rootState.config;
    const response = await api.payments.get({ widgetToken, publicKey });
    commit('getPaymentIntent', response.data);
  },
  async setPaymentMfaCounter({ commit }) {
    commit('setPaymentMfaCounter');
  },
  async setContactMfaCounter({ commit }) {
    commit('setContactMfaCounter');
  },
  setSimulateCheckContact({ commit }, { value }) {
    commit('setSimulateCheckContact', { value });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
