<template>
  <button
    :disabled="disabled"
    class="flex flex-col justify-center items-center w-full min-w-full min-h-26 max-h-26 shadow-sm rounded-lg space-y-2
          bg-white focus:ring focus:ring-primary-focus hover:border-solid hover:border-1.5 hover:border-primary-main"
  >
    <AuthSelectLogo :authType="authType"/>
    <span class="font-bold text-xs text-body-color">
      {{ text }}
    </span>
  </button>
</template>

<script>
import AuthSelectLogo from './AuthSelectLogo.vue';

export default {
  props: {
    authType: String,
    disabled: Boolean,
    text: String,
  },
  components: {
    AuthSelectLogo,
  },
};
</script>
