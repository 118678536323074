<template >
  <widget-layout>
    <div class="outer-container">
      <div class="container-ap">
        <div class="top-container-ap">
          <EntityLogo />
        </div>

        <div class="text-center">
          <div class="font-M font-700 color-txt-heading"> {{ textHeading }} </div>
          <div class="font-S font-400 color-txt-body"> Esto demora unos segundos </div>
        </div>

        <Spinner class="mt-6 z-10" />
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Aceptar"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import utils from '../../../helpers/utils';
import constants from '../../../constants';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import Footer from '../../layout/Footer.vue';
import ActionButton from '../../layout/ActionButton.vue';
import Spinner from '../../layout/Spinner.vue';
import products from '../../../../sharedTypes/products';
import EntityLogo from '../../layout/EntityLogo.vue';

const { setFrozenFriendlyTimeout } = utils;

export default {
  data() {
    return {
      polling: null,
    };
  },
  computed: {
    ...mapState({
      product: (state) => state.config.product,
      entityId: (state) => state.widget.entityIdSelected || state.refreshIntent.institutionId,
    }),
    ...mapGetters(['resourceStatus', 'resourceFailureReason']),
    isRefreshIntent() {
      return this.product === products.REFRESH_INTENT;
    },
    textHeading() {
      if (this.entityId === 'mx_banco_bbva') {
        return 'Validando token';
      }
      return 'Validando tu segundo factor...';
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'getResource', 'setErrorType']),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    transitionToFailed() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PROCESSING_ACTION_FAILED,
      });
    },
    async transitionToSucceeded() {
      this.clearPolling();
      const { REFRESH_INTENT_SUCCESSFUL, LINK_INTENT_SUCCESSFUL } = constants.transitions;
      const transition = this.isRefreshIntent ? REFRESH_INTENT_SUCCESSFUL : LINK_INTENT_SUCCESSFUL;
      this.transitionToNextState({
        transition,
      });
    },
    async transitionToLoadingResource() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PROCESSING_ACTION_SUCCEEDED,
      });
    },
    async requestResourceStatus() {
      try {
        await this.getResource();
      } catch (error) {
        Sentry.captureException(error);
        this.transitionToFailed();
      }
    },
    pollingTimeout() {
      if (this.polling !== null) {
        this.transitionToFailed();
      }
    },
    pollSubscriptionIntent() {
      this.polling = setInterval(() => {
        this.requestResourceStatus();
      }, process.env.VUE_APP_SUBSCRIPTION_INTENT_POLLING_RATE_SEC);
      setFrozenFriendlyTimeout(this.pollingTimeout, process.env.VUE_APP_SUBSCRIPTION_INTENT_POLLING_TIMEOUT_SEC);
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    this.pollSubscriptionIntent();
  },
  watch: {
    resourceStatus(status) {
      const processingStatuses = ['processing_link_intent', 'fetching_movements', 'processing_refresh_intent'];
      if (status === 'succeeded') {
        this.transitionToSucceeded();
      }
      if (processingStatuses.includes(status)) {
        this.transitionToLoadingResource();
      }
      if (status === 'failed') {
        if (this.resourceFailureReason === 'invalid_mfa_error') {
          this.setErrorType({ errorType: constants.errors.INVALID_MFA });
        }
        this.transitionToFailed();
      }
      if (status === 'rejected') {
        let errorType;
        if (this.linkIntentReason === 'credentials_locked') {
          errorType = constants.errors.LINK_INTENT_REJECTED_CREDENTIALS_LOCKED;
        } else {
          errorType = constants.errors.LINK_INTENT_REJECTED_INVALID_CREDENTIALS;
        }
        this.setErrorType({ errorType });
        this.transitionToFailed();
      }
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Spinner,
    EntityLogo,
  },
};
</script>

<style>

.top-container-ap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
}

.container-ap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100vw;
  max-width: 360px;
}

</style>
