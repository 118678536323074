<template>
<div :class="containerClass">
  <div :class="(isSufficientBalance(account, calculateCommission) ? 'text-ai font-XS font-400 color-txt-body' : 'text-ai font-XS font-400 color-txt-disable')"> {{account.name}} </div>
  <div class="text-ai">
    <div :class="(isSufficientBalance(account, calculateCommission) ? 'font-XS font-700 color-txt-body' : 'font-XS font-700 color-txt-disable')">
      {{ hideSensitiveInformation(account.number)}}
    </div>
    <div :class="(isSufficientBalance(account, calculateCommission) ? 'font-S font-700 color-txt-body' : 'font-S font-700 color-txt-disable')">
      {{ parsedAmount(account.balance.available) }}
    </div>
  </div>
  <div v-if="canShowCreditLine(account)">
    <div class="underline" />
    <div :class="(isSufficientBalance(account, calculateCommission) ? 'text-ai font-XS font-400 color-txt-body' : 'text-ai font-XS font-400 color-txt-disable')"> Línea de crédito </div>
    <div class="text-ai">
      <div :class="(isSufficientBalance(account, calculateCommission) ? 'font-XS font-700 color-txt-body' : 'font-XS font-700 color-txt-disable')">
        Disponible
      </div>
      <div :class="(isSufficientBalance(account, calculateCommission) ? 'font-S font-700 color-txt-body' : 'font-S font-700 color-txt-disable')">
        {{ parsedAmount(calculateCreditLine(account)) }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import PaymentsMixin from '../mixins/payments';
import AccountMixin from '../mixins/account';

export default {
  props: {
    account: {
      balance: Object,
      number: Number,
      name: String,
    },
    containerClass: String,
  },
  mixins: [PaymentsMixin, AccountMixin],
  computed: {
    ...mapGetters(['isSufficientBalance', 'calculateCommission']),
  },
};
</script>

<style scoped>

.text-ai {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 80px);
  max-width: 280px;
  min-width: 200px;
}

.underline {
  width: 100%;
  height: 1px;
  margin: 5px 0px;
  border: 1px solid #E2E1E9;
  box-sizing: border-box;
}

</style>
