/* eslint-disable import/prefer-default-export */

export const ErrorMessages = {
  amount_below_minimum: {
    title: () => 'Monto a transferir es menor al mínimo permitido',
    description: (bankName, minAmount) => `El monto mínimo permitido en ${bankName} es de ${minAmount}`,
  },
  unknown_validation_error: {
    title: () => 'Los datos de transferencia no son permitidos por tu banco',
    description: (bankName) => `Tuvimos un problema validando los datos de transferencia con ${bankName}`,
  },
};
