export default {
  data() {
    return {
      debounceTimeout: [],
    };
  },
  methods: {
    debounce(fn, delay = 100, ...args) {
      const key = fn.name + fn.length;
      clearTimeout(this.debounceTimeout[key]);
      const that = this;
      this.debounceTimeout[key] = setTimeout(async () => {
        await fn.apply(that, args);
      }, delay);
    },
  },
};
