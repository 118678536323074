<template>
  <widget-layout>
    <div class="outer-container px-4 justify-start space-y-8">
      <AuthInfo />
      <div class="flex flex-col w-full items-center space-y-4">
        <Timer :input-time="showTimeout" />
        <AuthAction :authType="sms" />
        <NumericInputs
          v-bind:length="inputLength"
          @input="updateCode"
          @keyenter="debounceSendCode"
        />
      </div>
      <AuthDescription />
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="(loading || !isCodeValid)"
          :text="btnText"
          @click.native="debounceSendCode"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';
import Debounce from '../../mixins/debounce';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import Timer from '../../lib/Timer.vue';
import constants from '../../../constants';
import PollingMixin from '../../mixins/polling';
import ValidationMixin from '../../mixins/validation';
import Footer from '../../layout/Footer.vue';
import ActionButton from '../../layout/ActionButton.vue';
import AuthInfo from '../AuthInfo.vue';
import AuthAction from '../AuthAction.vue';
import AuthDescription from '../AuthDescription.vue';
import NumericInputs from '../NumericInputs.vue';
import authTypes from '../../../../sharedTypes/authTypes';
import optimizelyFlags from '../../../helpers/optimizely';

export default {
  mixins: [PollingMixin, ValidationMixin, Debounce],
  data() {
    return {
      loading: false,
      value: '',
      idempotencyKey: '',
    };
  },
  computed: {
    ...mapGetters([
      'isSubscription',
      'isSubscriptionIntent',
      'isPayment',
      'isLinkIntent',
      'isRefreshIntent',
      'isBancoFalabella',
    ]),
    ...mapState({
      paymentStatus: (state) => state.payments.status,
      product: (state) => state.config.product,
      stateContext: (state) => state.widget.currentStateContext,
      organizationName: (state) => state.config.organizationName,
      stateLog: (state) => state,
      mfaConfig: (state) => state.config.mfaConfig,
      senderEntityId: (state) => state.widget.entityIdSelected,
      contactMfaCounter: (state) => state.payments.contactMfaCounter,
      paymentMfaCounter: (state) => state.payments.paymentMfaCounter,
    }),
    getPaymentBtnText() {
      const contactAuthText = 'Continuar';
      const paymentAuthText = 'Transferir';
      if (this.stateContext === constants.state_context.CONTACT_AUTH) {
        return contactAuthText;
      }
      if (this.stateContext === constants.state_context.PAYMENT_AUTH) {
        return paymentAuthText;
      }
      return paymentAuthText;
    },
    btnText() {
      if (this.isSubscription || this.isSubscriptionIntent) return 'Suscribir';
      if (this.isPayment) return this.getPaymentBtnText;
      if (this.isLinkIntent || this.isRefreshIntent) return 'Aceptar';
      return 'Continuar';
    },
    inputLength() {
      if (
        this.mfaConfig
        && this.mfaConfig.sms.authLength !== undefined
        && this.mfaConfig.sms.authLength !== null
      ) return this.mfaConfig.sms.authLength;
      if (this.isBancoFalabella) {
        return 6;
      }
      return 4;
    },
    showTimeout() {
      if (this.mfaConfig) {
        const { timeout } = this.mfaConfig.sms;
        return timeout;
      }
      return null;
    },
    isCodeValid() {
      return (this.isLengthValid(this.value.length, this.inputLength) && this.isNum(this.value));
    },
    sms() {
      return authTypes.SMS;
    },
  },
  methods: {
    ...mapActions([
      'transitionToNextState', 'sendSubscriptionSecondAuth', 'sendPaymentSecondAuth',
      'sendLinkIntentSecondAuth', 'sendRefreshIntentSecondAuth', 'setPaymentMfaCounter',
      'setContactMfaCounter', 'getPaymentStatus', 'sendSubscriptionIntentSecondAuth',
    ]),
    updateCode(value) {
      if (this.isNum(value)) {
        this.value = value;
      }
    },
    async sendSecondAuth(args) {
      if (this.isSubscription) {
        return this.sendSubscriptionSecondAuth(args);
      }
      if (this.isSubscriptionIntent) {
        return this.sendSubscriptionIntentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      if (this.isPayment) {
        return this.sendPaymentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      if (this.isLinkIntent) {
        return this.sendLinkIntentSecondAuth(args);
      }
      if (this.isRefreshIntent) {
        return this.sendRefreshIntentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      throw new Error(`Second factor for product: ${this.product} unavailable.`);
    },
    debounceSendCode() {
      if (!this.isCodeValid) {
        return;
      }
      this.loading = true;
      this.trackMfaAttempted();
      this.debounce(this.sendCode);
    },
    async sendCode() {
      this.loading = true;
      try {
        await this.sendSecondAuth({ secondFactor: this.value });
        if (this.stateContext === constants.state_context.PAYMENT_AUTH) {
          this.setPaymentMfaCounter();
        } else if (this.stateContext === constants.state_context.CONTACT_AUTH) {
          this.setContactMfaCounter();
        }
        this.transitionToNextState({
          transition: constants.transitions.SECOND_FACTOR_SUCCEEDED,
        });
      } catch (error) {
        Sentry.captureException(error);
        if (error.__CANCEL__) {
          return;
        }

        this.transitionToFailed();
      }
      this.loading = false;
    },
    trackMfaAttempted() {
      if (this.isPayment) {
        optimizelyFlags.trackEvent('mfa_attempted');
      }
    },
    transitionToFailed() {
      this.transitionToNextState({
        transition: constants.transitions.SECOND_FACTOR_FAILED,
      });
    },
    async requestPaymentsStatus() {
      try {
        await this.getPaymentStatus();
      } catch (error) {
        Sentry.captureException(error);
        this.transitionToFailed();
      }
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    if (this.isPayment) {
      this.startPolling(this.requestPaymentsStatus, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_RATE_SEC);
    }
  },
  mounted() {
    this.idempotencyKey = uuidv1();
  },
  watch: {
    paymentStatus(status) {
      if (status === 'failed') {
        this.transitionToFailed();
      }
      if (status === 'unknown') {
        this.transitionToFailed();
      }
      if (status === 'rejected') {
        this.transitionToFailed();
      }
    },
  },
  components: {
    Timer,
    WidgetLayout,
    ActionButton,
    AuthInfo,
    AuthAction,
    AuthDescription,
    Footer,
    NumericInputs,
  },
};
</script>
