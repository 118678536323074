<template >
  <widget-layout includeScrollArrow>
    <div class="outer-container">
      <div class="container-pc">
        <PaymentInfo
          v-if="!isProgressBarVariant"
          class="mt-2"
        />
        <Error v-if="(!this.isAnyAccountSufficientFunds)" class="mt-4 min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />

        <div class="middle-frame-pc">
          <div class="font-M font-700 color-txt-heading text-center"> {{titleText}} </div>
        </div>

        <div class="accounts-frame-pc">
          <div v-for="account in sortedAccounts" :key='account.id' @click="selectAccount(account)">
            <AccountInfo :containerClass="accountClass(account)" :account="account"/>
          </div>
        </div>
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="(!isAnyAccountSufficientFunds || loading)"
          text="Continuar"
          @click.native="debounceConfirmPayment()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';
import Debounce from '../mixins/debounce';
import constants from '../../constants';
import PollingMixin from '../mixins/polling';
import PaymentsMixin from '../mixins/payments';
import AccountMixin from '../mixins/account';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';
import AccountInfo from '../widget-views/AccountInfo.vue';
import Error from '../icons/Error.vue';
import PaymentInfo from './PaymentInfo.vue';
import Hotjar from '../../helpers/hotjar';
import optimizelyFlags from '../../helpers/optimizely';

export default {
  mixins: [PollingMixin, Debounce, PaymentsMixin, AccountMixin],
  data() {
    return {
      loading: false,
      account: null,
      idempotencyKey: '',
    };
  },
  computed: {
    ...mapGetters(['isSufficientBalance', 'calculateCommission']),
    ...mapState({
      accounts: (state) => state.link.accounts,
      organizationName: (state) => state.config.organizationName,
      entityId: (state) => state.widget.entityIdSelected,
      paymentIntent: (state) => state.payments.paymentIntent,
      paymentDatetime: (state) => state.payments.datetime,
      linkUsername: (state) => state.link.username,
      paymentAmount: (state) => state.payments.amount,
      paymentStatus: (state) => state.payments.status,
    }),
    sortedAccounts() {
      return this.getPaymentSortedAndFilteredAllowedAccounts(this.accounts);
    },
    permittedAccounts() {
      return this.sortedAccounts.filter((account) => this.isSufficientBalance(account, this.calculateCommission));
    },
    titleText() {
      if (this.isAnyAccountSufficientFunds) {
        return 'Selecciona la cuenta para realizar el pago por transferencia';
      }
      return 'No tienes saldo suficiente para realizar el pago por transferencia';
    },
    isAnyAccountSufficientFunds() {
      return this.permittedAccounts.length > 0;
    },
    hotjarPaymentAttributes() {
      return {
        rut: this.linkUsername,
        created_at: this.paymentDatetime,
        bank: this.entityId,
        payment_intent_id: this.paymentIntent.id,
        company_name: this.organizationName,
        amount: this.paymentAmount,
        sender_account: this.account && this.account.number,
      };
    },
    isProgressBarVariant() {
      return optimizelyFlags.progressBarVariant;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'confirmPaymentIntent', 'getPaymentStatus']),
    debounceConfirmPayment() {
      this.loading = true;
      this.debounce(this.confirmPayment);
    },
    async confirmPayment() {
      this.loading = true;
      try {
        if (!this.isAnyAccountSufficientFunds) {
          return;
        }
        const account = this.account === null ? this.sortedAccounts[0] : this.account;
        this.identifyPaymentOnHotjar();
        await this.confirmPaymentIntent({ account, idempotencyKey: this.idempotencyKey });
        this.transitionToNextState({ transition: constants.transitions.PAYMENTS_CONFIRMED_SUCCEEDED });
      } catch (error) {
        Sentry.captureException(error);
        if (error.__CANCEL__) {
          return;
        }

        this.transitionToNextState({ transition: constants.transitions.PAYMENTS_CONFIRMED_FAILED });
      }
      this.loading = false;
    },
    isSelectedAccount(account) {
      if (this.account) {
        return this.account.id === account.id;
      }
      if (this.isAnyAccountSufficientFunds) {
        return this.permittedAccounts[0].id === account.id;
      }
      return false;
    },
    selectAccount(account) {
      if (!this.loading && this.permittedAccounts.includes(account)) {
        this.account = account;
      }
    },
    accountCellSelected(account) {
      return this.isSelectedAccount(account) && this.isSufficientBalance(account, this.calculateCommission);
    },
    accountCellUnselected(account) {
      return !this.isSelectedAccount(account) && this.isSufficientBalance(account, this.calculateCommission);
    },
    accountCellDisabled(account) {
      return !this.isSelectedAccount(account) && !this.isSufficientBalance(account, this.calculateCommission);
    },
    accountClass(account) {
      const cellClass = this.canShowCreditLine(account) ? 'account-cell-with-credit-line' : 'account-cell';
      if (this.accountCellSelected(account)) {
        return `${cellClass} bg-selected-account-info border-solid border-1.5 border-primary-main`;
      }
      if (this.accountCellDisabled(account)) {
        return `${cellClass} cell-disabled`;
      }
      return `${cellClass} cell-not-selected`;
    },
    transitionToFailed() {
      this.transitionToNextState({
        transition: constants.transitions.PAYMENTS_CONFIRMED_FAILED,
      });
    },
    async requestPaymentsStatus() {
      try {
        await this.getPaymentStatus();
      } catch (error) {
        Sentry.captureException(error);
        this.transitionToFailed();
      }
    },
    identifyPaymentOnHotjar() {
      if (!Hotjar.isAPIAvailable()) return;

      Hotjar.sendEvent(this.paymentIntent.id);
      Hotjar.identifySession(this.paymentIntent.id, this.hotjarPaymentAttributes);
    },
    startHotjarSession() {
      if (!Hotjar.isAPIAvailable()) return;

      Hotjar.sendEvent('payment_confirmation_started');
      this.identifyPaymentOnHotjar();
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    this.startPolling(this.requestPaymentsStatus, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_RATE_SEC);
  },
  watch: {
    paymentStatus(status) {
      if (status === 'failed') {
        this.transitionToFailed();
      }
      if (status === 'unknown') {
        this.transitionToFailed();
      }
      if (status === 'rejected') {
        this.transitionToFailed();
      }
    },
  },
  mounted() {
    this.idempotencyKey = uuidv1();
    if (this.isAnyAccountSufficientFunds) {
      this.selectAccount(this.sortedAccounts[0]);
      this.startHotjarSession();
    }
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    AccountInfo,
    Error,
    PaymentInfo,
  },
};
</script>

<style>

.accounts-frame-pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;

  width: calc(100vw - 40px);
  max-width: 320px;
}

.middle-frame-pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 16px 0px;
}

.container-pc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px;
  width: 100vw;
  max-width: 360px;
}
</style>
