const MOBILE_OS_LIST = ['ios', 'android'];

// eslint-disable-next-line import/prefer-default-export
export const useDevice = () => {
  const { userAgentData } = navigator;
  const dataString = navigator.appVersion.toLowerCase();

  /**
   * @returns {'ios' | 'android' | 'windows' | 'macos' | 'linux' | 'unknown'}
   */
  const getOperatingSystem = () => {
    if (userAgentData?.platform) {
      return userAgentData.platform.toLowerCase();
    }
    if (dataString.includes('iphone')) {
      return 'ios';
    }
    if (dataString.includes('android')) {
      return 'android';
    }
    if (dataString.includes('windows')) {
      return 'windows';
    }
    if (dataString.includes('macintosh')) {
      return 'macos';
    }
    if (dataString.includes('linux')) {
      return 'linux';
    }
    return 'unknown';
  };

  /**
   *
   * @returns {boolean}
   */
  const getMobileStatus = () => {
    if (userAgentData) {
      return userAgentData.mobile;
    }
    const os = getOperatingSystem();
    return MOBILE_OS_LIST.includes(os);
  };

  const os = getOperatingSystem();
  const isMobile = getMobileStatus();

  return { os, isMobile };
};
