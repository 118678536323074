export default {
  methods: {
    calculateCreditLine(account) {
      return account.balance.limit - account.balance.available;
    },
    canShowCreditLine(account) {
      const accountTypesToShow = ['checking_account', 'savings_account', 'sight_account', 'rut_account'];
      return this.calculateCreditLine(account) > 0 && accountTypesToShow.includes(account.type);
    },
  },
};
