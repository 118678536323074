const LINK_INTENT_REJECTED_INVALID_CREDENTIALS = 'LINK_INTENT_REJECTED_INVALID_CREDENTIALS';
const LINK_INTENT_REJECTED_CREDENTIALS_LOCKED = 'LINK_INTENT_REJECTED_CREDENTIALS_LOCKED';
const LINK_INTENT_FAILED = 'LINK_INTENT_FAILED';
const LINK_INTENT_MFA_REQUIRED = 'LINK_INTENT_MFA_REQUIRED';
const INVALID_MFA = 'INVALID_MFA';

export default {
  LINK_INTENT_FAILED,
  LINK_INTENT_REJECTED_INVALID_CREDENTIALS,
  LINK_INTENT_REJECTED_CREDENTIALS_LOCKED,
  LINK_INTENT_MFA_REQUIRED,
  INVALID_MFA,
};
