<template>
  <widget-layout>
    <div class="outer-container">
      <div class="container-ril">
        <EntityLogo />

        <div class="intro-loading">
          <span class="font-M font-700 color-txt-heading"> {{textHeading}} </span>
          <span class="font-S font-400 color-txt-body text-center marginLR-20"> {{textBody}} </span>
        </div>

        <Spinner class="mt-8"/>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Continuar"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import utils from '../../helpers/utils';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import EntityLogo from '../layout/EntityLogo.vue';
import ActionButton from '../layout/ActionButton.vue';
import Spinner from '../layout/Spinner.vue';

const { setFrozenFriendlyTimeout } = utils;

export default {
  data() {
    return {
      polling: null,
    };
  },
  computed: {
    ...mapGetters(['entityName']),
    ...mapState({
      refreshIntentStatus: (state) => state.refreshIntent.status,
      secondFactorType: (state) => state.refreshIntent.secondFactorType,
    }),
    textHeading() {
      if (this.refreshIntentStatus === 'processing_refresh_intent') return 'Obteniendo movimientos';
      return `Conectándonos con ${this.entityName}...`;
    },
    textBody() {
      if (this.refreshIntentStatus === 'processing_refresh_intent') return 'Esto puede demorar unos minutos';
      return 'Esto demora unos segundos';
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'getRefreshIntent', 'setErrorType']),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    async transitionToFailed() {
      this.clearPolling();
      this.transitionToNextState({ transition: constants.transitions.REFRESH_INTENT_FAILED });
    },
    async transitionToSucceeded() {
      this.transitionToNextState({ transition: constants.transitions.REFRESH_INTENT_SUCCESSFUL });
    },
    async requestRefreshIntentStatus() {
      try {
        await this.getRefreshIntent();
      } catch (error) {
        Sentry.captureException(error);
        await this.transitionToFailed();
      }
    },
    async transitionToMFA() {
      this.clearPolling();
      switch (this.secondFactorType) {
        case 'enter_captcha_code': {
          this.transitionToNextState({
            transition: constants.transitions.REFRESH_INTENT_CAPTCHA_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_device_code': {
          this.transitionToNextState({
            transition: constants.transitions.REFRESH_INTENT_DEVICE_AUTH_REQUIRED,
          });
          break;
        }
        case 'authorize_in_app': {
          this.transitionToNextState({
            transition: constants.transitions.REFRESH_INTENT_APP_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_sms_code': {
          this.transitionToNextState({
            transition: constants.transitions.REFRESH_INTENT_SMS_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_coordinates': {
          this.transitionToNextState({
            transition: constants.transitions.REFRESH_INTENT_CARD_AUTH_REQUIRED,
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    async pollingTimeout() {
      if (this.polling !== null) {
        await this.transitionToFailed();
      }
      clearInterval(this.polling);
    },
    pollRefreshIntent() {
      this.polling = setInterval(
        this.requestRefreshIntentStatus,
        process.env.VUE_APP_LINK_INTENT_POLLING_RATE_SEC,
      );
      setFrozenFriendlyTimeout(this.pollingTimeout, process.env.VUE_APP_LINK_INTENT_TIMEOUT_SEC);
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  async created() {
    if (this.refreshIntentStatus === 'requires_action') this.transitionToMFA();
    this.pollRefreshIntent();
  },
  watch: {
    async refreshIntentStatus(status) {
      if (status === 'succeeded') {
        await this.transitionToSucceeded();
      }
      if (status === 'rejected') {
        const errorType = constants.errors.REFRESH_INTENT_REJECTED;
        this.setErrorType({ errorType });
        await this.transitionToFailed();
      }
      if (status === 'failed') {
        const errorType = constants.errors.REFRESH_INTENT_FAILED;
        this.setErrorType({ errorType });
        await this.transitionToFailed();
      }
      if (status === 'requires_action') {
        this.transitionToMFA();
      }
    },
  },
  components: {
    WidgetLayout,
    Footer,
    EntityLogo,
    ActionButton,
    Spinner,
  },
};
</script>

<style scoped>

.intro-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.container-ril {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px;
  width: 100%;
}
</style>
