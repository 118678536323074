// Hotjar Tracking Code for https://wizard.fintoc.com/
function trackingFunc(h, o, t, j, a, r) {
  /* eslint-disable */
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2897746,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
  /* eslint-enable */
}

function start() {
  trackingFunc(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}

function isAPIAvailable() {
  return !!window.hj;
}

function sendEvent(event) {
  window.hj('event', event);
}

function identifySession(id, attributes) {
  window.hj('identify', id, attributes);
}

export default {
  start,
  sendEvent,
  identifySession,
  isAPIAvailable,
};
