import axiosInstance from './axiosInstance';

/*
  Validate public key with fintoc, brings crypto publicKey and mode: test|live
*/
function get({ publicKey }) {
  return axiosInstance.get('/internal/v1/widget_auth',
    {
      headers: {
        Authorization: publicKey,
      },
    });
}

export default {
  get,
};
