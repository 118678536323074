/* eslint-disable import/prefer-default-export */

import { onMounted, onUnmounted } from '@vue/composition-api';

export const useVisibility = () => {
  let documentHiddenKey;
  let visibilityChangeEvent;

  if (typeof document.hidden !== 'undefined') {
    documentHiddenKey = 'hidden';
    visibilityChangeEvent = 'visibilitychange';
  } else if (typeof document.msHidden !== 'undefined') {
    documentHiddenKey = 'msHidden';
    visibilityChangeEvent = 'msvisibilitychange';
  } else if (typeof document.webkitHidden !== 'undefined') {
    documentHiddenKey = 'webkitHidden';
    visibilityChangeEvent = 'webkitvisibilitychange';
  }

  const browserSupportsHiddenAPI = typeof document.hidden !== 'undefined';

  const onHidden = (callback) => {
    const wrappedCallback = () => {
      if (browserSupportsHiddenAPI && document[documentHiddenKey]) {
        callback();
      }
    };

    onMounted(() => document.addEventListener(visibilityChangeEvent, wrappedCallback));
    onUnmounted(() => document.removeEventListener(visibilityChangeEvent, wrappedCallback));
  };

  const onShown = (callback) => {
    const wrappedCallback = () => {
      if (browserSupportsHiddenAPI && !document[documentHiddenKey]) {
        callback();
      }
    };

    onMounted(() => document.addEventListener(visibilityChangeEvent, wrappedCallback));
    onUnmounted(() => document.removeEventListener(visibilityChangeEvent, wrappedCallback));
  };

  return { onHidden, onShown };
};
