<template>
  <div >
    <div class="flex justify-between h-15 min-h-15 items-center rounded-t-lg w-full bg-white p-4 space-x-2">
      <Back
        v-if="headerConfig.back"
        :class="(isWindowWidthSmall ? 'cursor-pointer focus:outline-none h-4' : 'cursor-pointer focus:outline-none')"
        @click.native="goBack"
      />
      <div v-else class="min-w-2.5"/>

      <span id="hTitle" :class="(isWindowWidthSmall ? 'font-S w-full text-center font-700 color-txt-heading' : 'font-M w-full text-center font-700 color-txt-heading')">
        {{ this.title }}
      </span>

      <Close
        v-if="headerConfig.close"
        :class="(isWindowWidthSmall ? 'cursor-pointer focus:outline-none h-4' : 'cursor-pointer focus:outline-none')"
        @click.native="closeWidget"
      />
    </div>
    <ProgressBar v-if="showProgressBar" :currentStep="stateCounter" />
    <div class="border border-solid border-divider-color" v-if="showSeparator" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import constants from '../../constants';
import Close from '../icons/Close.vue';
import Back from '../icons/Back.vue';
import ProgressBar from './ProgressBar.vue';

import AnalyticsEvents from '../../constants/analytics_events';
import Analytics from '../../helpers/analytics';
import optimizelyFlags from '../../helpers/optimizely';

export default {
  props: {
    goBackFunc: Function,
  },
  computed: {
    ...mapGetters(['headerConfig', 'isPayment']),
    ...mapState({
      currentState: (state) => state.widget.currentState,
      stateCounter: (state) => state.widget.stateCounter,
      selectedInstitution: (state) => state.config.selectedInstitution,
      country: (state) => state.config.selectedCountry,
      windowWidth: (state) => state.widget.windowWidth,
      analyticsEventProperties: (state) => Analytics.getEventPropertiesFromRootState(state),
    }),
    isWindowWidthSmall() {
      if (this.windowWidth < 300) {
        return true;
      }
      return false;
    },
    title() {
      if (typeof this.headerConfig.title === 'function') {
        return this.headerConfig.title(this.country);
      }
      return this.headerConfig.title;
    },
    showProgressBar() {
      return this.currentState !== constants.states.INTRODUCTION && this.isPayment && this.isProgressBarVariant;
    },
    showSeparator() {
      if (this.isPayment && this.isProgressBarVariant) {
        return false;
      }
      return !this.headerConfig.hideSeparator;
    },
    isProgressBarVariant() {
      return optimizelyFlags.progressBarVariant;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState']),
    closeWidget() {
      window.analytics.track(
        AnalyticsEvents.EXIT_CLICKED,
        {
          ...this.analyticsEventProperties,
          last_state: this.currentState,
        },
      );
      this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
    },
    goBack() {
      if (this.goBackFunc) { this.goBackFunc(); return; }
      if (this.selectedInstitution && this.currentState === constants.states.LOGIN) {
        this.transitionToNextState({ transition: constants.transitions.GO_HOME });
      } else {
        this.transitionToNextState({ transition: constants.transitions.GO_BACK });
      }
    },
  },
  components: {
    Back,
    Close,
    ProgressBar,
  },
};
</script>
