<template>
  <div class="top-container-mfa-2">
    <div class="font-XS font-400 color-txt-sec-and-cap"> Empresa: </div>
    <div class="font-L font-700 color-txt-heading text-center"> {{ organizationName }} </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      organizationName: (state) => state.config.organizationName,
    }),
  },
};
</script>

<style scoped>

.top-container-mfa-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
}
</style>
