import errors from '../errors';
import { isFrozen } from './frozen-state/utils';

function isFiscalProduct(product) {
  return ['INVOICES', 'TAX_RETURNS', 'INCOME', 'TAX_STATEMENTS'].includes(product);
}

function checkIsValidFiscalConfig(institutionId, product, holderType, institutionsList, country) {
  const invalidConfigError = new errors.InvalidSelectedInstitution(`Invalid selected institution: '${institutionId}' for product '${product}', holderType '${holderType}' and country '${country}'`);
  const config = institutionsList.find((institution) => institution.publicId === institutionId);
  const isValidConfig = config?.products?.[product.toLowerCase()]?.[holderType.toLowerCase()];
  if (!isValidConfig) throw invalidConfigError;
}

function checkIsValidFiscalProduct(product, holderType, country, institutionsList) {
  if (country === 'mx') {
    return checkIsValidFiscalConfig('mx_fiscal_sat', product, holderType, institutionsList, country);
  }
  if (country === 'cl') {
    return checkIsValidFiscalConfig('cl_fiscal_sii', product, holderType, institutionsList, country);
  }
  throw new errors.InvalidSelectedInstitution(`Invalid selected fiscal institution for product '${product}', holderType '${holderType}' and country '${country}'`);
}

function checkIsValidBankProduct(selectedInstitution, institutionsList, product, holderType, country) {
  if (!selectedInstitution) return;
  const validInstitutions = institutionsList.filter((institution) => institution.products[product.toLowerCase()] && institution.products[product.toLowerCase()][holderType.toLowerCase()]);
  const isValidInstitution = validInstitutions.map((institution) => institution.publicId).includes(selectedInstitution);
  if (!isValidInstitution) {
    throw new errors.InvalidSelectedInstitution(`Invalid selected institution: '${selectedInstitution}' for product '${product}', holderType '${holderType}' and country '${country}'`);
  }
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

function valueExists(variable) {
  return variable !== null && variable !== undefined;
}

function validateSelectedInstitution(selectedInstitution, institutionsList, product, holderType, country) {
  if (isFiscalProduct(product)) {
    // For now all fiscal products only have one institution, so we will validate them
    // even if the institution is not selected.
    return checkIsValidFiscalProduct(product, holderType, country, institutionsList);
  }
  return checkIsValidBankProduct(selectedInstitution, institutionsList, product, holderType, country);
}

function getResourceId(widgetToken) {
  if (!widgetToken) return null;
  return widgetToken.split('_sec_')[0];
}

function widgetParentOrigin() {
  const urlParams = new URLSearchParams(window.location.search);
  const origin = urlParams.get('parent');
  const parentIsFile = origin === 'file://';
  return parentIsFile ? '*' : origin;
}

/**
 * @param {() => void} callback
 * @param {number} [ms]
 * @param {() => void} [frozenCallback]
 * @returns {NodeJS.Timeout}
 */
function setFrozenFriendlyTimeout(callback, ms, frozenCallback = () => null) {
  const newCallback = () => {
    if (isFrozen()) {
      return frozenCallback();
    }
    return callback();
  };

  return setTimeout(newCallback, ms);
}

export default {
  sleep,
  valueExists,
  validateSelectedInstitution,
  getResourceId,
  widgetParentOrigin,
  setFrozenFriendlyTimeout,
};
