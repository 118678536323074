<template>
  <widget-layout includeScrollArrow>
    <div class="outer-container">
      <div v-bind:class="[(anonimizedEntities || isLessThanFiveBanks) ? 'anonimized-banks-container' : '', 'banks-container']">
        <div v-bind:class="(anonimizedEntities || isLessThanFiveBanks) ? 'bank-list w-full' : 'bank-grid'">
            <bank-button
              data-cy="bank-button"
              :class="(anonimizedEntities || isLessThanFiveBanks) ? 'bank-btn-h-big' : 'bank-btn-h-small'"
              v-for="bank in banksList"
              v-bind:key="bank.publicId"
              :bankId="bank.publicId"
              @click.native="transition()" />
        </div>
      </div>
    </div>
    <template #footer>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import constants from '../../../constants';
import BankButton from './EntityButton.vue';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import Footer from '../../layout/Footer.vue';
import PaymentsMixin from '../../mixins/payments';
import products from '../../../../sharedTypes/products';

export default {
  mixins: [PaymentsMixin],
  computed: {
    ...mapState({
      product: (state) => state.config.product.toLowerCase(),
      holderType: (state) => state.config.holderType.toLowerCase(),
      banks: (state) => state.config.banks,
      preSelectedBank: (state) => state.config.selectedInstitution,
      anonimizedEntities: (state) => state.config.anonimizedEntities,
    }),
    banksList() {
      if (this.anonimizedEntities) {
        return this.banks.filter((bank) => ['cl_banco_santander', 'cl_banco_de_chile', 'cl_banco_estado', 'cl_banco_scotiabank', 'cl_banco_itau'].includes(bank.publicId));
      }

      if (this.product === products.SUBSCRIPTION_INTENTS.toLowerCase()) {
        const subscriptionProduct = products.SUBSCRIPTION.toLowerCase();
        return this.banks
          .filter((bank) => bank.products[subscriptionProduct] && bank.products[subscriptionProduct][this.holderType]);
      }

      return this.banks
        .filter((bank) => bank.products[this.product] && bank.products[this.product][this.holderType]);
    },
    bankGroups() {
      const groups = [];
      let group = [];
      for (let index = 0; index < this.banks.length; index += 1) {
        if (index > 0 && index % 2 === 0) {
          groups.push(group);
          group = [];
        }
        group.push(this.banks[index]);
      }
      if (group.length > 0) { groups.push(group); }
      return groups;
    },
    amountOfRows() {
      return Math.round(this.banks.length / 2);
    },
    isLessThanFiveBanks() {
      return this.banksList.length <= 5;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'updateBankSelected', 'setProductBankConfig', 'resetBankSelected']),
    async transition() {
      if (['payments'].includes(this.product)) {
        await this.setProductBankConfig();
        if (!this.paymentDataIsValidForBank()) {
          return this.transitionToNextState({ transition: constants.transitions.PAYMENT_DATA_VALIDATION_FOR_SELECTED_BANK_FAILED });
        }
      }
      return this.transitionToNextState({ transition: constants.transitions.SELECT_BANK });
    },
  },
  created() {
    if (this.banks.find((bank) => bank.publicId === this.preSelectedBank)) {
      this.updateBankSelected({ bankId: this.preSelectedBank });
      if (['payments'].includes(this.product)) {
        if (!this.paymentDataIsValidForBank()) {
          return this.transitionToNextState({ transition: constants.transitions.PAYMENT_DATA_VALIDATION_FOR_SELECTED_BANK_FAILED });
        }
      }
      return this.transitionToNextState({ transition: constants.transitions.SELECT_BANK });
    }

    return this.resetBankSelected();
  },
  components: {
    BankButton,
    WidgetLayout,
    Footer,
  },
};
</script>

<style scoped>
.banks-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px 20px 20px;

  width: 100vw;
  max-width: 360px;
}

.anonimized-banks-container {
  padding: 0 0 0 0;
  align-items: center;
}

.bank-grid {
  display: grid;
  grid-template: repeat(2, 1fr) / repeat(2, 1fr);
  grid-gap: 16px;
}

.bank-list {
  display: grid;
  gap: 10px;
  padding: 20px;
}

.bank-btn-h-small {
  height: 76px;
}

.bank-btn-h-big {
  height: 86px;
}

</style>
