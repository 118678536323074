<template>
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col items-center w-full py-0 px-4 text-center h-full" :class="{'justify-center': !(shouldShowPaymentInfo || isCheckAccountBeforeRetry)}">
        <PaymentInfo v-if="shouldShowPaymentInfo" class="mt-1"/>
        <EntityLogo v-if="isCheckAccountBeforeRetry"/>

        <Cross v-if="isRecipientAccountIssue || isTryAgainLaterError" class="min-w-14 min-h-14" backgroundColor="#FADDE1" circleColor="#DD1C36"/>
        <Clock v-else-if="isTimeoutError" class="min-w-14 min-h-14" backgroundColor="#FFF2E7" clockColor="#EA780E" />
        <Error v-else :class="{ 'mt-4' : shouldShowPaymentInfo }" class="min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />

        <div id="peHeaderText" class="font-S font-700 color-txt-heading mt-4"> {{ errorTitle }} </div>
        <div id="peSubText" class="font-S font-400 color-txt-body mt-4 mx-4"> {{ errorDescription }} </div>

        <div id="peAccounts" v-if="isErrorInsufficientBalance" class="flex flex-col items-center w-90 min-h-36 mt-2.5">
          <div
            v-for="account in sortedAccounts"
            :key='account.id'
            >
            <AccountInfo :containerClass="accountClass(account)" :account="account"/>
          </div>
        </div>

        <AccountInfo v-if="(isErrorNewContactLimitMax || isErrorDailyLimitMax)" :account="paymentAccount" containerClass='account-cell cell-not-selected mt-5'/>
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
      <ActionButton
        text="Reintentar"
        @click.native="transition()"
      />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import constants from '../../constants/index';
import WidgetLayout from '../layout/WidgetLayout.vue';
import PaymentInfo from './PaymentInfo.vue';
import Error from '../icons/Error.vue';
import Cross from '../icons/Cross.vue';
import Clock from '../icons/Clock.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import EntityLogo from '../layout/EntityLogo.vue';
import AccountInfo from '../widget-views/AccountInfo.vue';
import PaymentsMixin from '../mixins/payments';
import AccountMixin from '../mixins/account';
import {
  buildErrorMessage,
  isErrorInsufficientBalance,
  isErrorNewContactLimitMax,
  isErrorDailyLimitMax,
  isRecipientAccountIssue,
  isTryAgainLaterError,
  isCheckAccountBeforeRetry,
  isTimeoutError,
} from '../../utils/payment/PaymentError.constants';

export default {
  mixins: [PaymentsMixin, AccountMixin],
  methods: {
    ...mapActions(['transitionToNextState']),
    transition() {
      this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
    },
    accountClass(account) {
      const cellClass = this.canShowCreditLine(account)
        ? 'account-cell-with-credit-line'
        : 'account-cell';
      const selectedClass = this.isSufficientBalance(account, this.calculateCommission)
        ? 'cell-not-selected'
        : 'cell-disabled';
      return `${cellClass} ${selectedClass}`;
    },
  },
  computed: {
    ...mapGetters(['isSufficientBalance', 'calculateCommission', 'bankTextEntitySelected']),
    ...mapState({
      errorType: (state) => state.widget.errorType,
      errorReason: (state) => state.payments.errorReason,
      accounts: (state) => state.link.accounts,
      entityId: (state) => state.widget.entityIdSelected,
      accountNumber: (state) => state.payments.accountNumber,
      accountBalance: (state) => state.payments.accountBalance,
      accountName: (state) => state.payments.accountName,
      accountOfficialName: (state) => state.payments.accountOfficialName,
      organizationName: (state) => state.config.organizationName,
    }),
    paymentAccount() {
      const account = {
        number: this.accountNumber,
        balance: this.accountBalance,
        name: this.accountName,
      };
      return account;
    },
    errorMessageData() {
      return {
        errorType: this.errorType,
        errorReason: this.errorReason,
        bankTextEntitySelected: this.bankTextEntitySelected,
        organizationName: this.organizationName,
        entityId: this.entityId,
        accountOfficialName: this.accountOfficialName,
      };
    },
    errorTitle() {
      return buildErrorMessage(this.errorMessageData).title;
    },
    errorDescription() {
      return buildErrorMessage(this.errorMessageData).description;
    },
    sortedAccounts() {
      return this.getPaymentSortedAndFilteredAllowedAccounts(this.accounts);
    },
    isErrorInsufficientBalance() {
      return isErrorInsufficientBalance(this.errorReason);
    },
    isErrorNewContactLimitMax() {
      return isErrorNewContactLimitMax(this.errorReason);
    },
    isErrorDailyLimitMax() {
      return isErrorDailyLimitMax(this.errorReason);
    },
    isRecipientAccountIssue() {
      return isRecipientAccountIssue(this.errorReason);
    },
    isTryAgainLaterError() {
      return isTryAgainLaterError(this.errorReason);
    },
    isCheckAccountBeforeRetry() {
      return isCheckAccountBeforeRetry(this.errorReason);
    },
    isTimeoutError() {
      return isTimeoutError(this.errorReason);
    },
    shouldShowPaymentInfo() {
      return this.isErrorInsufficientBalance || this.isErrorNewContactLimitMax || this.isErrorDailyLimitMax;
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Error,
    PaymentInfo,
    AccountInfo,
    Cross,
    Clock,
    EntityLogo,
  },
};
</script>
