<template>
  <div class="flex flex-col">
    <div
      class="flex flex-row w-full space-x-1 h-1"
    >
      <div class="w-1/3 bg-divider-color h-1">
        <div
          class="h-1"
          :class="progress1Classes"
          :style="`max-width: ${progress1}`"
        />
      </div>
      <div class="w-1/3 bg-divider-color h-1">
        <div
          class="h-1"
          :class="progress2Classes"
          :style="simulateCheckContact ? `max-width: ${simulatedProgress2}` : `max-width: ${progress2}`"
        />
      </div>
      <div class="w-1/3 bg-divider-color h-1">
        <div
          class="h-1"
          :class="progress3Classes"
          :style="`max-width: ${progress3}`"
        />
      </div>
    </div>
    <div
      v-if="showProgressDescription"
      class="flex flex-row w-full space-x-4 px-2.5 py-2"
    >
      <div class="flex flex-col w-full space-y-1">
        <div class="text-secondary-color text-xs font-medium"> Tu cuenta </div>
        <div
          class="text-sm font-bold"
          :class="(isProgress1Completed || simulateCheckContact) ? 'text-disabled-color' : 'text-heading-color'"
        > {{ bankText }} </div>
        <div
          class="flex items-start space-x-1 font-bold text-xxs"
          :class="(accountNumber && !isProgress1Completed && !simulateCheckContact) ? 'text-heading-color' : 'text-disabled-color'"
        >
          ●●●●
          <span :class="{ 'text-xs ml-px -mt-px' : accountNumber }" > {{ hiddenAccountNumber }} </span>
        </div>
      </div>
      <div class="flex flex-col w-full space-y-1">
        <div class="text-secondary-color text-xs font-medium"> Destinatario </div>
        <div
          class="text-sm font-bold"
          :class="isInContactStep ? 'text-heading-color' : 'text-disabled-color'"
        > {{ truncateRecipientHolderName(recipientHolderName) }} </div>
      </div>
      <div class="flex flex-col w-full space-y-1">
        <div class="text-secondary-color text-xs font-medium"> Monto a pagar </div>
        <div
          class="text-sm font-bold"
          :class="isPaymentStepSeen ? 'text-heading-color' : 'text-disabled-color'"
        > {{ parsedAmountWithComission }} </div>
      </div>
    </div>
    <InfoOneLine
      v-if="isBancoEstadoComission && showProgressDescription"
      class="m-2"
      :text="'Banco Estado te cobrará una comisión de $' + calculateCommission"
    />
    <div class="bg-divider-color h-1.5px w-full" v-if="showProgressDescription" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import states from '../../constants/states';
import utils from '../../helpers/utils';
import InfoOneLine from '../views.common/InfoOneLine.vue';

const { sleep } = utils;

const MAX_RECIPIENT_HOLDER_NAME_LENGTH = 16;

export default {
  props: {
    currentStep: Number,
  },
  data() {
    return {
      totalFirstPaymentSteps: 5,
      maxTotalSecondPaymentSteps: 8,
      maxTotalThirdPaymentSteps: 9,
      simulatedProgress2: '0%',
    };
  },
  computed: {
    ...mapGetters([
      'parsedAmountWithComission',
      'bankTextEntitySelected',
      'isPrimaryMainFintoc',
      'isBancoEstadoComission',
      'calculateCommission',
    ]),
    ...mapState({
      currentState: (state) => state.widget.currentState,
      isContactStepSeen: (state) => state.widget.isContactStepSeen,
      isPaymentStepSeen: (state) => state.widget.isPaymentStepSeen,
      paymentStatus: (state) => state.payments.status,
      totalStepsBeforePayment: (state) => state.widget.totalStepsBeforePaymentSteps,
      recipientHolderName: (state) => state.payments.recipientHolderName,
      accountNumber: (state) => state.payments.accountNumber,
      entityId: (state) => state.widget.entityIdSelected,
      simulateCheckContact: (state) => state.payments.simulateCheckContact,
      linkStatus: (state) => state.link.status,
    }),
    isProgress1Completed() {
      return this.currentStep > this.totalFirstPaymentSteps;
    },
    progress1() {
      if (this.isProgress1Completed) {
        return '100%';
      }
      const lastProgressWidth = document.documentElement.style.getPropertyValue('--new-progress-1') || '0%';
      const newProgressWidth = `${(this.currentStep / this.totalFirstPaymentSteps) * 100}%`;
      document.documentElement.style.setProperty('--last-progress-1', lastProgressWidth);
      document.documentElement.style.setProperty('--new-progress-1', newProgressWidth);
      return newProgressWidth;
    },
    progress1Classes() {
      let classes = '';
      if (!this.isProgress1Completed) {
        classes += 'rounded-r-sm progress1';
      }
      if (this.isPaymentSucceded) {
        classes += ' bg-success-main';
      } else if ((this.isPaymentFailed || this.isLinkRejected) && this.isPrimaryMainFintoc) {
        classes += ' bg-warning-main';
      } else {
        classes += ' bg-primary-main';
      }
      return classes;
    },
    progress2() {
      if (this.isPaymentStepSeen) {
        return '100%';
      }
      if (this.isContactStepSeen) {
        const currentSecondStep = this.currentStep - this.totalFirstPaymentSteps;
        const lastProgressWidth = document.documentElement.style.getPropertyValue('--new-progress-2') || '0%';
        const newProgressWidth = `${(currentSecondStep / this.maxTotalSecondPaymentSteps) * 100}%`;
        document.documentElement.style.setProperty('--last-progress-2', lastProgressWidth);
        document.documentElement.style.setProperty('--new-progress-2', newProgressWidth);
        return newProgressWidth;
      }
      return '0%';
    },
    progress2Classes() {
      let classes = '';
      if (this.simulateCheckContact) {
        classes += 'rounded-r-sm progress2Simulation';
      } else if (this.isContactStepSeen && !this.isPaymentStepSeen) {
        classes += 'rounded-r-sm progress2';
      }
      if (this.isPaymentSucceded) {
        classes += ' bg-success-main';
      } else if (this.isPaymentFailed && this.isPrimaryMainFintoc) {
        classes += ' bg-warning-main';
      } else {
        classes += ' bg-primary-main';
      }
      return classes;
    },
    progress3() {
      if (this.isPaymentStepSeen) {
        const currentThirdStep = this.currentStep - this.totalStepsBeforePayment + 1;

        const lastProgressWidth = document.documentElement.style.getPropertyValue('--new-progress-3') || '0%';
        const newProgressWidth = `${(currentThirdStep / this.maxTotalThirdPaymentSteps) * 100}%`;
        document.documentElement.style.setProperty('--last-progress-3', lastProgressWidth);

        if (this.isPaymentSucceded) {
          document.documentElement.style.setProperty('--new-progress-3', '100%');
          return '100%';
        }
        document.documentElement.style.setProperty('--new-progress-3', newProgressWidth);
        return newProgressWidth;
      }
      return '0%';
    },
    progress3Classes() {
      let classes = '';
      if (this.isPaymentSucceded) {
        classes += ' bg-success-main progress3';
      } else if (this.isPaymentFailed && this.isPrimaryMainFintoc) {
        classes += ' bg-warning-main progress3';
      } else {
        classes += ' bg-primary-main rounded-r-sm';
      }
      if (this.isProgress1Completed && this.isPaymentStepSeen) {
        classes += ' progress3';
      }
      return classes;
    },
    isPaymentSucceded() {
      return this.paymentStatus === 'succeeded';
    },
    isPaymentFailed() {
      return this.paymentStatus === 'failed';
    },
    isLinkRejected() {
      return this.linkStatus === 'rejected';
    },
    showProgressDescription() {
      const showProgressStates = [
        states.LOADING_LINK_INTENT,
        states.PAYMENTS_CONFIRMATION_REQUIRED,
        states.PROCESSING_PAYMENTS,
        states.PAYMENTS_PROCESSING_ACTION,
      ];
      const isPaymentError = this.currentState === states.PAYMENTS_ERROR;
      return showProgressStates.includes(this.currentState) && !isPaymentError;
    },
    hiddenAccountNumber() {
      return this.accountNumber ? this.accountNumber.slice(-4) : '●●●●';
    },
    bankText() {
      if (this.entityId === 'cl_banco_estado' || this.entityId === 'cl_banco_de_chile') {
        return this.bankTextEntitySelected;
      }
      return this.bankTextEntitySelected.slice(6);
    },
    isInContactStep() {
      return (this.isContactStepSeen && !this.isPaymentStepSeen) || this.simulateCheckContact;
    },
  },
  methods: {
    truncateRecipientHolderName(recipientHolderName) {
      if (this.recipientHolderName.match(/cruzada spa/i)) {
        return 'Paga en línea';
      }
      if (recipientHolderName.length > MAX_RECIPIENT_HOLDER_NAME_LENGTH) {
        return `${recipientHolderName.slice(0, MAX_RECIPIENT_HOLDER_NAME_LENGTH)}...`;
      }
      return recipientHolderName;
    },
  },
  watch: {
    async simulateCheckContact(value) {
      if (value === true) {
        document.documentElement.style.setProperty('--last-progress-2', '0%');
        document.documentElement.style.setProperty('--new-progress-2', '100%');
        this.simulatedProgress2 = '100%';
        await sleep(5000);
      }
    },
  },
  components: {
    InfoOneLine,
  },
};
</script>

<style>
.progress1 {
  animation: progress-1-animation 1s;
}

@keyframes progress-1-animation {
  from {
    width: var(--last-progress-1)
  }
  to {
    width: var(--new-progress-1)
  }
}

.progress2Simulation {
  animation: progress-2-animation 2.8s ease-out;
}
.progress2 {
  animation: progress-2-animation 1s;
}

@keyframes progress-2-animation {
  from {
    width: var(--last-progress-2)
  }
  to {
    width: var(--new-progress-2)
  }
}

.progress3 {
  animation: progress-3-animation 1s;
}

@keyframes progress-3-animation {
  from {
    width: var(--last-progress-3)
  }
  to {
    width: var(--new-progress-3)
  }
}
</style>
