import Vue from 'vue';
import VueRouter from 'vue-router';

import Container from '../views/Container.vue';
import PageNotFound from '../views/PageNotFound.vue';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    { path: '/', component: Container },
    { path: '*', component: PageNotFound },
  ],
});

export default router;
