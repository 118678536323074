const BANKS = {
  cl_banco_bci: 'Banco BCI',
  cl_banco_bci_360: 'Banco BCI 360',
  cl_banco_bice: 'Banco BICE',
  cl_banco_de_chile: 'Banco de Chile',
  cl_banco_estado: 'Banco Estado',
  cl_banco_falabella: 'Banco Falabella',
  cl_banco_itau: 'Banco Itaú',
  cl_banco_ripley: 'Banco Ripley',
  cl_banco_santander: 'Banco Santander',
  cl_banco_santander_pyme: 'Banco Santander Pyme',
  cl_banco_scotiabank: 'Banco Scotiabank',
  cl_banco_security: 'Banco Security',
  mx_banco_banamex: 'Banco Banamex',
  mx_banco_bbva: 'Banco BBVA',
  mx_hey_banco: 'Hey Banco',
  mx_banco_azteca: 'Banco Azteca',
  mx_banco_santander: 'Banco Santander',
  mx_banco_hsbc: 'Banco HSBC',
  mx_banco_banorte: 'Banco Banorte',
};

const BANK_CONFIG = {
  app: { timeout: 30, delay: 20 },
  sms: { timeout: 30, delay: 0, authLength: 4 },
  device: { timeout: 30, delay: 0, authLength: 6 },
  coordinates: { timeout: 30, delay: 0 },
  email: { timeout: 30, delay: 0, authLength: 6 },
  commission: 300,
  paymentMinimumAmount: 1000,
};

/**
 * @returns {Object.<string, BANK_CONFIG>}
 */
export const getBanksConfig = () => Object.entries(BANKS).reduce((banks, [bank]) => ({ ...banks, [bank]: BANK_CONFIG }), {});

/**
 *
 * @param {'cl' | 'mx'} [countryCode = 'cl']
 * @returns {Object.<string, BANK_CONFIG>}
 */
export const getAvailableProducts = (countryCode = 'cl') => Object.entries(BANKS).map(([bankId, bank]) => ({
  publicId: bankId,
  name: bank,
  products: {
    payments: { individual: true, business: false },
    movements: { individual: true, business: true },
    charges: { individual: true, business: false },
    subscription: { individual: true, business: true },
  },
})).filter(({ publicId }) => publicId.startsWith(countryCode));
