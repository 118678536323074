export default {
  data() {
    return {
      polling: null,
    };
  },
  methods: {
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    startPolling(func, pollingRate) {
      this.polling = setInterval(() => {
        func();
      },
      pollingRate);
    },
  },
};
