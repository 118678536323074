/* eslint-disable import/prefer-default-export */

import api from '../../api';

export const getFiscalAuthoritiesConfig = async () => {
  // This config needs to be decoupled from the backend.
  // For now the fiscal authorities are hardcoded
  // in the frontend (specifically in the API
  // module) so they won't be duplicated,
  // but once they get moved to the backend they
  // need to be hardcoded in this file instead of
  // using the API module.
  const response = await api.config.getFiscalAuthorities();
  return response.data;
};
