<template>
<svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect :class="isSuccess ? 'text-success-surface' : 'text-primary-surface'" width="56" height="56" rx="28" fill="currentColor"/>
<g clip-path="url(#clip0_1061_33370)">
<path :class="isSuccess ? 'text-success-main' : 'text-primary-main'" d="M8 28C8 16.9531 16.9531 8 28 8C39.0469 8 48 16.9531 48 28C48 39.0469 39.0469 48 28 48C16.9531 48 8 39.0469 8 28ZM37.0469 24.5469C37.8984 23.6953 37.8984 22.3047 37.0469 21.4531C36.1953 20.6016 34.8047 20.6016 33.9531 21.4531L25.5 29.9062L22.0469 26.4531C21.1953 25.6016 19.8047 25.6016 18.9531 26.4531C18.1016 27.3047 18.1016 28.6953 18.9531 29.5469L23.9531 34.5469C24.8047 35.3984 26.1953 35.3984 27.0469 34.5469L37.0469 24.5469Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_1061_33370">
<rect width="40" height="40" fill="white" transform="translate(8 8)"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  props: {
    isSuccess: {
      default: true,
      type: Boolean,
    },
  },
};
</script>
