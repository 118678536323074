import constants from '../constants';
import StateMachine from '../helpers/state-machine';

const { states, transitions } = constants;

export default function createSubscriptionsStateMachine() {
  return new StateMachine({
    id: 'subscription-state-machine',
    initial: states.INTRODUCTION,
    states: {
      [states.INTRODUCTION]: {
        on: {
          [transitions.OPEN_BANKS]: states.BANK_SELECTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Conéctate a tu banco',
          close: true,
        },
        body: {
          institution: 'tu banco',
          privacyText: 'Tus credenciales no serán compartidas con nadie.',
        },
      },
      [states.BANK_SELECTION]: {
        on: {
          [transitions.SELECT_BANK]: states.LOGIN,
          [transitions.GO_BACK]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: true,
          title: 'Selecciona tu banco',
          close: true,
        },
      },
      [states.LOGIN]: {
        on: {
          [transitions.LINK_INTENT_CREATE_SUCCEEDED]: states.LOADING_LINK_INTENT,
          [transitions.LINK_INTENT_CREATE_FAILED]: states.ERROR_VIEW,
          [transitions.GO_BACK]: states.BANK_SELECTION,
          [transitions.GO_HOME]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.SUBSCRIPTIONS_NO_RETRY_ERROR]: states.SUBSCRIPTION_ERROR,
        },
        header: {
          back: true,
          title: 'Ingresa tus credenciales',
          close: true,
        },
      },
      [states.LOADING_LINK_INTENT]: {
        on: {
          [transitions.LINK_INTENT_SUCCESSFUL]: states.SUBSCRIPTION_CONFIRMATION_REQUIRED,
          [transitions.LINK_INTENT_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.SUBSCRIPTIONS_NO_RETRY_ERROR]: states.SUBSCRIPTION_ERROR,
        },
        header: {
          back: false,
          title: 'Conectándonos',
          close: true,
        },
      },
      [states.SUBSCRIPTION_CONFIRMATION_REQUIRED]: {
        on: {
          [transitions.SUBSCRIPTION_CONFIRMED_SUCCEEDED]: states.LOADING_SUBSCRIPTION,
          [transitions.SUBSCRIPTION_CONFIRMED_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Confirma tu suscripción',
          close: true,
        },
      },
      [states.LOADING_SUBSCRIPTION]: {
        on: {
          [transitions.LOADING_SUBSCRIPTION_SUCCEEDED]: states.SUBSCRIPTION_CREATED,
          [transitions.LOADING_SUBSCRIPTION_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.LOADING_SUBSCRIPTION_ABORTED]: states.SUBSCRIPTION_ABORTED,
          [transitions.SUBSCRIPTION_APP_AUTH_REQUIRED]: states.APP_AUTH_REQUIRED,
          [transitions.SUBSCRIPTION_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.SUBSCRIPTION_CARD_AUTH_REQUIRED]: states.CARD_AUTH_REQUIRED,
          [transitions.SUBSCRIPTION_SMS_AUTH_REQUIRED]: states.SMS_AUTH_REQUIRED,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Validando con el banco',
          close: true,
        },
      },
      [states.APP_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_SUCCEEDED]: states.SUBSCRIPTION_CREATED,
          [transitions.WAITING_ACTION_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.DEVICE_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.SUBSCRIPTION_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.CARD_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.SUBSCRIPTION_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.SMS_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.SUBSCRIPTION_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.SUBSCRIPTION_PROCESSING_ACTION]: {
        on: {
          [transitions.PROCESSING_ACTION_SUCCEEDED]: states.SUBSCRIPTION_CREATED,
          [transitions.PROCESSING_ACTION_FAILED]: states.SUBSCRIPTION_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Procesando segundo factor',
          close: true,
        },
      },
      [states.SUBSCRIPTION_CREATED]: {
        type: 'final',
        header: {
          back: false,
          title: 'Suscripción exitosa',
          close: false,
        },
      },
      [states.ERROR_VIEW]: {
        on: {
          [transitions.RETRY_LINK_INTENT]: states.LOGIN,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Tuvimos un problema',
          close: true,
        },
      },
      [states.SUBSCRIPTION_ERROR]: {
        on: {
          [transitions.RETRY_LINK_INTENT]: states.LOGIN,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'No se pudo suscribir el PAC',
          close: true,
        },
      },
      [states.SUBSCRIPTION_ABORTED]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'No se pudo suscribir el PAC',
          close: true,
        },
      },
      [states.WIDGET_CLOSED]: {
        type: 'final',
      },
    },
  });
}
