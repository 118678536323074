import axiosInstance from './axiosInstance';

function create({
  accountId, customerId, amountLimit, linkToken, callbackUrl, publicKey,
}) {
  return axiosInstance
    .post(`/internal/v1/accounts/${accountId}/subscriptions`, {
      customerId,
      amountLimit,
      linkToken,
      callbackUrl,
    }, {
      headers: { Authorization: publicKey },
    });
}

function update({
  subscriptionId, secondFactor, linkToken, publicKey,
}) {
  return axiosInstance
    .patch(`/internal/v1/subscriptions/${subscriptionId}`, {
      linkToken, secondFactor,
    }, {
      headers: { Authorization: publicKey },
    });
}

function get({ subscriptionId, linkToken, publicKey }) {
  return axiosInstance
    .get(`/internal/v1/subscriptions/${subscriptionId}?link_token=${linkToken}`, {
      headers: { Authorization: publicKey },
    });
}

export default {
  create,
  update,
  get,
};
