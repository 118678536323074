<template>
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col h-full items-center justify-center space-y-8 px-4">
        <EntityLogo />
        <Error class="min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />
        <div id="seTitleText" class="font-M font-700 color-txt-heading text-center">
          {{ errorTitle }}
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Reintentar"
          @click.native="transition()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions } from 'vuex';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import Error from '../icons/Error.vue';
import EntityLogo from '../layout/EntityLogo.vue';

export default {
  methods: {
    ...mapActions(['transitionToNextState']),
    transition() {
      this.transitionToNextState({ transition: constants.transitions.RETRY_LINK_INTENT });
    },
  },
  computed: {
    errorTitle() {
      return 'Tuvimos un problema conectándonos';
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Error,
    EntityLogo,
  },
};
</script>
