import { cancelAllPostAndPatchRequest, blockAllIdempotencyKeyNextRequest } from '../api/axiosInstance';

const actions = {
  cancelAllAxiosPostAndPatchRequest() {
    cancelAllPostAndPatchRequest();
  },
  blockAllAxiosIdempotencyKeyNextRequest() {
    blockAllIdempotencyKeyNextRequest();
  },
};

export default {
  actions,
};
