import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTimes, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { rutInputDirective, rutFilter } from 'vue-dni';
import VueClipboard from 'vue-clipboard2';
import Vuelidate from 'vuelidate';
import * as Sentry from '@sentry/vue';

import Embed from './Embed.vue';
import router from './router';
import store from './store';
import '@/assets/css/tailwind.css';
import { isFrozen } from './helpers/frozen-state/utils';
import { setupFrozenState } from './config/frozen.setup';
import setupDevelopment from './config/dev.setup';
import setListeners from './widget_api/listeners';

library.add(faTimes, faArrowLeft);

Vue.use(VueCompositionAPI);
Vue.directive('rut', rutInputDirective);
Vue.filter('rutFilter', rutFilter);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;
Vue.use(VueClipboard);
Vue.use(Vuelidate);

Sentry.init({
  Vue,
  dsn: process.env.VUE_APP_SENTRY_DSN,
  environment: process.env.VUE_APP_ENVIRONMENT,
  release: process.env.VUE_APP_COMMIT_HASH,
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  store,
  render: (h) => h(Embed),
}).$mount('#app');

if (window.Cypress) {
  window.store = store;
}

if (isFrozen()) {
  setupFrozenState(store);
} else if (process.env.VUE_APP_LOCAL_DEV) {
  setupDevelopment(store);
} else {
  setListeners();
}
