import holderTypes from '../../sharedTypes/holderTypes';
import products from '../../sharedTypes/products';
import keys from './dev.keys';

const {
  WEBHOOK_URL,
  PUBLIC_KEY_TEST_DEV,
  PUBLIC_KEY_LIVE_DEV,
  PAYMENTS_WIDGET_TOKEN,
  PUBLIC_KEY_TEST_STG,
  PUBLIC_KEY_LIVE_STG,
  REFRESH_INTENT_TOKEN,
} = keys;

const BUSINESS_DEV_TEST = false;
const INDIVIDUAL_DEV_TEST = false;
const INDIVIDUAL_DEV_LIVE = false;
const SUBSCRIPTION_DEV_TEST = false;
const SUBSCRIPTION_DEV_LIVE = false;
const SUBSCRIPTION_STG_TEST = false;
const SUBSCRIPTION_STG_LIVE = false;
const INVOICES_DEV_TEST = false;
const INVOICES_DEV_LIVE = false;
const INVOICES_STG_LIVE = false;
const INDIVIDUAL_STG_LIVE = false;
const TAX_RETURNS_DEV_LIVE = false;
const INCOME_DEV_TEST = false;
const INCOME_DEV_LIVE = false;
const PAYMENTS_DEV_TEST = false;
const PAYMENTS_STG_TEST = false;
const REFRESH_DEV_TEST = false;
const REFRESH_DEV_LIVE = false;

function setupDevelopment(store) {
  if (process.env.NODE_ENV !== 'development') { return; }

  if (PAYMENTS_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      publicKey: PUBLIC_KEY_TEST_DEV,
      widgetToken: PAYMENTS_WIDGET_TOKEN,
      webhookUrl: WEBHOOK_URL,
      username: '416148503',
      product: products.PAYMENTS,
    });
    store.dispatch('widgetOpened');
  }

  if (PAYMENTS_STG_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      publicKey: PUBLIC_KEY_TEST_STG,
      widgetToken: PAYMENTS_WIDGET_TOKEN,
      webhookUrl: WEBHOOK_URL,
      username: '416148503',
      product: products.PAYMENTS,
    });
    store.dispatch('widgetOpened');
  }

  if (REFRESH_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      publicKey: PUBLIC_KEY_TEST_DEV,
      widgetToken: REFRESH_INTENT_TOKEN,
      webhookUrl: WEBHOOK_URL,
      preselectedUsername: '416148503',
      product: products.REFRESH_INTENT,
    });
    store.dispatch('widgetOpened');
  }

  if (REFRESH_DEV_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      publicKey: PUBLIC_KEY_LIVE_DEV,
      widgetToken: REFRESH_INTENT_TOKEN,
      webhookUrl: WEBHOOK_URL,
      product: products.REFRESH_INTENT,
    });
    store.dispatch('widgetOpened');
  }

  if (BUSINESS_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.BUSINESS,
      product: products.MOVEMENTS,
      publicKey: PUBLIC_KEY_TEST_DEV,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (INDIVIDUAL_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.MOVEMENTS,
      publicKey: PUBLIC_KEY_TEST_DEV,
      webhookUrl: WEBHOOK_URL,
      // username: '41614850-3',
      // selectedInstitution: 'cl_banco_santander',
      // onEvent: (event) => { console.log(event); },
    });
    store.dispatch('widgetOpened');
  }

  if (INDIVIDUAL_DEV_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.MOVEMENTS,
      publicKey: PUBLIC_KEY_LIVE_DEV,
      webhookUrl: WEBHOOK_URL,
      // username: '4152313691611250',
      // selectedInstitution: 'mx_banco_bbva',
      // country: 'mx',
      onEvent: (event) => { console.log(event); },
    });
    store.dispatch('widgetOpened');
  }

  if (INDIVIDUAL_STG_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.MOVEMENTS,
      publicKey: PUBLIC_KEY_TEST_STG,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (SUBSCRIPTION_DEV_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.SUBSCRIPTION,
      publicKey: PUBLIC_KEY_LIVE_DEV,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (SUBSCRIPTION_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.SUBSCRIPTION,
      publicKey: PUBLIC_KEY_TEST_DEV,
      webhookUrl: WEBHOOK_URL,
      selectedInstitution: 'cl_banco_de_chile',
      username: '416148503',
      productConfig: {
        subscription: {
          amountLimit: null,
          customerId: '1111222223333',
        },
      },
    });
    store.dispatch('widgetOpened');
  }

  if (SUBSCRIPTION_STG_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.SUBSCRIPTION,
      publicKey: PUBLIC_KEY_LIVE_STG,
      webhookUrl: WEBHOOK_URL,
      username: '120887963',
      productConfig: {
        subscription: {
          amountLimit: 1_000_000,
        },
      },
    });
    store.dispatch('widgetOpened');
  }

  if (SUBSCRIPTION_STG_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.SUBSCRIPTION,
      publicKey: PUBLIC_KEY_TEST_STG,
      webhookUrl: WEBHOOK_URL,
      mode: 'test',
      username: '193225438',
      productConfig: {
        subscription: {
          amountLimit: 1_000_000,
        },
      },
    });
    store.dispatch('widgetOpened');
  }

  if (INVOICES_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.BUSINESS,
      product: products.INVOICES,
      publicKey: PUBLIC_KEY_TEST_DEV,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (INVOICES_DEV_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.BUSINESS,
      product: products.INVOICES,
      publicKey: PUBLIC_KEY_LIVE_DEV,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (INVOICES_STG_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.BUSINESS,
      product: products.INVOICES,
      publicKey: PUBLIC_KEY_LIVE_STG,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (TAX_RETURNS_DEV_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.TAX_RETURNS,
      publicKey: PUBLIC_KEY_LIVE_DEV,
      webhookUrl: WEBHOOK_URL,
    });
    store.dispatch('widgetOpened');
  }

  if (INCOME_DEV_TEST) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.INCOME,
      publicKey: PUBLIC_KEY_TEST_DEV,
      webhookUrl: WEBHOOK_URL,
      mode: 'test',
    });
    store.dispatch('widgetOpened');
  }

  if (INCOME_DEV_LIVE) {
    store.dispatch('configurateWidget', {
      holderType: holderTypes.INDIVIDUAL,
      product: products.INCOME,
      publicKey: PUBLIC_KEY_LIVE_DEV,
      webhookUrl: WEBHOOK_URL,
      mode: 'live',
    });
    store.dispatch('widgetOpened');
  }
}

export default setupDevelopment;
