import getProductStateMachine from '../helpers/product';
import Tracking from '../helpers/tracking';
import Payments from '../helpers/payments';
import * as messages from '../widget_api/messages';
import products from '../../sharedTypes/products';
import optimizelyFlags from '../helpers/optimizely';

const initialState = {
  entityIdSelected: null,
  currentState: null,
  currentStateContext: null,
  stateCounter: 0,
  isContactStepSeen: false,
  isPaymentStepSeen: false,
  isPaymentSelectingAuthSeen: false,
  totalStepsBeforePaymentSteps: 0,
  open: false,
  primaryColor: null,
  stateMachine: null,
  textColor: null,
  errorType: null,
  windowWidth: null,
  windowHeight: null,
  showFintocDrawer: false,
};

const getters = {
  entityName(state) {
    switch (state.entityIdSelected) {
      case 'mx_fiscal_sat':
        return 'el SAT';
      case 'cl_fiscal_sii':
        return 'el SII';
      default:
        return 'tu banco';
    }
  },
  isFiscal(state) {
    return state.entityIdSelected === 'cl_fiscal_sii';
  },
  headerConfig(state) {
    return state.stateMachine.getHeaderConfig(state.currentState, state.currentStateContext);
  },
  bodyConfig(state) {
    return state.stateMachine.getBodyConfig(state.currentState);
  },
  institutionName(state, _, rootState) {
    if (state.entityIdSelected === null) {
      return null;
    }
    return rootState.config.banks.find((bank) => bank.publicId === state.entityIdSelected).name;
  },
  bankTextEntitySelected(state) {
    const entities = {
      cl_banco_bci: 'Banco BCI',
      cl_banco_bci_360: 'Banco BCI 360',
      cl_banco_bice: 'Banco BICE',
      cl_banco_de_chile: 'Banco de Chile',
      cl_banco_estado: 'Banco Estado',
      cl_banco_itau: 'Banco Itaú',
      cl_banco_santander: 'Banco Santander',
      cl_banco_scotiabank: 'Banco Scotiabank',
      cl_banco_security: 'Banco Security',
      mx_banco_banamex: 'Banco Banamex',
      mx_banco_bbva: 'Banco BBVA',
      cl_banco_falabella: 'Banco Falabella',
      cl_banco_ripley: 'Banco Ripley',
    };
    return entities[state.entityIdSelected];
  },
  isWindowHeightSmall(state) {
    return (state.windowHeight < 750);
  },
  authDeviceName(state) {
    const defaultText = 'Dispositivo de Seguridad';
    const entities = {
      cl_banco_bci: 'MultiPass',
      cl_banco_de_chile: 'Digipass',
      cl_banco_falabella: 'App Banco Falabella',
      mx_banco_banorte: 'App Banorte',
      mx_banco_bbva: 'App BBVA',
    };
    return entities[state.entityIdSelected] ?? defaultText;
  },
  authAppName(state, gett) {
    const defaultText = `Aplicación de ${gett.bankTextEntitySelected}`;
    const entities = {
      cl_banco_bci: 'BciPass',
      cl_banco_de_chile: 'Mi Pass',
      cl_banco_estado: 'BE Pass',
      cl_banco_falabella: 'App Falabella',
      cl_banco_itau: 'Itaú Pass',
      cl_banco_santander: 'Santander PASS',
      cl_banco_santander_pyme: 'Santander PASS',
      cl_banco_scotiabank: 'KeyPass',
    };
    return entities[state.entityIdSelected] ?? defaultText;
  },
  authCoordinatesName(state) {
    const defaultText = 'Tarjeta de Coordenadas';
    const entities = {
      cl_banco_de_chile: 'Digicard',
    };
    return entities[state.entityIdSelected] ?? defaultText;
  },
  isBancoFalabella(state) {
    return state.entityIdSelected === 'cl_banco_falabella';
  },
  isBancoBBVA(state) {
    return state.entityIdSelected === 'mx_banco_bbva';
  },
  isBancoBanorte(state) {
    return state.entityIdSelected === 'mx_banco_banorte';
  },
};

const mutations = {
  loadWidgetFrozenState(state, payload) {
    Object.assign(state, { ...(payload || {}) });
  },
  transitionToNextState(state, { transition, stateContext }) {
    state.currentState = state.stateMachine.transition(state.currentState, transition);
    state.currentStateContext = stateContext;
  },
  setState(state, payload) {
    state.currentState = payload.state;
    state.currentStateContext = payload.stateContext;
  },
  setErrorType(state, { errorType, errorReason }) {
    state.errorType = errorType;
    state.errorReason = errorReason;
  },
  setStateMachine(state, { product }) {
    state.stateMachine = getProductStateMachine(product);
    state.currentState = state.stateMachine.initialState;
  },
  setInitialState(state) {
    state.currentState = state.stateMachine.initialState;
    state.entityIdSelected = null;
    state.open = false;
    state.stateCounter = 0;
    state.isContactStepSeen = false;
    state.isPaymentStepSeen = false;
    state.isPaymentSelectingAuthSeen = false;
    state.totalStepsBeforePaymentSteps = 0;
  },
  updateEntitySelected(state, { id }) {
    state.entityIdSelected = id;
  },
  widgetOpened(state) {
    state.open = true;
  },
  updateScreenDimensions(state, { width, height }) {
    state.windowWidth = width;
    state.windowHeight = height;
  },
  setStateCounter(state, { value }) {
    state.stateCounter += value;
  },
  setIsContactStepSeen(state) {
    state.isContactStepSeen = true;
  },
  setIsPaymentStepSeen(state) {
    state.isPaymentStepSeen = true;
  },
  setIsPaymentSelectingAuthSeen(state) {
    state.isPaymentSelectingAuthSeen = true;
  },
  setTotalStepsBeforePaymentSteps(state, { value }) {
    state.totalStepsBeforePaymentSteps = value;
  },
  toggleFintocDrawer(state) {
    state.showFintocDrawer = !state.showFintocDrawer;
  },
};

const actions = {
  setStateMachine({ commit, state, rootState }, payload) {
    commit('setStateMachine', payload);
    Tracking.trackState(state.currentState, rootState);
  },
  setErrorType({ commit }, payload) {
    commit('setErrorType', payload);
  },
  transitionToNextState({
    commit, state, rootState, dispatch,
  }, payload) {
    commit('transitionToNextState', payload);
    dispatch('blockAllAxiosIdempotencyKeyNextRequest');
    dispatch('cancelAllAxiosPostAndPatchRequest');
    messages.widgetEvent(state.currentState);
    Tracking.trackState(state.currentState, rootState);

    if (optimizelyFlags.progressBarVariant) {
      commit('setStateCounter', { value: Payments.checkCounterValue(payload.transition) });
      if (state.currentState === 'PAYMENTS_SELECTING_AUTH') {
        commit('setIsPaymentSelectingAuthSeen');
      }
      if (payload.stateContext === 'CONTACT_AUTH') {
        commit('setIsContactStepSeen');
      }
      if (payload.stateContext === 'PAYMENT_AUTH') {
        if (state.isPaymentStepSeen === false) {
          commit('setTotalStepsBeforePaymentSteps', { value: state.stateCounter });
        }
        commit('setIsPaymentStepSeen');
      }
    }
  },
  resetBankSelected({ commit }) {
    commit('updateEntitySelected', { id: null });
  },
  updateBankSelected({ commit }, payload) {
    commit('updateEntitySelected', { id: payload.bankId });
  },
  updateFiscalAuthoritySelected({ commit }, payload) {
    commit('updateEntitySelected', { id: payload.fiscalAuthorityId });
  },
  widgetClosed({ commit }) {
    commit('setInitialState');
    messages.widgetClosed();
  },
  widgetClosedFromClient({ commit }) {
    commit('setInitialState');
  },
  widgetFinished({ commit, rootState }) {
    commit('setInitialState');
    const { product } = rootState.config;
    const { link } = rootState.link;
    const { refreshIntent } = rootState;
    const { paymentIntent } = rootState.payments;
    const { subscriptionIntent } = rootState.subscriptionIntents;

    Tracking.trackWidgetFinished(rootState);

    if (product === products.PAYMENTS) {
      return messages.widgetFinished({ paymentIntent });
    }
    if (product === products.SUBSCRIPTION_INTENTS) {
      return messages.widgetFinished({ subscriptionIntent });
    }
    return messages.widgetFinished({ link, refreshIntent });
  },
  widgetOpened({ commit, state }) {
    commit('widgetOpened');
    if (state.currentState !== null) { messages.widgetEvent(state.currentState); }
  },
  updateScreenDimensions({ commit }, payload) {
    commit('updateScreenDimensions', { width: payload.width, height: payload.height });
  },
  setWidgetParentOrigin({ commit }) {
    const urlParams = new URLSearchParams(window.location.search);
    commit('setWidgetParentOrigin', { origin: urlParams.get('parent') });
  },
  toggleFintocDrawer({ commit }) {
    commit('toggleFintocDrawer');
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
