<template >
  <widget-layout>
    <div class="outer-container">
      <PaymentInfo
        v-if="!isProgressBarVariant"
        class="mt-2"
      />
      <div class="flex flex-col items-center px-4 pb-8 w-screen bg-white justify-center h-full space-y-8">
        <div class="mt-8 text-center w-screen max-w-90 min-w-60">
          <div class="font-M font-700 color-txt-heading"> Procesando transferencia... </div>
          <div class="font-S font-400 color-txt-body"> Esto demora unos segundos </div>
        </div>

        <Spinner />
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Transferir"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import utils from '../../helpers/utils';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';
import Spinner from '../layout/Spinner.vue';
import PaymentInfo from './PaymentInfo.vue';
import PaymentsMixin from '../mixins/payments';
import optimizelyFlags from '../../helpers/optimizely';

const { setFrozenFriendlyTimeout } = utils;

export default {
  mixins: [PaymentsMixin],
  data() {
    return {
      polling: null,
    };
  },
  computed: {
    ...mapState({
      paymentStatusAndMfaType: (state) => `${state.payments.status}$${state.payments.secondFactorType}`,
      secondFactorType: (state) => state.payments.secondFactorType,
      organizationName: (state) => state.config.organizationName,
      amount: (state) => state.payments.amount.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' }),
    }),
    isProgressBarVariant() {
      return optimizelyFlags.progressBarVariant;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'getPaymentStatus']),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    transitionToFailed() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PROCESSING_ACTION_FAILED,
      });
    },
    async transitionToSucceeded() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PROCESSING_ACTION_SUCCEEDED,
      });
    },
    async transitionToSelectingAuth({ stateContext }) {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.PAYMENTS_SELECTING_AUTH,
        stateContext,
      });
    },
    async requestPaymentsStatus() {
      try {
        await this.getPaymentStatus();
      } catch (error) {
        Sentry.captureException(error);
        clearInterval(this.polling);
        this.transitionToFailed();
      }
    },
    pollingTimeout() {
      if (this.polling !== null) {
        this.transitionToFailed();
      }
    },
    pollPaymentIntent() {
      this.polling = setInterval(() => {
        this.requestPaymentsStatus();
      }, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_RATE_SEC);
      setFrozenFriendlyTimeout(this.pollingTimeout, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_TIMEOUT_SEC);
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    this.pollPaymentIntent();
  },
  watch: {
    paymentStatusAndMfaType(statusAndMfaType) {
      const [status] = statusAndMfaType.split('$');
      if (status === 'succeeded') {
        this.transitionToSucceeded();
      }

      if (status === 'failed') {
        this.transitionToFailed();
      }

      if (status === 'unknown') {
        this.transitionToFailed();
      }

      if (status === 'rejected') {
        this.transitionToFailed();
      }

      if (this.isPaymentSelectingContactAuthStatus(status)) {
        this.transitionToSelectingAuth({ stateContext: constants.state_context.CONTACT_AUTH });
      }

      if (this.isPaymentSelectingTransactionAuthStatus(status)) {
        this.transitionToSelectingAuth({ stateContext: constants.state_context.PAYMENT_AUTH });
      }

      if (this.isPaymentContactAuthStatus(status)) {
        this.clearPolling();
        this.transitionToPayment2FA({ stateContext: constants.state_context.CONTACT_AUTH });
      }

      if (this.isPaymentTransactionAuthStatus(status)) {
        this.clearPolling();
        this.transitionToPayment2FA({ stateContext: constants.state_context.PAYMENT_AUTH });
      }
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Spinner,
    PaymentInfo,
  },
};
</script>
