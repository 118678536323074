import errors from '../errors';

class StateMachine {
  constructor(machine) {
    this.machine = machine;
    this.initialState = this.findInitialState();
  }

  findInitialState() {
    return this.machine.initial;
  }

  transition(state, transition) {
    this.checkStateInMachine(state);
    this.checkValidTransition(state, transition);
    return this.machine.states[state].on[transition];
  }

  getHeaderConfig(state, stateContext = null) {
    let { title } = this.machine.states[state].header;
    if (typeof title !== 'string') {
      if (stateContext !== undefined && stateContext !== null) {
        title = this.machine.states[state].header.title[stateContext];
      } else {
        title = this.machine.states[state].header.title.default;
      }
    }
    return {
      back: this.machine.states[state].header.back,
      title,
      close: this.machine.states[state].header.close,
      hideSeparator: this.machine.states[state].header.hideSeparator || false,
    };
  }

  getBodyConfig(state) {
    return this.machine.states[state].body;
  }

  checkStateInMachine(state) {
    if (this.machine.states[state] === undefined) {
      throw new errors.StateNotInStateMachine(`Invalid state: ${state} not defined in state machine`);
    }
  }

  checkValidTransition(state, transition) {
    if (this.machine.states[state].on[transition] === undefined) {
      throw new errors.InvalidTransition(`Invalid transition: ${transition} not defined for state ${state}`);
    }
  }
}

export default StateMachine;
