<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { useVisibility } from './composables/visibility';
import Tracking from './helpers/tracking';
import constants from './constants';
import AnalyticsEvents from './constants/analytics_events';

export default {
  computed: {
    ...mapState({
      currentState: (state) => state.widget.currentState,
    }),
  },
  /* Uncomment when FIN-1333 or FIP-46 is resolved. This code disables zoom for touch events.
  data() {
    return {
      tapped: false,
    };
  },
  */
  setup() {
    const { onHidden, onShown } = useVisibility();

    onHidden(() => Tracking.trackEvent(
      AnalyticsEvents.WIDGET_VISIBILITY_CHANGED,
      { visibility: 'hidden' },
    ));
    onShown(() => Tracking.trackEvent(
      AnalyticsEvents.WIDGET_VISIBILITY_CHANGED,
      { visibility: 'shown' },
    ));
  },
  methods: {
    ...mapActions(['widgetClosed']),
    /* Uncomment when FIN-1333 or FIP-46 is resolved. This code disables zoom for touch events.
    handleTouchStart(e) {
      const millisecondsUntilAllowTouch = 600;
      if (!this.tapped) {
        this.tapped = setTimeout(() => {
          this.tapped = null;
        }, millisecondsUntilAllowTouch);
      } else {
        e.preventDefault();
        e.stopPropagation();
        clearTimeout(this.tapped);
        this.tapped = null;
      }
    },
    handleTouchMove(e) {
      const numberOfFingersTouchMoving = e.touches.length;
      if (numberOfFingersTouchMoving !== 1) {
        e.preventDefault();
        e.stopPropagation();
      }
    },
    */
  },
  watch: {
    currentState(newState) {
      if (newState === constants.states.WIDGET_CLOSED) {
        this.widgetClosed();
      }
    },
  },
  /* Uncomment when FIN-1333 or FIP-46 is resolved. This code disables zoom for touch events.
  created() {
    document.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    document.addEventListener('touchmove', this.handleTouchMove, { passive: false });
  },
  destroyed() {
    document.removeEventListener('touchstart', this.handleTouchStart);
    document.removeEventListener('touchmove', this.handleTouchMove);
  },
  */
};
</script>
<style>
@import './assets/css/tailwind.css';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './assets/css/fintoc.css';

html {
  font-family: 'Inter';
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  overflow: hidden;
}

</style>
