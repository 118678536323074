<template >
  <widget-layout includeScrollArrow>
    <div class="outer-container">
      <div class="container-sc">
        <div class="top-container-sc">
          <div class="font-XS font-400 color-txt-sec-and-cap"> Empresa: </div>
          <div class="font-heading-XS font-700 color-txt-heading text-center marginLR-20"> {{ organizationName }} </div>
        </div>

        <div class="middle-frame-sc">
          <div class="font-M font-700 color-txt-heading text-center marginLR-20"> Selecciona la cuenta bancaria con la que deseas suscribir el PAC </div>
        </div>

        <div class="accounts-frame-sc">
          <div
            v-for="account in permittedAccounts"
            :key='account.id'
            :class="{ 'cell-sc selected-sc border-2 border-primary-main': isSelectedAccount(account), 'cell-sc not-selected-sc': !isSelectedAccount(account) }"
            @click="selectAccount(account)"
          >
            <div class="font-400 font-S text-sc color-txt-body"> {{account.name}} </div>
            <div class="font-400 font-S text-sc color-txt-body">
                <div>
                  {{ account.number }}
                </div>
                <div>
                  {{ parsedBalance(account) }}
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Suscribir"
          @click.native="confirmAccount()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';

export default {
  data() {
    return {
      loading: false,
      account: null,
      idempotencyKey: '',
    };
  },
  computed: {
    ...mapGetters(['isWindowHeightSmall']),
    ...mapState({
      accounts: (state) => state.link.accounts,
      organizationName: (state) => state.config.organizationName,
    }),
    permittedAccounts() {
      const accountTypes = [
        'checking_account', 'sight_account',
      ];
      const currency = 'CLP';
      const filteredAccounts = this.accounts.filter((account) => accountTypes.includes(account.type) && account.currency === currency);
      const sortedAccounts = filteredAccounts.sort((a, b) => b.balance.available - a.balance.available);
      return sortedAccounts;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'confirmSubscriptionIntentAccount']),
    async confirmAccount() {
      try {
        const account = this.account === null ? this.permittedAccounts[0] : this.account;
        await this.confirmSubscriptionIntentAccount({ account, idempotencyKey: this.idempotencyKey });
        this.transitionToNextState({ transition: constants.transitions.SUBSCRIPTION_CONFIRMED_SUCCEEDED });
      } catch (error) {
        Sentry.captureException(error);
        if (error.__CANCEL__) {
          return;
        }

        this.transitionToNextState({ transition: constants.transitions.SUBSCRIPTION_CONFIRMED_FAILED });
      }
    },
    isSelectedAccount(account) {
      if (this.account) {
        return this.account.id === account.id;
      }
      return this.permittedAccounts[0].id === account.id;
    },
    selectAccount(account) {
      if (!this.loading) {
        this.account = account;
      }
    },
    parsedBalance(account) {
      const parsedBalance = account.balance.available.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
      return parsedBalance;
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
  },
  mounted() {
    this.idempotencyKey = uuidv1();
    this.selectAccount(this.permittedAccounts[0]);
  },
};
</script>

<style>

.text-sc {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: calc(100vw - 80px);
  max-width: 280px;
  min-width: 200px;
}

.not-selected-sc {
  background: #FFFFFF;
  border: 1px solid #C5C4D3;
}

.cell-sc {
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 12px 16px;
  width: calc(100vw - 40px);
  max-width: 320px;
  min-height: 60px;
  max-height: 60px;
  box-sizing: border-box;
  border-radius: 6px;
}

.accounts-frame-sc {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  height: 240px;
  max-height: 240px;
  min-height: 240px;

  overflow-y: scroll;
}

.middle-frame-sc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin: 20px 0px;
}

.top-container-sc {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;

  width: 100vw;
  max-width: 360px;
}

.container-sc {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 360px;
}

</style>
