<template>
  <div class="flex items-center justify-center font-semibold">
    <default class="h-10 w-10 ml-4" />
    <span class="ml-4 text-xl"> {{ bankText }} </span>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Default from '../icons/Banks/Default.vue';

export default {
  computed: {
    ...mapState({
      anonimizedEntities: (state) => state.config.anonimizedEntities,
      holderType: (state) => state.config.holderType,
      entityId: (state) => state.widget.entityIdSelected,
    }),
    bankText() {
      switch (this.entityId) {
        case 'cl_banco_bci': {
          return 'Banco BCI';
        }
        case 'cl_banco_bci_360': {
          return 'Banco BCI 360';
        }
        case 'cl_banco_bice': {
          return 'Banco BICE';
        }
        case 'cl_banco_de_chile': {
          return 'Banco de Chile';
        }
        case 'cl_banco_estado': {
          return 'Banco Estado';
        }
        case 'cl_banco_itau': {
          return 'Banco Itau';
        }
        case 'cl_banco_santander': {
          return 'Banco Santander';
        }
        case 'cl_banco_scotiabank': {
          return 'Banco Scotiabank';
        }
        case 'cl_banco_security': {
          return 'Banco Security';
        }
        case 'mx_banco_banamex': {
          return 'Banco Banamex';
        }
        case 'mx_banco_bbva': {
          return 'Banco BBVA';
        }
        case 'mx_banco_azteca': {
          return 'Banco Azteca';
        }
        case 'mx_banco_banorte': {
          return 'Banco Banorte';
        }
        default: {
          return '';
        }
      }
    },
  },
  components: {
    Default,
  },
};
</script>
