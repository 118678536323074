<template>
  <button
    :class="`relative max-w-90 flex flex-row justify-center items-center py-3 px-4 rounded-md w-full ${colorClasses}`"
    :disabled="disabled || loading">
    <div v-if="loading">
      <LoadingSpinner class="mr-1.5 h-5 w-5" />
    </div>
    <div
      v-if="text"
      class="font-medium text-lg"
    >
      {{ text }}
    </div>
  </button>
</template>

<script>
import { mapGetters } from 'vuex';
import LoadingSpinner from '../icons/LoadingSpinner.vue';

export default {
  props: {
    disabled: Boolean,
    loading: Boolean,
    secondary: Boolean,
    text: String,
  },
  computed: {
    ...mapGetters(['isPrimaryColorDark', 'isPrimaryMainFintoc']),
    colorClasses() {
      if (this.loading) {
        return 'cursor-default bg-light-gray text-disabled-color';
      }
      if (this.disabled) {
        return 'cursor-default bg-light-gray text-disabled-color';
      }
      if (this.secondary) {
        return 'bg-primary-surface text-primary-main focus:ring focus:ring-primary-focus';
      }
      const baseClass = 'bg-primary-main text-white';
      if (!this.isPrimaryMainFintoc) {
        if (this.isPrimaryColorDark) {
          return `${baseClass} focus:opacity-80 hover:opacity-90`;
        }
        return `${baseClass} filter hover:brightness-90 focus:brightness-80`;
      }
      return `${baseClass} active:bg-primary-pressed hover:bg-primary-hover`;
    },
  },
  components: { LoadingSpinner },
};
</script>
