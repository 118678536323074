<template>
  <Drawer :showDrawer="showDrawer" height="h-2/5" @close="closeDrawer">
    <div class="h-full pt-8 pb-4 flex flex-col justify-between">
      <div class="flex flex-col items-center space-y-2 overflow-y-scroll px-6" ref="mainContentContainer" @scroll="onScroll">
        <p class="text-heading-color font-bold text-lg text-center">{{ title }}</p>
        <p class="text-body-color font-medium">Fintoc le permite a {{ organizationName }} procesar de forma segura y simple tu pago por transferencia dentro de la misma aplicación.</p>
      </div>
      <div class="px-5 pt-3 w-full" :class="{'shadow': canScrollToBottom}">
        <ActionButton @click.native="closeDrawer" text="Cerrar"/>
      </div>
    </div>
  </Drawer>
</template>
<script>
import { mapState } from 'vuex';
import StylesMixin from '../../mixins/styles';
import Drawer from '../../layout/Drawer.vue';
import ActionButton from '../../layout/ActionButton.vue';
import optimizelyFlags from '../../../helpers/optimizely';

export default {
  mixins: [StylesMixin],
  props: {
    showDrawer: Boolean,
  },
  computed: {
    ...mapState({
      organizationName: (state) => state.config.organizationName,
    }),
    title() {
      switch (optimizelyFlags.loginQuestionsVariationKey) {
        case 'what_is_fintoc':
          return '¿Qué es Fintoc?';
        case 'how_it_works':
          return '¿Cómo funciona?';
        case 'why_is_involved':
          return '¿Por qué esta involucrado Fintoc?';
        default:
          return '';
      }
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
  },
  components: { Drawer, ActionButton },
};
</script>
