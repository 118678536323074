<template>
  <widget-layout :goBackFunc="goBackFunction" :showDrawer="showDrawer">
    <div class="outer-container">
      <div class="flex flex-col items-center w-screen max-w-90 min-w-50 space-y-8">
        <EntityLogo class="pt-8"/>
        <div class="flex flex-col justify-between items-center max-w-xs w-full mx-10">

          <div
            v-if="isBusiness && !isSantanderPyme"
            class="flex flex-col justify-center items-start py-0 px-2.5 m-1 w-full h-12 box-border rounded-md focus-within:border-solid focus-within:border-1.5 focus-within:border-primary-main color-bg-neutral min-h-12"
            :class="(shouldShowDisabledHolderIdInput ? 'color-bg-disabled' : 'color-bg-neutral')"
            @click="focusInput('holderId')"
            id="holder-id-div"
          >
            <div class="flex items-center h-12 w-full cursor-text">
              <InputPerson class="mr-2 text-placeholder-color"/>
              <div class="flex flex-col justify-center items-start w-full">
                <div class="font-XS font-500 color-txt-sec-and-cap" id="holder-id-label">
                  {{ holderIdInputLabel }}
                </div>
                <div
                  class="font-XS font-500 color-txt-body input w-full"
                  v-if="shouldShowDisabledHolderIdInput && !isChileanRut"
                  id="holder-id-preselected"
                >
                    {{ preselectedUsername }}
                </div>
                <div
                  class="font-XS font-500 color-txt-body input w-full"
                  v-if="shouldShowDisabledHolderIdInput && isChileanRut"
                  id="holder-id-chilean-preselected"
                >
                    {{ preselectedUsername | rutFilter }}
                </div>
                <input
                  v-if="!shouldShowDisabledHolderIdInput && isChileanRut"
                  class="font-XS font-500 color-txt-body focus:outline-none w-full pr-2"
                  v-on:keyup.enter="onEnter"
                  v-model.trim="$v.holderId.$model"
                  v-rut:live
                  data-cy="holderIdInput"
                  ref="chileanHolderIdInput"
                />
                <input
                  v-if="!shouldShowDisabledHolderIdInput && !isChileanRut"
                  class="font-XS font-500 color-txt-body focus:outline-none w-full pr-2"
                  v-on:keyup.enter="onEnter"
                  v-model.trim="$v.holderId.$model"
                  data-cy="holderIdInput"
                  ref="notChileanHolderIdInput"
                />
              </div>
            </div>
          </div>

          <div
            v-if="!(isBusiness && isFiscal)"
            class="flex flex-col justify-center items-start py-0 px-2.5 m-1 w-full h-12 box-border rounded-md focus-within:border-solid focus-within:border-1.5 focus-within:border-primary-main min-h-12"
            :class="(preselectedUsernameExists ? 'color-bg-disabled' : 'color-bg-neutral')"
            @click="focusInput('username')"
            id="username-div"
          >
            <div class="flex items-center h-12 w-full cursor-text">
              <InputPerson class="mr-2 text-placeholder-color"/>
              <div class="flex flex-col justify-center items-start w-full">
                <div
                  class="font-XS font-500 color-txt-sec-and-cap"
                  id="username-label"
                >
                  {{ usernameInputLabel }}
                </div>
                <div
                  class="font-XS font-500 color-txt-body input w-full"
                  v-if="preselectedUsernameExists && isChileanRut"
                  id="username-chilean-preselected">
                    {{ preselectedUsername | rutFilter }}
                </div>
                <div
                  class="font-XS font-500 color-txt-body input w-full"
                  v-if="preselectedUsernameExists && !isChileanRut"
                  id="username-preselected">
                    {{ preselectedUsername }}
                </div>
                <input
                  v-if="!preselectedUsernameExists && isChileanRut"
                  class="font-XS font-500 color-txt-body focus:outline-none w-full"
                  v-on:keyup.enter="onEnter"
                  v-model.trim="$v.username.$model"
                  v-rut:live
                  data-cy="usernameInput"
                  ref="chileanUsernameInput"
                />
                <input
                  v-if="!preselectedUsernameExists && !isChileanRut"
                  class="font-XS font-500 color-txt-body focus:outline-none w-full"
                  v-on:keyup.enter="onEnter"
                  v-model.trim="$v.username.$model"
                  data-cy="usernameInput"
                  ref="notChileanUsernameInput"
                />
              </div>
              <a v-clipboard:copy='username||preselectedUsername'>
                <Copy class="cursor-pointer" v-if="isBancoBBVA" />
              </a>
            </div>
          </div>

          <div
            class="flex flex-col justify-center items-start py-0 px-2.5 m-1 w-full h-12 box-border rounded-md focus-within:border-solid focus-within:border-1.5 focus-within:border-primary-main color-bg-neutral min-h-12"
            @click="focusInput('password')"
            id="password-div"
          >
            <div class="flex items-center h-12 w-full cursor-text">
              <InputLock class="mr-2 text-placeholder-color"/>
              <div class="flex flex-col justify-center items-start w-full">
                <div id="password-label" class="font-XS font-500 color-txt-sec-and-cap">
                  {{ passwordInputLabel }}
                </div>
                <input
                  v-on:keyup.enter="onEnter"
                  v-model.trim="$v.password.$model"
                  :type="passwordInput"
                  class="font-XS font-500 color-txt-body focus:outline-none w-full"
                  data-cy="passwordInput"
                  ref="passwordInput"
                />
              </div>
              <InputEye class="cursor-pointer ml-2" @click.native="togglePassword()" :crossedOut="showPassword"/>
            </div>
          </div>
          <div class="flex items-center justify-center w-full pl-3 gap-1" v-if="showPasswordHint" data-test="password-hint">
            <ExclamationCircle class="w-3.5 h-3.5 min-w-3.5 min-h-3.5 text-primary-main"/>
            <div class="w-full text-xs ml-1"> {{ passwordHintText }} <span class="font-bold">portal web</span></div>
          </div>

          <TSLWarning :isPayments="isPayments"/>
          <button id="paymentsResetPassword" class="w-80 h-5 mt-2 text-sm text-center text-primary-main" v-if="(isPayments && resetPasswordUrl!== false)" @click="resetPasswordPressed"> Recuperar {{passwordInputLabel}} </button>
          <button class="w-80 h-5 mt-1.5 text-sm text-center text-primary-main tracking-tighter" v-if="isBancoBBVA" @click="openResetPasswordHelper" id="openResetPassword">Recuperar contraseña web</button>
          <button class="w-80 h-5 mt-1.5 text-sm text-center text-primary-main tracking-tighter" v-if="isBancoBBVA" @click="openNewPasswordHelper" id="openNewPassword">Crear contraseña web</button>
          <button class="w-80 h-5 mt-6 text-sm text-center text-primary-main underline" v-if="showLoginQuestion" @click="toggleDrawer"> {{ loginQuestion }} </button>
        </div>
      </div>

    </div>
    <template #drawer>
      <LoginQuestionsDrawer :showDrawer="showDrawer" @close="toggleDrawer" />
    </template>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          v-if="loading"
          :disabled="true"
          text="Continuar"
        />
        <ActionButton
          v-else
          data-cy="continue"
          :disabled="$v.$invalid"
          text="Continuar"
          @click.native="debounceCreateLink()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { requiredIf, required, minLength } from 'vuelidate/lib/validators';
import { rutValidator } from 'vue-dni';
import { v1 as uuidv1 } from 'uuid';

import Debounce from '../../mixins/debounce';
import holderTypes from '../../../../sharedTypes/holderTypes';
import products from '../../../../sharedTypes/products';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import constants from '../../../constants';
import ActionButton from '../../layout/ActionButton.vue';
import Footer from '../../layout/Footer.vue';
import EntityLogo from '../../layout/EntityLogo.vue';
import InputPerson from '../../icons/InputPerson.vue';
import InputLock from '../../icons/InputLock.vue';
import InputEye from '../../icons/Eye.vue';
import Copy from '../../icons/Copy.vue';

import AnalyticsEvents from '../../../constants/analytics_events';
import Analytics from '../../../helpers/analytics';
import optimizelyFlags from '../../../helpers/optimizely';
import Tracking from '../../../helpers/tracking';
import PaymentsMixin from '../../mixins/payments';
import TSLWarning from './TSLWarning.vue';
import ExclamationCircle from '../../icons/ExclamationCircle.vue';
import LoginQuestionsDrawer from './LoginQuestionsDrawer.vue';

export default {
  mixins: [Debounce, PaymentsMixin],
  data() {
    return {
      holderId: '',
      username: '',
      password: '',
      loading: false,
      showPassword: false,
      idempotencyKey: '',
      showDrawer: false,
    };
  },

  validations() {
    return {
      holderId: {
        required: requiredIf(() => this.isBusiness && !this.isSantanderPyme),
        validRut(holderId) {
          if (this.isChileanRut && this.isBusiness && !this.isSantanderPyme) {
            return rutValidator(holderId);
          }
          return true;
        },
      },
      username: {
        required: requiredIf(() => !(this.isBusiness && this.isFiscal)),
        validRut(username) {
          if (this.isChileanRut && !(this.isBusiness && this.isFiscal)) {
            return rutValidator(username);
          }
          return true;
        },
      },
      password: {
        required,
        minLength: minLength(4),
      },
    };
  },

  computed: {
    ...mapState({
      entityId: (state) => state.widget.entityIdSelected,
      product: (state) => state.config.product,
      holderType: (state) => state.config.holderType,
      preselectedUsername: (state) => state.config.username,
      savedUsername: (state) => state.link.username,
      analyticsEventProperties: (state) => Analytics.getEventPropertiesFromRootState(state),
      paymentStatus: (state) => state.payments.status,
      subscriptionIntentStatus: (state) => state.subscriptionIntents.status,
      windowHeight: (state) => state.widget.windowHeight,
    }),
    ...mapGetters([
      'bankTextEntitySelected',
      'isBancoBBVA',
    ]),
    isIndividual() {
      return (this.holderType === holderTypes.INDIVIDUAL);
    },
    isBusiness() {
      return (this.holderType === holderTypes.BUSINESS);
    },
    isPayments() {
      return this.isIndividual && this.product === products.PAYMENTS;
    },
    isEstadoIndividual() {
      return this.isIndividual && this.entityId === 'cl_banco_estado';
    },
    isChileIndividual() {
      return this.isIndividual && this.entityId === 'cl_banco_de_chile';
    },
    isMexicoWebPassword() {
      return ['mx_banco_hsbc', 'mx_banco_bbva'].includes(this.entityId);
    },
    showPasswordHint() {
      return this.isEstadoIndividual || this.isChileIndividual || this.isMexicoWebPassword;
    },
    passwordHintText() {
      if (this.entityId.includes('mx_')) {
        return 'Recuerda utilizar la contraseña del';
      }
      return 'Recuerda utilizar la clave del';
    },
    isFiscal() {
      return (
        this.product === products.TAX_RETURNS || this.product === products.INVOICES
        || this.product === products.INCOME || this.product === products.TAX_STATEMENTS
      );
    },
    needsUsername() {
      return ['mx_hey_banco', 'mx_banco_hsbc', 'mx_banco_banorte'].includes(this.entityId);
    },
    fillUsername() {
      return this.isFiscal && this.isBusiness;
    },
    preselectedUsernameExists() {
      return !!this.preselectedUsername;
    },
    passwordInput() {
      return this.showPassword ? 'input' : 'password';
    },
    usernameInputLabel() {
      if (this.entityId === 'mx_banco_bbva') return 'Número de tarjeta de débito';
      if (this.needsUsername) return 'Nombre de usuario';
      if (this.entityId === 'mx_banco_azteca') return 'Usuario/Cuenta/Tarjeta/Celular';
      if (['mx_banco_banamex', 'mx_banco_santander'].includes(this.entityId)) return 'Número de cliente';
      return 'RUT persona';
    },
    holderIdInputLabel() {
      if (this.entityId === 'mx_fiscal_sat') return 'RFC';
      return 'RUT Empresa';
    },
    passwordInputLabel() {
      if (this.entityId === 'mx_banco_bbva') return 'Contraseña web';
      if (this.entityId === 'cl_banco_estado') return 'Clave Internet';
      if (this.entityId === 'cl_banco_de_chile') return 'Clave';
      if (this.entityId === 'cl_banco_santander') return 'Clave Digital';
      if (this.entityId.includes('mx_')) return 'Contraseña';
      return 'Clave';
    },
    isChileanRut() {
      return this.entityId.includes('cl_');
    },
    resetPasswordUrl() {
      if (this.entityId === 'cl_banco_estado') return 'https://www.bancoestado.cl/imagenes/_personas/login/ingresarut.asp';
      if (this.entityId === 'cl_banco_de_chile') return 'https://login.bancochile.cl/bancochile-web/persona/login/index.html#/olvido-clave-persona';
      if (this.entityId === 'cl_banco_santander') return 'https://www.santander.cl/transa/productos/activatuclave3.0/index.asp';
      return false;
    },
    isSubscriptionIntents() {
      return this.isIndividual && this.product === products.SUBSCRIPTION_INTENTS;
    },
    isSantanderPyme() {
      return this.entityId === 'cl_banco_santander_pyme';
    },
    isSubscriptionIntentRetryable() {
      return this.subscriptionIntentStatus === 'retryable_failure';
    },
    shouldShowDisabledHolderIdInput() {
      return this.preselectedUsernameExists && this.isFiscal;
    },
    goBackFunction() {
      return this.loading ? () => {} : undefined;
    },
    showLoginQuestion() {
      return this.isPayments && optimizelyFlags.loginQuestionsVariationKey !== 'off';
    },
    loginQuestion() {
      switch (optimizelyFlags.loginQuestionsVariationKey) {
        case 'what_is_fintoc':
          return '¿Qué es Fintoc?';
        case 'how_it_works':
          return '¿Cómo funciona?';
        case 'why_is_involved':
          return '¿Por qué esta involucrado Fintoc?';
        default:
          return '';
      }
    },
  },
  methods: {
    onEnter() {
      if (!this.loading) {
        this.debounceCreateLink();
      }
    },
    ...mapActions(
      ['linkIntentLoading', 'transitionToNextState', 'createLinkIntent',
        'setErrorType', 'setLinkIntentStatus', 'saveUsername',
        'getPaymentStatus', 'getSubscriptionIntentStatus'],
    ),
    debounceCreateLink() {
      this.loading = true;
      this.debounce(this.createLink);
    },
    async processLinkIntentToFailed() {
      if (this.isPayments) {
        await this.getPaymentStatus();
        if (this.isPaymentRetryable(this.paymentStatus)) {
          this.transitionToNextState({ transition: constants.transitions.LINK_INTENT_CREATE_FAILED });
        } else {
          this.transitionToNextState({ transition: constants.transitions.PAYMENTS_NO_RETRY_ERROR });
        }
      } else if (this.isSubscriptionIntents) {
        await this.getSubscriptionIntentStatus();
        if (this.isSubscriptionIntentRetryable) {
          this.transitionToNextState({ transition: constants.transitions.LINK_INTENT_CREATE_FAILED });
        } else {
          this.transitionToNextState({ transition: constants.transitions.SUBSCRIPTIONS_NO_RETRY_ERROR });
        }
      } else {
        this.transitionToNextState({ transition: constants.transitions.LINK_INTENT_CREATE_FAILED });
      }
    },
    async createLink() {
      if (this.$v.$invalid) { return; }

      if (this.isPayments) {
        optimizelyFlags.trackEvent('credentials_attempted');
      }

      this.loading = true;
      if (this.fillUsername) {
        this.username = this.holderId;
      }
      try {
        let formattedUsername;
        if (this.needsUsername) {
          formattedUsername = this.username;
        } else {
          formattedUsername = this.username.toLowerCase().replace(/\./gi, '').replace('-', '');
        }
        await this.createLinkIntent({
          username: formattedUsername,
          holderId: this.holderId,
          password: this.password,
          idempotencyKey: this.idempotencyKey,
        });
        this.linkIntentLoading();
        this.transitionToNextState({
          transition: constants.transitions.LINK_INTENT_CREATE_SUCCEEDED,
        });
      } catch (error) {
        Sentry.captureException(error);
        if (error.__CANCEL__) {
          return;
        }

        await this.processLinkIntentToFailed();
      }
      this.loading = false;
    },
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    openResetPasswordHelper() {
      this.saveUsername({ username: this.username });
      this.transitionToNextState({ transition: constants.transitions.OPEN_RESET_PASSWORD_HELPER });
    },
    openNewPasswordHelper() {
      this.saveUsername({ username: this.username });
      this.transitionToNextState({ transition: constants.transitions.OPEN_NEW_PASSWORD_HELPER });
    },
    resetPasswordPressed() {
      window.open(this.resetPasswordUrl);
      window.analytics.track(
        AnalyticsEvents.RESET_PASSWORD_CLICKED, this.analyticsEventProperties,
      );
    },
    focusInput(inputSelected) {
      /* eslint-disable no-unused-expressions */
      if (inputSelected === 'password') {
        this.$refs.passwordInput.focus();
      }
      if (inputSelected === 'username') {
        if (this.isChileanRut) {
          this.$refs.chileanUsernameInput?.focus();
        } else {
          this.$refs.notChileanUsernameInput?.focus();
        }
      }
      if (inputSelected === 'holderId') {
        if (this.isChileanRut) {
          this.$refs.chileanHolderIdInput?.focus();
        } else {
          this.$refs.notChileanHolderIdInput?.focus();
        }
      }
      /* eslint-enable no-unused-expressions */
    },
    toggleDrawer() {
      if (!this.showDrawer) {
        Tracking.trackEvent(
          AnalyticsEvents.DRAWER_OPENED,
          { drawer: optimizelyFlags.loginQuestionsVariationKey },
        );
      }
      this.showDrawer = !this.showDrawer;
    },
  },
  created() {
    this.setLinkIntentStatus({ status: null });
    this.setErrorType({ errorType: null });
  },
  mounted() {
    this.idempotencyKey = uuidv1();
    this.username = this.savedUsername;
    if (this.preselectedUsernameExists) {
      this.username = this.preselectedUsername;
      if (this.shouldShowDisabledHolderIdInput) {
        this.holderId = this.preselectedUsername;
      }
    }
  },
  components: {
    ActionButton,
    WidgetLayout,
    Footer,
    EntityLogo,
    InputPerson,
    InputLock,
    InputEye,
    Copy,
    TSLWarning,
    ExclamationCircle,
    LoginQuestionsDrawer,
  },
};
</script>
