export default {
  INTRODUCTION_SCREEN_VIEWED: 'Introduction Screen Viewed',
  INSTITUTIONS_SCREEN_VIEWED: 'Institutions Screen Viewed',
  LOGIN_SCREEN_VIEWED: 'Login Screen Viewed',
  ERROR_SCREEN_VIEWED: 'Error Screen Viewed',
  WIDGET_CLOSED: 'Widget Closed',
  LOADING_SCREEN_VIEWED: 'Loading Screen Viewed',
  MFA_SCREEN_VIEWED: 'MFA Screen Viewed',
  CAPTCHA_SCREEN_VIEWED: 'CAPTCHA Screen Viewed',
  ACCOUNTS_SCREEN_VIEWED: 'Accounts Screen Viewed',
  SUCCESS_SCREEN_VIEWED: 'Success Screen Viewed',
  HELPER_SCREEN_VIEWED: 'Helper Screen Viewed',
  PAYMENT_VOUCHER_CLICKED: 'Payment Voucher Clicked',
  SUBSCRIPTION_INTENT_VOUCHER_CLICKED: 'Subscription Intent Voucher Clicked',
  EXIT_CLICKED: 'Exit Clicked',
  WIDGET_FINISH_CLICKED: 'Widget Finish Clicked',
  MFA_SELECTION_SCREEN_VIEWED: 'MFA Selection Screen Viewed',
  ABORT_SCREEN_VIEWED: 'Abort Screen Viewed',
  RESET_PASSWORD_CLICKED: 'Reset Password Clicked',
  WIDGET_VISIBILITY_CHANGED: 'Widget Visibility Changed',
  DRAWER_OPENED: 'Drawer Opened',
  MFA_APPLICATION_OPENED_FROM_WIDGET: 'MFA Application Opened From Widget',
};
