<template>
  <div
    :is="currentComponent"
  />
</template>

<script>
import { mapState } from 'vuex';

import BancoDeChileMiPass from '../icons/Bank-App/BancoDeChileMiPass.vue';
import BancoEstadoPass from '../icons/Bank-App/BancoEstadoPass.vue';
import Bci from '../icons/Bank-App/BancoBciPass.vue';
import Bice from '../icons/Bank-Notification/Bice.vue';
import Falabella from '../icons/Bank-Notification/Falabella.vue';
import Itau from '../icons/Bank-App/BancoItauPass.vue';
import Santander from '../icons/Bank-App/BancoSantanderPass.vue';
import SantanderPymePass from '../icons/Bank-App/BancoSantanderPymePass.vue';
import Scotiabank from '../icons/Bank-App/BancoScotiabankKeyPass.vue';
import Security from '../icons/Bank-Notification/Security.vue';

export default {
  computed: {
    ...mapState({
      entityId: (state) => state.widget.entityIdSelected || state.refreshIntent.institutionId,
    }),
    currentComponent() {
      const entities = {
        cl_banco_de_chile: 'banco-de-chile-mi-pass',
        cl_banco_estado: 'banco-estado-pass',
        cl_banco_bci: 'bci',
        cl_banco_bice: 'bice',
        cl_banco_falabella: 'falabella',
        cl_banco_itau: 'itau',
        cl_banco_santander: 'santander',
        cl_banco_santander_pyme: 'santander-pyme-pass',
        cl_banco_scotiabank: 'scotiabank',
        cl_banco_security: 'security',
      };
      return entities[this.entityId];
    },
  },
  components: {
    BancoDeChileMiPass,
    BancoEstadoPass,
    Bci,
    Bice,
    Falabella,
    Itau,
    Santander,
    SantanderPymePass,
    Scotiabank,
    Security,
  },
};
</script>
