<template >
  <widget-layout>
    <div class="outer-container">
      <div class="container-sp2">
        <div class="text-center mt-10">
          <div class="font-XS font-400 color-txt-sec-and-cap"> Empresa: </div>
          <div class="font-heading-XS font-700 color-txt-heading"> {{ organizationName }} </div>
        </div>

        <div class="mt-8 text-center">
          <div class="font-M font-700 color-txt-heading"> Suscribiendo PAC... </div>
          <div class="font-S font-400 color-txt-body w-64"> Esto demora unos segundos</div>
        </div>

        <Spinner class="mt-8 z-10"/>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Suscribir"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import * as Sentry from '@sentry/vue';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';
import Spinner from '../layout/Spinner.vue';
import PollingMixin from '../mixins/polling';

export default {
  mixins: [PollingMixin],
  computed: {
    ...mapState({
      subscriptionIntentStatus: (state) => state.subscriptionIntents.status,
      errorReason: (state) => state.subscriptionIntents.errorReason,
      secondFactorType: (state) => state.subscriptionIntents.secondFactorType,
      organizationName: (state) => state.config.organizationName,
    }),
    subscriptionAlreadyExists() {
      return this.errorReason === 'subscription_already_exists';
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'getSubscriptionIntentStatus', 'getSubscriptionIntent']),
    transitionToFailed() {
      this.clearPolling();
      if (this.subscriptionAlreadyExists) {
        return this.transitionToNextState({
          transition: constants.transitions.LOADING_SUBSCRIPTION_ABORTED,
        });
      }
      return this.transitionToNextState({
        transition: constants.transitions.LOADING_SUBSCRIPTION_FAILED,
      });
    },
    async transitionToSucceeded() {
      this.clearPolling();
      this.transitionToNextState({
        transition: constants.transitions.LOADING_SUBSCRIPTION_SUCCEEDED,
      });
    },
    async transitionTo2FA() {
      this.clearPolling();
      switch (this.secondFactorType) {
        case 'enter_device_code': {
          this.transitionToNextState({
            transition: constants.transitions.SUBSCRIPTION_DEVICE_AUTH_REQUIRED,
          });
          break;
        }
        case 'authorize_in_app': {
          this.transitionToNextState({
            transition: constants.transitions.SUBSCRIPTION_APP_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_sms_code': {
          this.transitionToNextState({
            transition: constants.transitions.SUBSCRIPTION_SMS_AUTH_REQUIRED,
          });
          break;
        }
        case 'enter_coordinates': {
          this.transitionToNextState({
            transition: constants.transitions.SUBSCRIPTION_CARD_AUTH_REQUIRED,
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    async requestSubscriptionIntentStatus() {
      try {
        await this.getSubscriptionIntentStatus();
      } catch (error) {
        Sentry.captureException(error);
        this.transitionToFailed();
      }
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    this.startPolling(this.requestSubscriptionIntentStatus, process.env.VUE_APP_SUBSCRIPTION_INTENT_POLLING_RATE_SEC);
  },
  watch: {
    async subscriptionIntentStatus(status) {
      if (status === 'succeeded') {
        await this.getSubscriptionIntent();
        this.transitionToSucceeded();
      }

      if (status === 'failed') {
        this.transitionToFailed();
      }

      if (status === 'asking_subscription_auth') {
        this.transitionTo2FA();
      }
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    Spinner,
  },
};
</script>

<style>

.container-sp2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px;
  width: 100vw;
  max-width: 360px;
}
</style>
