/**
 * @typedef {'config' | 'widget' | 'payments' | 'links' | 'refreshIntents' | 'subscriptionIntents' | 'subscriptions'} FrozenStateSliceName
 */

/**
 * @typedef {Object} FrozenStateConfig
 * @property {string} product
 * @property {string} state
 * @property {Record<FrozenStateSliceName, any>} frozenConfig
 */

export const frozenStateName = () => process.env.VUE_APP_FROZEN_STATE || null;

export const isFrozen = () => frozenStateName() !== null;

/**
  * @param {string} frozenState
  * @returns {Promise<FrozenStateConfig>}
  */
export const getFrozenStateConfig = async (frozenState) => {
  /* eslint-disable-next-line prefer-template */
  const file = await import('../../frozen-states/' + frozenState + '.js');
  return { frozenConfig: {}, ...file.default };
};
