<template>
  <div
    v-if="currentComponent"
    tabIndex="0"
    class="rounded-md focus:ring focus:ring-primary-focus"
  >
    <component
      v-if="!anonimizedEntities"
      class="flex flex-col justify-center items-center bg-white shadow-md rounded-md w-full h-full px-5 py-4 hover:border-solid hover:border-1.5 hover:border-primary-main hover:cursor-pointer"
      :is="currentComponent"
      @click.native="selectBank"
    >
    </component>
    <component
      v-else
      :is="currentComponent"
      v-bind:bank-name="bankText"
      class="rounded-md hover:box-border hover:border-solid hover:border-1.5 hover:border-primary-main hover:cursor-pointer h-full py-4"
      @click.native="selectBank"
    >
    </component>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Azteca from '../../icons/Banks/Azteca.vue';
import Banamex from '../../icons/Banks/BanamexInvertedSmall.vue';
import Banorte from '../../icons/Banks/Banorte.vue';
import Bbva from '../../icons/Banks/BbvaInverted.vue';
import Bci from '../../icons/Banks/Bci.vue';
import Bci360 from '../../icons/Banks/Bci360.vue';
import BancoDeChileBiz from '../../icons/Banks/BancoDeChileBusiness.vue';
import BancoDeChileInd from '../../icons/Banks/BancoDeChileIndividual.vue';
import Bice from '../../icons/Banks/Bice.vue';
import Default from '../../icons/Banks/Default.vue';
import Estado from '../../icons/Banks/Estado.vue';
import Falabella from '../../icons/Banks/Falabella.vue';
import HeyBanco from '../../icons/Banks/HeyBanco.vue';
import Hsbc from '../../icons/Banks/Hsbc.vue';
import Itau from '../../icons/Banks/Itau.vue';
import Ripley from '../../icons/Banks/Ripley.vue';
import Santander from '../../icons/Banks/Santander.vue';
import SantanderPyme from '../../icons/Banks/SantanderPyme.vue';
import Scotiabank from '../../icons/Banks/Scotiabank.vue';
import Security from '../../icons/Banks/Security.vue';
import AnonimizedEntityButton from '../../layout/AnonimizedEntityButton.vue';

export default {
  props: {
    bankId: String,
    fullLength: Boolean,
  },
  computed: {
    ...mapState({
      anonimizedEntities: (state) => state.config.anonimizedEntities,
      holderType: (state) => state.config.holderType,
      banks: (state) => state.config.banks,
      entityIdSelected: (state) => state.widget.entityIdSelected,
    }),
    bankText() {
      switch (this.bankId) {
        case 'cl_banco_bci': {
          return 'Banco BCI';
        }
        case 'cl_banco_bci_360': {
          return 'Banco BCI 360';
        }
        case 'cl_banco_bice': {
          return 'Banco BICE';
        }
        case 'cl_banco_de_chile': {
          return 'Banco de Chile';
        }
        case 'cl_banco_estado': {
          return 'Banco Estado';
        }
        case 'cl_banco_itau': {
          return 'Banco Itau';
        }
        case 'cl_banco_santander': {
          return 'Banco Santander';
        }
        case 'cl_banco_santander_pyme': {
          return 'Banco Santander Pyme';
        }
        case 'cl_banco_scotiabank': {
          return 'Banco Scotiabank';
        }
        case 'cl_banco_security': {
          return 'Banco Security';
        }
        case 'mx_banco_banamex': {
          return 'Banco Banamex';
        }
        case 'mx_banco_bbva': {
          return 'Banco BBVA';
        }
        case 'mx_banco_azteca': {
          return 'Banco Azteca';
        }
        case 'cl_banco_falabella': {
          return 'Banco Falabella';
        }
        case 'cl_banco_ripley': {
          return 'Banco Ripley';
        }
        case 'mx_hey_banco': {
          return 'Hey Banco';
        }
        case 'mx_banco_santander': {
          return 'Banco Santander';
        }
        case 'mx_banco_hsbc': {
          return 'Banco HSBC';
        }
        case 'mx_banco_banorte': {
          return 'banorte';
        }
        default: {
          return '';
        }
      }
    },
    currentComponent() {
      if (this.anonimizedEntities) return 'anonimized-entity-button';
      switch (this.bankId) {
        case 'cl_banco_bci': {
          return 'bci';
        }
        case 'cl_banco_bci_360': {
          return 'bci-360';
        }
        case 'cl_banco_bice': {
          return 'bice';
        }
        case 'cl_banco_de_chile': {
          if (this.holderType === 'BUSINESS') return 'banco-de-chile-biz';
          return 'banco-de-chile-ind';
        }
        case 'cl_banco_estado': {
          return 'estado';
        }
        case 'cl_banco_falabella': {
          return 'falabella';
        }
        case 'cl_banco_itau': {
          return 'itau';
        }
        case 'cl_banco_ripley': {
          return 'ripley';
        }
        case 'cl_banco_santander': {
          return 'santander';
        }
        case 'cl_banco_santander_pyme': {
          return 'santander-pyme';
        }
        case 'cl_banco_scotiabank': {
          return 'scotiabank';
        }
        case 'cl_banco_security': {
          return 'security';
        }
        case 'mx_banco_banamex': {
          return 'banamex';
        }
        case 'mx_banco_bbva': {
          return 'bbva';
        }
        case 'mx_hey_banco': {
          return 'hey-banco';
        }
        case 'mx_banco_azteca': {
          return 'azteca';
        }
        case 'mx_banco_santander': {
          return 'santander';
        }
        case 'mx_banco_hsbc': {
          return 'hsbc';
        }
        case 'mx_banco_banorte': {
          return 'banorte';
        }
        default: {
          return '';
        }
      }
    },
  },
  methods: {
    ...mapActions(['updateBankSelected']),
    selectBank() {
      if (!this.entityIdSelected) {
        this.updateBankSelected({ bankId: this.bankId });
      }
    },
  },
  components: {
    Azteca,
    Banamex,
    Banorte,
    BancoDeChileBiz,
    BancoDeChileInd,
    Bice,
    Bci,
    Bci360,
    Default,
    Estado,
    Falabella,
    HeyBanco,
    Hsbc,
    Itau,
    Ripley,
    Santander,
    SantanderPyme,
    Scotiabank,
    Security,
    Bbva,
    AnonimizedEntityButton,
  },
};
</script>
