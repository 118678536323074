<template>
  <widget-layout>
    <div class="outer-container">
      <div class="container-rie">
        <EntityLogo />
        <Error class="frame-img min-w-14 min-h-14" backgroundColor="#FFF2E7" circleColor="#EA780E" />

        <div class="intro-2 mt-4">
          <div class="font-M font-700 color-txt-body">
            {{ textError }}
          </div>
          <div v-if="showSideTextError" class="font-S font-400 color-txt-body text-center marginLR-20">
            {{ 'Intenta más tarde' }}
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="true"
          text="Reintentar"
          @click.native="transition()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import EntityLogo from '../layout/EntityLogo.vue';
import Error from '../icons/Error.vue';

export default {
  methods: {
    ...mapActions(['transitionToNextState']),
    transition() {},
  },
  computed: {
    ...mapState({
      errorType: (state) => state.widget.errorType,
    }),
    textError() {
      if (this.errorType === constants.errors.INVALID_MFA) return 'El segundo factor ingresado no era correcto';
      return 'Tuvimos un problema conectándonos';
    },
    showSideTextError() {
      return this.errorType === constants.errors.REFRESH_INTENT_FAILED;
    },
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    EntityLogo,
    Error,
  },
};
</script>

<style scoped>

.frame-img {
  height: 56px;
}

.intro-2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 320px;
}

.container-rie {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px;
  margin: 0px 0px 45px;
  width: 100%;
  height: 100%;
  left: 0px;
}
</style>
