import axiosInstance from './axiosInstance';

function get({ publicKey, widgetToken }) {
  return axiosInstance
    .get('/internal/v1/refresh_intents/status', {
      headers: {
        Authorization: publicKey,
      },
      params: { widget_token: widgetToken },
    });
}

function send2FA({
  publicKey, widgetToken, secondFactor, idempotencyKey,
}) {
  return axiosInstance
    .patch('/internal/v1/refresh_intents/second_factor', {
      secondFactor,
      widget_token: widgetToken,
    }, {
      headers: {
        Authorization: publicKey,
        'Idempotency-Key': idempotencyKey,
      },
    });
}
export default {
  get,
  send2FA,
};
