<template>
  <widget-layout>
    <div class="outer-container px-4 justify-start space-y-8">
      <AuthInfo />
      <div class="flex flex-col w-full items-center space-y-4">
        <Timer :input-time="showTimeout" />
        <AuthAction :authType="card"/>
        <div class="flex flex-col">
          <div class="flex space-x-1.5">
            <div v-for="(key, index) in challenges" :key="key" class="w-full space-y-1.5">
              <div class="text-center w-full font-bold text-body-color"> {{ key }} </div>
              <input
                :class="`rounded-lg text-center h-10 border-border-color border-1.5 w-full font-bold text-body-color ${colorClasses(index)}`"
                type="tel"
                :ref="`input-${index}`"
                :data-index="index"
                v-model="values[index]"
                @input="handleKeyPress"
                @keyup="handleKeyup($event, index)"
                @focus="$event.target.select()"
                @keyup.enter="debounceSendCode"
              />
            </div>
          </div>
          <div class="min-h-4.5">
            <div class="px-1 flex mt-1.5 space-x-1 h-0" v-if="showError" data-test="errorHint">
              <ExclamationCircle class="mx-1.5 w-3.5 h-3.5 min-w-3.5 min-h-3.5 text-danger-main"/>
              <p class="text-body-color font-medium text-xs">Introduce solo números, ni letras ni símbolos</p>
            </div>
          </div>
        </div>
      </div>
      <AuthDescription />
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="(loading || !isCodeValid)"
          :text="btnText"
          @click.native="debounceSendCode"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';
import Debounce from '../../mixins/debounce';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import Timer from '../../lib/Timer.vue';
import constants from '../../../constants';
import PollingMixin from '../../mixins/polling';
import ValidationMixin from '../../mixins/validation';
import Footer from '../../layout/Footer.vue';
import ActionButton from '../../layout/ActionButton.vue';
import AuthInfo from '../AuthInfo.vue';
import AuthAction from '../AuthAction.vue';
import authTypes from '../../../../sharedTypes/authTypes';
import AuthDescription from '../AuthDescription.vue';
import optimizelyFlags from '../../../helpers/optimizely';
import ExclamationCircle from '../../icons/ExclamationCircle.vue';

export default {
  mixins: [PollingMixin, ValidationMixin, Debounce],
  data() {
    return {
      loading: false,
      values: ['', '', ''],
      idempotencyKey: '',
    };
  },
  computed: {
    ...mapGetters([
      'isSubscription',
      'isSubscriptionIntent',
      'isPayment',
      'isLinkIntent',
      'isRefreshIntent',
      'isBancoBBVA',
      'isBancoBanorte']),
    ...mapState({
      paymentStatus: (state) => state.payments.status,
      challenges: (state) => state.subscription.challenges || state.payments.challenges || state.link.challenges || state.refreshIntent.challenges || state.subscriptionIntents.challenges,
      product: (state) => state.config.product,
      stateContext: (state) => state.widget.currentStateContext,
      mfaConfig: (state) => state.config.mfaConfig,
    }),
    getPaymentBtnText() {
      const contactAuthText = 'Continuar';
      const paymentAuthText = 'Transferir';
      if (this.stateContext === constants.state_context.CONTACT_AUTH) {
        return contactAuthText;
      }
      if (this.stateContext === constants.state_context.PAYMENT_AUTH) {
        return paymentAuthText;
      }
      return paymentAuthText;
    },
    btnText() {
      if (this.isSubscription || this.isSubscriptionIntent) return 'Suscribir';
      if (this.isPayment) return this.getPaymentBtnText;
      if (this.isLinkIntent || this.isRefreshIntent) return 'Aceptar';
      return 'default';
    },
    showTimeout() {
      if (this.mfaConfig) {
        const { timeout } = this.mfaConfig.coordinates;
        return timeout;
      }
      return null;
    },
    isCodeValid() {
      return this.values.every((v) => (v.length === 2 && this.isNum(v)));
    },
    card() {
      return authTypes.CARD;
    },
    showError() {
      return this.values.some((v) => v && !this.isNum(v));
    },
  },
  methods: {
    ...mapActions([
      'transitionToNextState', 'sendSubscriptionSecondAuth', 'sendPaymentSecondAuth',
      'sendLinkIntentSecondAuth', 'sendRefreshIntentSecondAuth', 'setPaymentMfaCounter',
      'setContactMfaCounter', 'getPaymentStatus', 'sendSubscriptionIntentSecondAuth',
    ]),
    async sendSecondAuth(args) {
      if (this.isSubscription) {
        return this.sendSubscriptionSecondAuth(args);
      }
      if (this.isSubscriptionIntent) {
        return this.sendSubscriptionIntentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      if (this.isPayment) {
        return this.sendPaymentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      if (this.isLinkIntent) {
        return this.sendLinkIntentSecondAuth(args);
      }
      if (this.isRefreshIntent) {
        return this.sendRefreshIntentSecondAuth({ ...args, idempotencyKey: this.idempotencyKey });
      }
      throw new Error(`Second factor for product: ${this.product} unavailable.`);
    },
    debounceSendCode() {
      if (!this.isCodeValid) {
        return;
      }
      this.loading = true;
      this.trackMfaAttempted();
      this.debounce(this.sendCode);
    },
    async sendCode() {
      this.loading = true;
      try {
        await this.sendSecondAuth({ secondFactor: this.values });
        if (this.stateContext === constants.state_context.PAYMENT_AUTH) {
          this.setPaymentMfaCounter();
        } else if (this.stateContext === constants.state_context.CONTACT_AUTH) {
          this.setContactMfaCounter();
        }
        this.transitionToNextState({
          transition: constants.transitions.SECOND_FACTOR_SUCCEEDED,
        });
      } catch (error) {
        Sentry.captureException(error);
        if (error.__CANCEL__) {
          return;
        }

        this.transitionToFailed();
      }
      this.loading = false;
    },
    trackMfaAttempted() {
      if (this.isPayment) {
        optimizelyFlags.trackEvent('mfa_attempted');
      }
    },
    handleKeyPress(e) {
      const value = e.target.value.toUpperCase();
      const index = parseInt(e.target.dataset.index, 10);
      const maxlength = 2;
      this.values[index] = value;
      if (value.length >= maxlength) {
        e.preventDefault();
        this.values[index] = this.values[index].slice(0, 2);
        if (index < (this.challenges.length - 1)) {
          this.$refs[`input-${parseInt(index + 1, 10)}`][0].focus();
        }
      }
      if (value.length === 0) {
        if (index > 0) {
          this.$refs[`input-${parseInt(index - 1, 10)}`][0].focus();
        }
      }
    },
    handleKeyup(event, index) {
      this.handleInputFocus(event, index);
    },
    handleInputFocus(event, index) {
      if (this.isRightArrowKey(event.keyCode) && (!this.isLeftArrowKey(event.keyCode))) {
        this.$refs[`input-${parseInt(index + 1, 10)}`][0].focus();
      } else if ((this.isLeftArrowKey(event.keyCode) || this.isBackspaceOrDelete(event.keyCode)) && index > 0) {
        this.$refs[`input-${parseInt(index - 1, 10)}`][0].focus();
      }
    },
    transitionToFailed() {
      this.transitionToNextState({
        transition: constants.transitions.SECOND_FACTOR_FAILED,
      });
    },
    async requestPaymentsStatus() {
      try {
        await this.getPaymentStatus();
      } catch (error) {
        Sentry.captureException(error);
        this.transitionToFailed();
      }
    },
    colorClasses(inputIndex) {
      if (this.values[inputIndex] && !this.isNum(this.values[inputIndex])) {
        return 'border-danger-main focus:ring-danger-focus focus:ring focus:outline-none';
      }
      return 'hover:border-primary-main focus:ring-primary-focus focus:border-primary-main focus:ring';
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    if (this.isPayment) {
      this.startPolling(this.requestPaymentsStatus, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_RATE_SEC);
    }
  },
  mounted() {
    this.idempotencyKey = uuidv1();
  },
  watch: {
    paymentStatus(status) {
      if (status === 'failed') {
        this.transitionToFailed();
      }
      if (status === 'unknown') {
        this.transitionToFailed();
      }
      if (status === 'rejected') {
        this.transitionToFailed();
      }
    },
  },
  components: {
    Timer,
    WidgetLayout,
    ActionButton,
    Footer,
    AuthInfo,
    AuthAction,
    AuthDescription,
    ExclamationCircle,
  },
};
</script>
