<template>
  <div class="flex flex-col">
    <div class="px-3 flex flex-row space-x-2 items-center justify-center focus:outline-none active:outline-none">
      <input
        :class="`rounded-lg text-center h-12.5 border-border-color border-1.5 w-full focus:outline-none focus:ring ${colorClasses(index)}`"
        :style="{
          fontSize: '20px'
        }"
        :id="generateInputId(index)"
        :ref="generateInputId(index)"
        type="tel"
        maxlength="1"
        v-model="inputValues[index]"
        @keyup="handleKeyup($event, index)"
        @paste.prevent="handlePastedValues"
        @input="returnFullString()"
        @focus="$event.target.select()"
        @keyup.enter="onEnter"
        :key="index"
        v-for="(input, index) in length"
      />
    </div>
    <div class="min-h-4.5">
      <div class="px-3 flex mt-1.5 space-x-1 h-0" v-if="showError" data-test="errorHint">
        <ExclamationCircle class="mx-1.5 w-3.5 h-3.5 min-w-3.5 min-h-3.5 text-danger-main"/>
        <p class="text-body-color font-medium text-xs">Introduce solo números, ni letras ni símbolos</p>
      </div>
    </div>
  </div>
</template>

<script>
import ValidationMixin from '../mixins/validation';
import ExclamationCircle from '../icons/ExclamationCircle.vue';

export default {
  mixins: [ValidationMixin],
  props: {
    length: {
      type: Number,
      default: 6,
      required: false,
    },
    fontSize: {
      type: Number,
      default: 18,
      required: false,
    },
    fontColor: {
      type: String,
      default: '#444444',
      required: false,
    },
    allowPaste: {
      type: Boolean,
      default: true,
      required: false,
    },
    onlyNumber: {
      type: Boolean,
      default: true,
      required: false,
    },
  },
  data() {
    return {
      inputValues: [],
    };
  },
  computed: {
    showError() {
      const code = this.inputValues.join('');
      const isInvalid = code && !this.isNum(code);
      return this.onlyNumber ? isInvalid : false;
    },
  },
  methods: {
    handleKeyup(event, index) {
      this.handleInputFocus(event, index);
    },
    generateInputId(index) {
      return `fk_${index + 1}`;
    },
    handleInputFocus(event, index) {
      if (!this.isLeftArrowKey(event.keyCode) && this.inputValueExists(this.inputValues, index) && index < this.length - 1) {
        const [nextInput] = this.$refs[`fk_${index + 2}`];
        nextInput.focus();
      } else if ((this.isLeftArrowKey(event.keyCode) || this.isBackspaceOrDelete(event.keyCode)) && index > 0) {
        const [previousInput] = this.$refs[`fk_${index}`];
        previousInput.focus();
      }
    },
    handlePastedValues(event) {
      if (this.allowPaste) {
        const pastedValue = event.clipboardData.getData('text/plain');
        const splitValues = pastedValue.split('');
        for (let i = 0; i < this.length; i += 1) {
          this.updateInputValue(i, splitValues[i]);
        }
        const [lastInput] = this.$refs[`fk_${this.length}`];
        lastInput.focus();
        this.returnFullString();
      }
    },
    updateInputValue(index, value) {
      this.$set(this.inputValues, index, value);
    },
    returnFullString() {
      this.$emit('input', this.inputValues.join(''));
    },
    onEnter() {
      this.$emit('keyenter');
    },
    colorClasses(inputIndex) {
      if (this.inputValues[inputIndex] && !this.isNum(this.inputValues[inputIndex])) {
        return 'border-danger-main focus:ring-danger-focus';
      }
      return 'hover:border-primary-main focus:ring-primary-focus focus:border-primary-main';
    },
  },
  components: { ExclamationCircle },
};
</script>

<style scoped>
.inp::-webkit-outer-spin-button,
.inp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.inp {
  -moz-appearance: textfield;
}
</style>
