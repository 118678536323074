const WIDGET_CLOSED = 'WIDGET_CLOSED';
const WIDGET_CLOSED_FROM_CLIENT = 'WIDGET_CLOSED_FROM_CLIENT';
const WIDGET_CONFIGURATION_DONE = 'WIDGET_CONFIGURATION_DONE';
const WIDGET_CONFIGURATION_INVALID = 'WIDGET_CONFIGURATION_INVALID';
const WIDGET_CONFIGURATION_REQUEST = 'WIDGET_CONFIGURATION_REQUEST';
const WIDGET_EVENT = 'WIDGET_EVENT';
const WIDGET_OPENED = 'WIDGET_OPENED';
const WIDGET_READY = 'WIDGET_READY';
const WIDGET_SUCCEEDED = 'WIDGET_SUCCEEDED';
const WIDGET_TIMEOUT = 'WIDGET_TIMEOUT';

export default {
  WIDGET_CLOSED,
  WIDGET_CLOSED_FROM_CLIENT,
  WIDGET_CONFIGURATION_DONE,
  WIDGET_CONFIGURATION_INVALID,
  WIDGET_CONFIGURATION_REQUEST,
  WIDGET_EVENT,
  WIDGET_OPENED,
  WIDGET_READY,
  WIDGET_SUCCEEDED,
  WIDGET_TIMEOUT,
};
