import createBankLinkStateMachine from '../config/bank_link.machine';
import createFiscalLinkStateMachine from '../config/fiscal_link.machine';
import createSubscriptionStateMachine from '../config/subscriptions.machine';
import createPaymentsStateMachine from '../config/payments.machine';
import createRefreshIntentStateMachine from '../config/refresh_intent.machine';

import products from '../../sharedTypes/products';
import errors from '../errors';

export default function getProductStateMachine(product) {
  switch (product) {
    case products.MOVEMENTS:
      return createBankLinkStateMachine();
    case products.SUBSCRIPTION:
      return createSubscriptionStateMachine();
    case products.SUBSCRIPTION_INTENTS:
      return createSubscriptionStateMachine();
    case products.INVOICES:
      return createFiscalLinkStateMachine();
    case products.TAX_RETURNS:
      return createFiscalLinkStateMachine();
    case products.INCOME:
      return createFiscalLinkStateMachine();
    case products.TAX_STATEMENTS:
      return createFiscalLinkStateMachine();
    case products.PAYMENTS:
      return createPaymentsStateMachine();
    case products.REFRESH_INTENT:
      return createRefreshIntentStateMachine();
    default:
      throw new errors.ProductNotSupportedError(`Product ${product} not supported`);
  }
}
