<template>
  <div class="widget">
    <EntityForm v-if="isEntityForm" />
    <LoadingLink v-if="isLoading" />
    <LinkCreated v-if="isLinkCreated" />
    <LinkError v-if="isLinkError" />
    <ActionProcessing v-if="isProcessingAction" />

    <auth-app-required v-if="isAuthAppRequired" />
    <auth-card-required v-if="isAuthCardRequired" />
    <auth-captcha-required v-if="isAuthCaptchaRequired" />
    <auth-device-required v-if="isAuthDeviceRequired" />
    <auth-sms-required v-if="isAuthSmsRequired" />
    <auth-email-required v-if="isAuthEmailRequired" />

    <template v-if="isSubscriptionIntentsProduct">
      <SubscriptionIntentConfirmation v-if="isSubscriptionConfirmation" />
      <SubscriptionIntentProcessing v-if="isSubscriptionProcessing" />
      <SubscriptionIntentActionProcessing v-if="isSubscriptionProcessingAction" />
      <SubscriptionIntentSucceeded v-if="isSubscriptionCreated" />
      <SubscriptionIntentError v-if="isSubscriptionError" />
    </template>
    <template v-else>
      <SubscriptionCreated v-if="isSubscriptionCreated" />
      <SubscriptionProcessing v-if="isSubscriptionProcessing" />
      <SubscriptionActionProcessing v-if="isSubscriptionProcessingAction" />
      <SubscriptionConfirmation v-if="isSubscriptionConfirmation" />
      <SubscriptionError v-if="isSubscriptionError" />
      <SubscriptionAborted v-if="isSubscriptionAborted" />
    </template>

    <Home v-if="isHome" />
    <FinancialEntities v-if="isFinancialEntities" />
    <FiscalAuthorities v-if="isFiscalAuthorities" />

    <PaymentConfirmation v-if="isPaymentConfirmation" />
    <PaymentProcessing v-if="isPaymentProcessing" />
    <PaymentActionRequired v-if="false" />
    <PaymentActionProcessing v-if="isPaymentProcessingAction" />
    <PaymentCreated v-if="isPaymentCreated" />
    <PaymentError v-if="isPaymentError" />
    <PaymentSelectingAuth v-if="isPaymentSelectingAuth" />
    <PaymentInvalidDataForBank v-if="isPaymentInvalidDataForBank" />

    <RefreshIntentLoading v-if="isRefreshIntentLoading" />
    <RefreshIntentSucceeded v-if="isRefreshIntentSucceeded" />
    <RefreshIntentError v-if="isRefreshIntentError" />

    <NewPasswordHelper v-if="isNewPasswordHelper" />
    <ResetPasswordHelper v-if="isResetPasswordHelper" />
    <TokenHelper v-if="isTokenHelper" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AuthAppRequired from './widget-views/AuthAppRequired/AuthAppRequired.vue';
import AuthCardRequired from './widget-views/AuthCardRequired/AuthCardRequired.vue';
import AuthCaptchaRequired from './widget-views/AuthCaptchaRequired/AuthCaptchaRequired.vue';
import AuthDeviceRequired from './widget-views/AuthDeviceRequired/AuthDeviceRequired.vue';
import AuthSmsRequired from './widget-views/AuthSmsRequired/AuthSmsRequired.vue';
import AuthEmailRequired from './widget-views/AuthEmailRequired/AuthEmailRequired.vue';

import SubscriptionProcessing from './views.subscription/SubscriptionProcessing.vue';
import SubscriptionConfirmation from './views.subscription/SubscriptionConfirmation.vue';
import SubscriptionActionProcessing from './views.subscription/SubscriptionActionProcessing.vue';
import SubscriptionCreated from './views.subscription/SubscriptionCreated.vue';
import SubscriptionError from './views.subscription/SubscriptionError.vue';
import SubscriptionAborted from './views.subscription/SubscriptionAborted.vue';

import SubscriptionIntentConfirmation from './views.subscription_intent/SubscriptionIntentConfirmation.vue';
import SubscriptionIntentProcessing from './views.subscription_intent/SubscriptionIntentProcessing.vue';
import SubscriptionIntentActionProcessing from './views.subscription_intent/SubscriptionIntentActionProcessing.vue';
import SubscriptionIntentSucceeded from './views.subscription_intent/SubscriptionIntentSucceeded.vue';
import SubscriptionIntentError from './views.subscription_intent/SubscriptionIntentError.vue';

import PaymentActionRequired from './views.payment/PaymentActionRequired.vue';
import PaymentActionProcessing from './views.payment/PaymentActionProcessing.vue';
import PaymentCreated from './views.payment/PaymentCreated.vue';
import PaymentConfirmation from './views.payment/PaymentConfirmation.vue';
import PaymentProcessing from './views.payment/PaymentProcessing.vue';
import PaymentError from './views.payment/PaymentError.vue';
import PaymentSelectingAuth from './views.payment/PaymentSelectingAuth.vue';
import PaymentInvalidDataForBank from './views.payment/PaymentInvalidDataForBank.vue';

import RefreshIntentLoading from './views.refresh_intent/RefreshIntentLoading.vue';
import RefreshIntentSucceeded from './views.refresh_intent/RefreshIntentSucceeded.vue';
import RefreshIntentError from './views.refresh_intent/RefreshIntentError.vue';

import Home from './views.common/Home/Home.vue';
import FinancialEntities from './views.common/FinancialEntities/FinancialEntities.vue';
import FiscalAuthorities from './views.common/FinancialEntities/FiscalAuthorities.vue';
import EntityForm from './views.common/EntityForm/EntityForm.vue';
import LoadingLink from './views.common/LoadingLink/LoadingLink.vue';
import LinkError from './views.common/LinkError/LinkError.vue';
import LinkCreated from './views.common/LinkCreated/LinkCreated.vue';
import ActionProcessing from './views.common/ActionProcessing/ActionProcessing.vue';

import products from '../../sharedTypes/products';
import constants from '../constants';
import NewPasswordHelper from './views.common/EntityForm/helpers/NewPasswordHelper.vue';
import ResetPasswordHelper from './views.common/EntityForm/helpers/ResetPasswordHelper.vue';
import TokenHelper from './widget-views/AuthDeviceRequired/TokenHelper.vue';
import Hotjar from '../helpers/hotjar';

export default {
  computed: {
    ...mapState({
      product: (state) => state.config.product,
      currentState: (state) => state.widget.currentState,
      hotjarEnabled: (state) => state.config.hotjarEnabled,
      organizationId: (state) => state.config.organizationId,
    }),
    isSubscriptionIntentsProduct() {
      return this.product === products.SUBSCRIPTION_INTENTS;
    },
    isHome() {
      return this.currentState === constants.states.INTRODUCTION;
    },
    isFinancialEntities() {
      return this.currentState === constants.states.BANK_SELECTION;
    },
    isFiscalAuthorities() {
      return this.currentState === constants.states.FISCAL_AUTHORITIES;
    },
    isEntityForm() {
      return this.currentState === constants.states.LOGIN;
    },
    isLoading() {
      return this.currentState === constants.states.LOADING_LINK_INTENT;
    },
    isLinkCreated() {
      return this.currentState === constants.states.LINK_CREATED;
    },
    isLinkError() {
      return this.currentState === constants.states.ERROR_VIEW;
    },
    isAuthAppRequired() {
      return this.currentState === constants.states.APP_AUTH_REQUIRED;
    },
    isAuthCardRequired() {
      return this.currentState === constants.states.CARD_AUTH_REQUIRED;
    },
    isAuthCaptchaRequired() {
      return this.currentState === constants.states.CAPTCHA_AUTH_REQUIRED;
    },
    isAuthDeviceRequired() {
      return this.currentState === constants.states.DEVICE_AUTH_REQUIRED;
    },
    isAuthSmsRequired() {
      return this.currentState === constants.states.SMS_AUTH_REQUIRED;
    },
    isAuthEmailRequired() {
      return this.currentState === constants.states.EMAIL_AUTH_REQUIRED;
    },
    isSubscriptionProcessing() {
      return this.currentState === constants.states.LOADING_SUBSCRIPTION;
    },
    isPaymentProcessing() {
      return this.currentState === constants.states.PROCESSING_PAYMENTS;
    },
    isSubscriptionConfirmation() {
      return this.currentState === constants.states.SUBSCRIPTION_CONFIRMATION_REQUIRED;
    },
    isPaymentConfirmation() {
      return this.currentState === constants.states.PAYMENTS_CONFIRMATION_REQUIRED;
    },
    isSubscriptionCreated() {
      return this.currentState === constants.states.SUBSCRIPTION_CREATED;
    },
    isSubscriptionError() {
      return this.currentState === constants.states.SUBSCRIPTION_ERROR;
    },
    isSubscriptionAborted() {
      return this.currentState === constants.states.SUBSCRIPTION_ABORTED;
    },
    isPaymentCreated() {
      return this.currentState === constants.states.PAYMENTS_CREATED;
    },
    isPaymentProcessingAction() {
      return this.currentState === constants.states.PAYMENTS_PROCESSING_ACTION;
    },
    isPaymentError() {
      return this.currentState === constants.states.PAYMENTS_ERROR;
    },
    isPaymentSelectingAuth() {
      return this.currentState === constants.states.PAYMENTS_SELECTING_AUTH;
    },
    isPaymentInvalidDataForBank() {
      return this.currentState === constants.states.PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK;
    },
    isSubscriptionProcessingAction() {
      return this.currentState === constants.states.SUBSCRIPTION_PROCESSING_ACTION;
    },
    isProcessingAction() {
      return this.currentState === constants.states.LINK_INTENT_PROCESSING_ACTION
        || this.currentState === constants.states.REFRESH_INTENT_PROCESSING_ACTION;
    },
    isRefreshIntentLoading() {
      return this.currentState === constants.states.REFRESH_INTENT_CREATED
        || this.currentState === constants.states.REFRESH_INTENT_FETCHING_DATA;
    },
    isRefreshIntentSucceeded() {
      return this.currentState === constants.states.REFRESH_INTENT_SUCCEEDED;
    },
    isRefreshIntentError() {
      return this.currentState === constants.states.REFRESH_INTENT_ERROR;
    },
    isNewPasswordHelper() {
      return this.currentState === constants.states.NEW_PASSWORD_HELPER;
    },
    isResetPasswordHelper() {
      return this.currentState === constants.states.RESET_PASSWORD_HELPER;
    },
    isTokenHelper() {
      return this.currentState === constants.states.TOKEN_HELPER;
    },
  },
  mounted() {
    if (this.hotjarEnabled) {
      Hotjar.start();
    }
  },
  components: {
    AuthAppRequired,
    AuthCardRequired,
    AuthCaptchaRequired,
    AuthDeviceRequired,
    AuthSmsRequired,
    AuthEmailRequired,
    Home,
    FinancialEntities,
    FiscalAuthorities,
    EntityForm,
    LinkCreated,
    LinkError,
    LoadingLink,
    SubscriptionConfirmation,
    SubscriptionCreated,
    SubscriptionProcessing,
    SubscriptionActionProcessing,
    SubscriptionError,
    SubscriptionAborted,
    SubscriptionIntentConfirmation,
    SubscriptionIntentProcessing,
    SubscriptionIntentActionProcessing,
    SubscriptionIntentSucceeded,
    SubscriptionIntentError,
    PaymentConfirmation,
    PaymentCreated,
    PaymentActionProcessing,
    PaymentActionRequired,
    PaymentProcessing,
    PaymentSelectingAuth,
    PaymentError,
    PaymentInvalidDataForBank,
    ActionProcessing,
    RefreshIntentLoading,
    RefreshIntentSucceeded,
    RefreshIntentError,
    NewPasswordHelper,
    ResetPasswordHelper,
    TokenHelper,
  },
};
</script>

<style>
.widget {
  max-width: 360px;
  min-width: 280px;
  max-height: 680px;
  width: 100vw;
  height: 100%;
  overflow-x: hidden;
}
</style>
