import constants from '../constants';
import StateMachine from '../helpers/state-machine';

const { states, transitions } = constants;

export default function createBankLinkStateMachine() {
  return new StateMachine({
    id: 'bank-link-state-machine',
    initial: states.INTRODUCTION,
    states: {
      [states.INTRODUCTION]: {
        on: {
          [transitions.OPEN_BANKS]: states.BANK_SELECTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Conéctate a tu banco',
          close: true,
        },
        body: {
          institution: 'tu banco',
          privacyText: 'Tus credenciales no serán compartidas con nadie.',
        },
      },
      [states.BANK_SELECTION]: {
        on: {
          [transitions.SELECT_BANK]: states.LOGIN,
          [transitions.GO_BACK]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: true,
          title: 'Selecciona tu banco',
          close: true,
        },
      },
      [states.LOGIN]: {
        on: {
          [transitions.LINK_INTENT_CREATE_SUCCEEDED]: states.LOADING_LINK_INTENT,
          [transitions.LINK_INTENT_CREATE_FAILED]: states.ERROR_VIEW,
          [transitions.GO_BACK]: states.BANK_SELECTION,
          [transitions.GO_HOME]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.OPEN_NEW_PASSWORD_HELPER]: states.NEW_PASSWORD_HELPER,
          [transitions.OPEN_RESET_PASSWORD_HELPER]: states.RESET_PASSWORD_HELPER,
        },
        header: {
          back: true,
          title: 'Ingresa tus credenciales',
          close: true,
        },
      },
      [states.NEW_PASSWORD_HELPER]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.LOGIN,
        },
        header: {
          back: true,
          title: 'Crear contraseña web',
          close: true,
          hideSeparator: true,
        },
      },
      [states.RESET_PASSWORD_HELPER]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.LOGIN,
        },
        header: {
          back: true,
          title: 'Recuperar contraseña web',
          close: true,
          hideSeparator: true,
        },
      },
      [states.LOADING_LINK_INTENT]: {
        on: {
          [transitions.LINK_INTENT_SUCCESSFUL]: states.LINK_CREATED,
          [transitions.LINK_INTENT_FAILED]: states.ERROR_VIEW,
          [transitions.LINK_INTENT_APP_AUTH_REQUIRED]: states.APP_AUTH_REQUIRED,
          [transitions.LINK_INTENT_CAPTCHA_AUTH_REQUIRED]: states.CAPTCHA_AUTH_REQUIRED,
          [transitions.LINK_INTENT_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.LINK_INTENT_CARD_AUTH_REQUIRED]: states.CARD_AUTH_REQUIRED,
          [transitions.LINK_INTENT_SMS_AUTH_REQUIRED]: states.SMS_AUTH_REQUIRED,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Conectándonos',
          close: true,
        },
      },
      [states.APP_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_SUCCEEDED]: states.LINK_CREATED,
          [transitions.WAITING_ACTION_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.CAPTCHA_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.ERROR_VIEW,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.LINK_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.SECOND_FACTOR_FAILED]: states.ERROR_VIEW,
          [transitions.GO_BACK]: states.BANK_SELECTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Ingreso de token',
          close: true,
        },
      },
      [states.DEVICE_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.ERROR_VIEW,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.LINK_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.OPEN_TOKEN_HELPER]: states.TOKEN_HELPER,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.TOKEN_HELPER]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.DEVICE_AUTH_REQUIRED,
          [transitions.GO_BACK]: states.DEVICE_AUTH_REQUIRED,
        },
        header: {
          back: true,
          title: '¿Dónde encuentro el token?',
          close: true,
        },
      },
      [states.CARD_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.ERROR_VIEW,
          [transitions.LINK_INTENT_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.LINK_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.ERROR_VIEW,
          [transitions.GO_BACK]: states.BANK_SELECTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.SMS_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.ERROR_VIEW,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.LINK_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.LINK_INTENT_PROCESSING_ACTION]: {
        on: {
          [transitions.LINK_INTENT_SUCCESSFUL]: states.LINK_CREATED,
          [transitions.PROCESSING_ACTION_SUCCEEDED]: states.LOADING_LINK_INTENT,
          [transitions.PROCESSING_ACTION_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Procesando segundo factor',
          close: true,
        },
      },
      [states.LINK_CREATED]: {
        type: 'final',
        header: {
          back: false,
          title: 'Conexión exitosa',
          close: false,
        },
      },
      [states.ERROR_VIEW]: {
        on: {
          [transitions.RETRY_LINK_INTENT]: states.LOGIN,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Tuvimos un problema',
          close: true,
        },
      },
      [states.WIDGET_CLOSED]: {
        type: 'final',
        header: {
          back: false,
          title: '',
          close: false,
        },
      },
    },
  });
}
