/* eslint-disable max-classes-per-file */
class InvalidTransition extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidTransition';
  }
}

class ProductNotSupportedError extends Error {
  constructor(message) {
    super(message);
    this.name = 'ProductNotSupportedError';
  }
}

class StateNotInStateMachine extends Error {
  constructor(message) {
    super(message);
    this.name = 'StateNotInStateMachine';
  }
}

class InvalidSelectedInstitution extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidSelectedInstitution';
    this.code = 'invalid_selected_institution';
  }
}

export default {
  InvalidTransition,
  ProductNotSupportedError,
  StateNotInStateMachine,
  InvalidSelectedInstitution,
};
