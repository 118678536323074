<template>
  <div class="mt-4 flex items-start static rounded-md p-2 bg-primary-surface text-primary-main w-full">
    <ShieldHalf class="w-4 min-w-4 h-4 min-h-4 ml-1 mr-2"/>
    <div class="text-heading-color text-sm">
      {{ text }}
    </div>
  </div>
</template>

<script>
import ShieldHalf from '../../icons/ShieldHalf.vue';

export default {
  components: {
    ShieldHalf,
  },
  props: {
    isPayments: Boolean,
  },
  computed: {
    text() {
      if (this.isPayments) {
        return 'Los datos son encriptados con TLS 1.2 y se utilizarán para pagar esta única vez.';
      }
      return 'Los datos son encriptados con TLS 1.2.';
    },
  },
};
</script>
