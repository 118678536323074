<template>
  <widget-layout :goBackFunc="prevStep">
    <div class="step-container">
      <div class="justify-self-auto" v-for="step in Array(totalSteps).keys()" v-bind:key="step">
        <div :class="currentStep >= step + 1 ? 'step bg-primary-main': 'step'" />
        <div class="step-label text-primary-main" v-if="currentStep === step + 1">Paso {{step + 1}}</div>
      </div>
    </div>

    <div v-if="currentStep === 0" class="flex flex-col items-center">
      <Warning class="mt-4 mb-8" />
      <p class="font-M font-700 color-txt-heading text-center">¡Hola!</p>
      <p class="font-M font-700 color-txt-heading text-center mx-4 mb-2">Para comenzar debes tener a tu disposición dos celulares</p>
      <img :src="require('@/assets/images/reset_password_helper0.svg')" v-if="currentStep === 0"/>
      <p class="text-instructions">Tu celular con la app BBVA abierta, y otro con la página web BBVA abierta, ya que necesitarás que la app y la web interactúen.</p>
    </div>

    <div class="text-instructions" v-if="currentStep === 1">
      <p>1- Desde el otro celular ingresa a <a href="https://www.bbva.mx" class="font-bold text-primary-main" target="_blank">https://www.bbva.mx</a></p>
      <p>2- Haz click en <span class="font-bold italic">Acceso</span> que está en la parte de arriba</p>
    </div>

    <div class="text-instructions" v-if="currentStep === 2">
      <p>Ingresa tu <strong>Número de tarjeta de débito</strong> y presiona <span class="font-bold italic">Continuar</span></p>
    </div>

    <div class="text-instructions" v-if="currentStep === 3">
      <p>Haz click en <span class="font-bold italic">¿Olvidaste tu contraseña?</span></p>
    </div>

    <div class="text-instructions" v-if="currentStep === 4">
      <p>Ingresa el <span class="font-bold">NIP del cajero</span> de tu tarjeta y haz click en <span class="font-bold italic">Continuar</span></p>
    </div>

    <div class="text-instructions" v-if="currentStep === 5">
      <p>Ingresa <span class="font-bold">una nueva contraseña</span></p>
    </div>

    <div class="text-instructions" v-if="currentStep === 6">
      <p>1 - Con tu celular abre tu <span class="font-bold">app BBVA</span></p>
      <p>2 - Haz click en <span class="font-bold italic">Token móvil</span></p>
      <p>3 - Haz click en <span class="font-bold italic">Usar lector óptico</span> se utilizará para escanear un QR</p>

    </div>

    <div class="text-instructions" v-if="currentStep === 7">
      <p>1 - Vuelve al otro celular con la web abierta</p>
      <p>2 - Escanea el QR con el lector óptico app BBVA</p>
      <p>3 - Ingresa el token que entrega la app BBVA</p>
      <p>4- Haz click en <span class="font-bold italic">Continuar</span></p>
    </div>

    <div v-if="currentStep === 8" class="flex flex-col items-center">
      <EntityLogo />
      <CheckVerified class="mb-8" />
      <p class="font-M font-700 color-txt-heading text-center">¡Ya recuperaste tu clave!</p>
      <p class="text-instructions">
        Presiona <span class="font-bold italic">¡Listo!</span> e
        ingresa tu nueva contraseña web para poder conectar tu banco con {{ organizationName }}
      </p>
      <InfoWarning
        class="mx-6"
        subtitle1="Si es que no funcionó por favor comunícate directamente con el banco para solucionarlo"
      />
    </div>

    <div class="-mb-5">
      <img :src="require('@/assets/images/reset_password_helper1.png')" v-if="currentStep === 1" class="-mb-12"/>
      <img :src="require('@/assets/images/reset_password_helper2.png')" v-if="currentStep === 2"/>
      <img :src="require('@/assets/images/reset_password_helper3.png')" v-if="currentStep === 3"/>
      <img :src="require('@/assets/images/reset_password_helper4.png')" v-if="currentStep === 4"/>
      <img :src="require('@/assets/images/reset_password_helper5.png')" v-if="currentStep === 5"/>
      <div class="flex flex-row mt-8 items-center justify-center gap-2" v-if="currentStep === 6">
        <img :src="require('@/assets/images/reset_password_helper6a.svg')"/>
        <img :src="require('@/assets/images/reset_password_helper6b.png')"/>
      </div>
      <img :src="require('@/assets/images/reset_password_helper6c.png')" v-if="currentStep === 6"/>
      <img :src="require('@/assets/images/reset_password_helper7.png')" class=" -mb-8" v-if="currentStep === 7"/>
    </div>

    <div class="buttons-container">
      <ActionButton
        class="w-full"
        :text="mainButtonText"
        @click.native="nextStep"
      />
    </div>
  </widget-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WidgetLayout from '../../../layout/WidgetLayout.vue';
import ActionButton from '../../../layout/ActionButton.vue';
import constants from '../../../../constants';
import Warning from '../../../icons/Warning.vue';
import CheckVerified from '../../../icons/CheckVerified.vue';
import InfoWarning from '../../InfoWarning.vue';
import EntityLogo from '../../../layout/EntityLogo.vue';

export default {
  data() {
    return {
      currentStep: 0,
      totalSteps: 7,
    };
  },
  computed: {
    ...mapState({
      organizationName: (state) => state.config.organizationName,
    }),
    mainButtonText() {
      if (this.currentStep === 8) return '¡Listo!';
      return 'Siguiente';
    },
  },
  methods: {
    closeHelper() {
      this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
    },
    nextStep() {
      if (this.currentStep === this.totalSteps + 1) { this.closeHelper(); }
      this.currentStep += 1;
    },
    prevStep() {
      if (this.currentStep === 0) this.closeHelper();
      this.currentStep -= 1;
    },
    ...mapActions(['transitionToNextState']),
  },
  components: {
    WidgetLayout,
    ActionButton,
    Warning,
    CheckVerified,
    InfoWarning,
    EntityLogo,
  },
};
</script>,
<style>

.step-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;

  position: sticky;
  position: -webkit-sticky; /* Safari */
  width: 360px;
  height: 21px;
  left: 0px;
  top: 0px;
  background: white;
  z-index: 10;
}
.step {
  position: static;
  width: 48px;
  height: 3px;
  left: 0px;
  top: 0px;
  background: #E2E1E9;
  z-index: 1;
}

.step-label{
font-family: Inter;
font-style: normal;
font-weight: bold;
font-size: 12px;
text-align: center;
}
.text-instructions {
  width: 320px;
  margin-top: 8px;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  letter-spacing: -0.006em;

  color: #4A4672
}

.buttons-container {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  margin-top: auto;
  position: relative;
  width: 100vw;
  max-width: 360px;
  height: auto;
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 20px;
  z-index: 30;
  align-items: center;
  flex-direction: column;
}

</style>
