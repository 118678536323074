const INVOICES = 'INVOICES';
const MOVEMENTS = 'MOVEMENTS';
const SUBSCRIPTION = 'SUBSCRIPTION';
const SUBSCRIPTION_INTENTS = 'SUBSCRIPTIONS';
const TAX_RETURNS = 'TAX_RETURNS';
const INCOME = 'INCOME';
const PAYMENTS = 'PAYMENTS';
const REFRESH_INTENT = 'REFRESH_INTENT';
const TAX_STATEMENTS = 'TAX_STATEMENTS';

export default {
  INVOICES,
  MOVEMENTS,
  SUBSCRIPTION,
  SUBSCRIPTION_INTENTS,
  TAX_RETURNS,
  INCOME,
  PAYMENTS,
  REFRESH_INTENT,
  TAX_STATEMENTS,
};
