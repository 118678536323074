<template>
  <Drawer :showDrawer="showFintocDrawer" @close="closeDrawer">
    <div class="h-full pt-8 pb-4 flex flex-col justify-between">
      <div class="flex flex-col items-center space-y-8 overflow-y-scroll px-4" ref="mainContentContainer" @scroll="onScroll">
        <div class="flex flex-col items-center space-y-2 text-center">
          <p class="text-heading-color font-bold text-lg">¿Qué es Fintoc?</p>
          <p class="text-body-color font-medium text-lg">Somos la manera fácil y segura para pagar por transferencias in-app</p>
          <p class="text-body-color font-medium text-xs">Más de 1 millón de personas han pagado con Fintoc</p>
        </div>
        <div class="flex flex-col items-center space-y-4">
          <div class="flex flex-col items-left space-y-0.5">
            <p class="text-heading-color font-bold">Fácil de usar</p>
            <p class="text-body-color font-medium">No tienes que copiar y pegar números y datos para transferir.</p>
          </div>
          <div class="flex flex-col items-left space-y-0.5">
            <p class="text-heading-color font-bold">Seguro para todos</p>
            <p class="text-body-color font-medium">Tus datos van encriptados de extremo a extremo usando TLS 1.2.</p>
          </div>
          <div class="flex flex-col items-left space-y-0.5">
            <p class="text-heading-color font-bold">Solo tú tienes acceso a tus datos de login</p>
            <p class="text-body-color font-medium">{{organizationName}} no tiene acceso a tus datos.</p>
          </div>
        </div>
      </div>
      <div class="px-5 pt-3 w-full" :class="{'shadow': canScrollToBottom}">
        <ActionButton @click.native="closeDrawer" text="Cerrar"/>
      </div>
    </div>
  </Drawer>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import StylesMixin from '../mixins/styles';
import ActionButton from './ActionButton.vue';
import Drawer from './Drawer.vue';

export default {
  mixins: [StylesMixin],
  computed: {
    ...mapState({
      organizationName: (state) => state.config.organizationName,
      showFintocDrawer: (state) => state.widget.showFintocDrawer,
    }),
  },
  methods: {
    ...mapActions(['toggleFintocDrawer']),
    closeDrawer() {
      this.toggleFintocDrawer();
    },
  },
  components: { Drawer, ActionButton },
};
</script>
