<template>
  <div
    class="absolute w-full h-full overflow-hidden"
    :class="isPrimaryMainFintoc ? 'bg-heading-color opacity-50' : 'bg-border-color opacity-60'"
  />
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['isPrimaryMainFintoc']),
  },
};
</script>
