<template>
  <widget-layout>
    <div class="outer-container">
      <div class="container-lc space-y-8 h-full justify-center">
        <EntityLogo />
        <CheckVerified class="frame-img check-background"/>

        <div class="intro-lc">
          <div class="font-M font-700 color-txt-heading text-center marginLR-20">
            {{ textHeading }}
          </div>
          <div class="font-S font-400 color-txt-body text-center marginLR-20">
            {{ textBody }}
          </div>
        </div>
      </div>

    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="¡Listo!"
          data-cy="close-widget"
          @click.native="widgetFinished()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import ActionButton from '../../layout/ActionButton.vue';
import Footer from '../../layout/Footer.vue';
import EntityLogo from '../../layout/EntityLogo.vue';
import CheckVerified from '../../icons/CheckVerified.vue';

export default {
  computed: {
    ...mapState({
      organizationName: (state) => state.config.organizationName,
      entityId: (state) => state.widget.entityIdSelected || state.refreshIntent.institutionId,
    }),
    ...mapGetters(['entityName']),
    textHeading() {
      if (this.entityId === 'mx_banco_bbva') {
        return `${this.organizationName} se ha conectado exitosamente con tus datos`;
      }
      return 'Conexión exitosa';
    },
    textBody() {
      if (this.entityId === 'mx_banco_bbva') {
        return '';
      }
      return `Se ha logrado conectar exitosamente con ${this.entityName}`;
    },
  },
  methods: {
    ...mapActions(['widgetFinished']),
  },
  components: {
    ActionButton,
    EntityLogo,
    Footer,
    WidgetLayout,
    CheckVerified,
  },
};
</script>

<style scoped>

.frame-img {
  height: 56px;
}

.intro-lc {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  margin-top: 16px;
  max-width: 360px;
  width: 100vw;
}

.container-lc {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px 45px 0px;
  padding-bottom: 20px;
  width: 100%;
}

</style>
