<template>
<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path v-if="crossedOut" opacity="0.3" d="M12.9644 15.97L11.2495 14.254C10.5863 14.4911 9.86947 14.535 9.18232 14.3807C8.49517 14.2263 7.866 13.8799 7.368 13.3819C6.87 12.8839 6.52365 12.2547 6.36926 11.5676C6.21486 10.8804 6.25877 10.1636 6.49588 9.50041L4.30712 7.31166C2.49662 8.92135 1.5 10.751 1.5 10.751C1.5 10.751 4.6875 16.5947 10 16.5947C11.0204 16.5912 12.0293 16.3786 12.9644 15.97V15.97ZM7.03563 5.53198C7.97068 5.12337 8.97957 4.91074 10 4.90723C15.3125 4.90723 18.5 10.751 18.5 10.751C18.5 10.751 17.5023 12.5795 15.6939 14.1914L13.5031 12.0005C13.7402 11.3373 13.7841 10.6204 13.6297 9.9333C13.4753 9.24615 13.1289 8.61697 12.6309 8.11897C12.1329 7.62097 11.5038 7.27463 10.8166 7.12023C10.1295 6.96584 9.41261 7.00975 8.74944 7.24685L7.03563 5.53304V5.53198Z" fill="#1A1A1A"/>
<path v-if="crossedOut" opacity="0.3" d="M16 16.7951L3.95711 4.75225L4.00225 4.70711L16.0451 16.75L16 16.7951Z" fill="#1A1A1A" stroke="black"/>
<path v-if="crossedOut" opacity="0.3" d="M7.36676 10.5217C7.30832 10.93 7.34577 11.3463 7.47616 11.7377C7.60655 12.129 7.82629 12.4846 8.11796 12.7763C8.40964 13.0679 8.76524 13.2877 9.15658 13.4181C9.54792 13.5485 9.96425 13.5859 10.3726 13.5275L7.3657 10.5217H7.36676ZM12.6261 11.2739L9.62033 8.26703C10.0287 8.20859 10.445 8.24604 10.8363 8.37643C11.2277 8.50682 11.5833 8.72656 11.8749 9.01823C12.1666 9.30991 12.3864 9.6655 12.5167 10.0568C12.6471 10.4482 12.6846 10.8645 12.6261 11.2728V11.2739Z" fill="#1A1A1A"/>
<path v-if="!crossedOut" opacity="0.3" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 10.7437C1.5 10.7437 4.6875 4.8999 10 4.8999C15.3125 4.8999 18.5 10.7437 18.5 10.7437C18.5 10.7437 15.3125 16.5874 10 16.5874C4.6875 16.5874 1.5 10.7437 1.5 10.7437ZM10 14.4624C10.9863 14.4624 11.9322 14.0706 12.6296 13.3732C13.327 12.6758 13.7188 11.7299 13.7188 10.7437C13.7188 9.75738 13.327 8.8115 12.6296 8.1141C11.9322 7.4167 10.9863 7.0249 10 7.0249C9.01373 7.0249 8.06785 7.4167 7.37045 8.1141C6.67305 8.8115 6.28125 9.75738 6.28125 10.7437C6.28125 11.7299 6.67305 12.6758 7.37045 13.3732C8.06785 14.0706 9.01373 14.4624 10 14.4624V14.4624Z" fill="#1A1A1A"/>
<path v-if="!crossedOut" opacity="0.3" d="M12.6525 10.8965C12.6525 11.601 12.3726 12.2766 11.8745 12.7747C11.3763 13.2729 10.7007 13.5527 9.99622 13.5527C9.29173 13.5527 8.61611 13.2729 8.11796 12.7747C7.61982 12.2766 7.33997 11.601 7.33997 10.8965C7.33997 10.192 7.61982 9.51638 8.11796 9.01823C8.61611 8.52009 9.29173 8.24023 9.99622 8.24023C10.7007 8.24023 11.3763 8.52009 11.8745 9.01823C12.3726 9.51638 12.6525 10.192 12.6525 10.8965V10.8965Z" fill="#1A1A1A"/>
</svg>
</template>

<script>
export default {
  name: 'Eye',
  props: {
    crossedOut: Boolean,
  },
};
</script>
