const WEBHOOK_URL = 'https://webhook.site/aa51b9ee-cfe9-4c6a-87bd-3f905d053620';

const PUBLIC_KEY_TEST_DEV = 'pk_test_iiT7EYCUs94sBvpKBpnVjwmwiSK_eYVpuP_qfG3XYTAGGBwXC7wHm1yBbbheMc-7';
const PUBLIC_KEY_LIVE_DEV = 'pk_live_k_2rvZ5aPrGLi6dbPmRcdfxtW_UT2yq5WxVz3psGB1DZ3Hoxfre_haSaBG9GhrQH';
const PUBLIC_KEY_TEST_STG = 'pk_test_Mkzri47eXum3up1XKyju6QnxbQdFVxqB';
const PUBLIC_KEY_LIVE_STG = 'pk_live_SZndmXKfyT96cjoQq9XukQs8zRuo-dHm';

const PAYMENTS_WIDGET_TOKEN = 'pi_ymRxZd7OS2dqKODg_sec_tDCY3Z3qy322d5Svgq1PBU9o';

export default {
  WEBHOOK_URL,
  PUBLIC_KEY_TEST_DEV,
  PUBLIC_KEY_LIVE_DEV,
  PAYMENTS_WIDGET_TOKEN,
  PUBLIC_KEY_TEST_STG,
  PUBLIC_KEY_LIVE_STG,
};
