import { mapState } from 'vuex';
import constants from '../../constants';

function filterAllowedAccounts(accounts) {
  const allowedAccountTypes = [
    'checking_account', 'sight_account',
  ];
  const allowedCurrency = 'CLP';
  return accounts
    .filter((account) => allowedAccountTypes.includes(account.type) && account.currency === allowedCurrency);
}

function sortAccountsByBalance(accounts) {
  return accounts.sort((a, b) => b.balance.limit - a.balance.limit);
}

export default {
  computed: {
    ...mapState({
      paymentMinimumAmount: (state) => state.config.paymentMinimumAmount,
      paymentAmount: (state) => state.payments.amount,
    }),
  },
  methods: {
    async transitionToPayment2FA({ stateContext }) {
      switch (this.secondFactorType) {
        case 'enter_device_code': {
          this.transitionToNextState({
            transition: constants.transitions.PAYMENTS_DEVICE_AUTH_REQUIRED,
            stateContext,
          });
          break;
        }
        case 'authorize_in_app': {
          this.transitionToNextState({
            transition: constants.transitions.PAYMENTS_APP_AUTH_REQUIRED,
            stateContext,
          });
          break;
        }
        case 'enter_sms_code': {
          this.transitionToNextState({
            transition: constants.transitions.PAYMENTS_SMS_AUTH_REQUIRED,
            stateContext,
          });
          break;
        }
        case 'enter_coordinates': {
          this.transitionToNextState({
            transition: constants.transitions.PAYMENTS_CARD_AUTH_REQUIRED,
            stateContext,
          });
          break;
        }
        case 'enter_email_code': {
          this.transitionToNextState({
            transition: constants.transitions.PAYMENTS_EMAIL_AUTH_REQUIRED,
            stateContext,
          });
          break;
        }
        default: {
          break;
        }
      }
    },
    isPaymentSelectingContactAuthStatus(status) {
      return status === 'asking_select_contact_auth';
    },
    isPaymentSelectingTransactionAuthStatus(status) {
      return status === 'asking_select_payment_auth';
    },
    isPaymentContactAuthStatus(status) {
      return status === 'asking_contact_auth';
    },
    isPaymentTransactionAuthStatus(status) {
      return status === 'asking_payment_auth';
    },
    isExecutingTransfer(status) {
      return status === 'executing_transfer';
    },
    isValidatingTransfer(status) {
      return status === 'validating_transfer';
    },
    isWaitingAction(status) {
      return status === 'waiting_action';
    },
    isExecutingPaymentStatus(status) {
      return this.isExecutingTransfer(status) || this.isWaitingAction(status) || this.isValidatingTransfer(status);
    },
    isPaymentAuthStatus(status) {
      return this.isPaymentContactAuthStatus(status) || this.isPaymentTransactionAuthStatus(status);
    },
    isPaymentSelectingAuthStatus(status) {
      return this.isPaymentSelectingContactAuthStatus(status) || this.isPaymentSelectingTransactionAuthStatus(status);
    },
    isPaymentError(paymentStatus) {
      return paymentStatus === 'failed' || paymentStatus === 'unknown'
        || paymentStatus === 'rejected' || paymentStatus === 'expired';
    },
    isPaymentRetryable(paymentStatus) {
      return paymentStatus === 'retryable_failure';
    },
    hideSensitiveInformation(number) {
      const stringNumber = number.toString();
      const hiddenNumber = stringNumber.split('').map((item, index) => ((stringNumber.length - index > 4) ? '●' : item)).join('');
      return hiddenNumber;
    },
    parsedAmount(amount) {
      return amount.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' });
    },
    getPaymentSortedAndFilteredAllowedAccounts(accounts) {
      return sortAccountsByBalance(filterAllowedAccounts(accounts));
    },
    paymentDataIsValidForBank() {
      return this.isPaymentAmountAboveMinimum();
    },
    isPaymentAmountAboveMinimum() {
      return this.paymentAmount >= this.paymentMinimumAmount;
    },
    getPaymentDataValidationErrorForBank() {
      if (!this.isPaymentAmountAboveMinimum()) {
        return 'amount_below_minimum';
      }
      return 'unknown_validation_error';
    },
  },
};
