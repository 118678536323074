<template>
  <div
    v-if="isAuthAvailable"
    class="flex flex-row w-full space-x-4 px-2 items-start"
  >
    <div class="min-w-8 min-h-8 mt-2">
      <component
        :is="authIcon"
      />
    </div>
    <div
      class="text-body-color max-w-60"
      data-test="auth-action-text"
      v-html="authActionText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SmsIcon from '../icons/Auth/type/SmsIcon.vue';
import CardIcon from '../icons/Auth/type/CardIcon.vue';
import DeviceIcon from '../icons/Auth/type/DeviceIcon.vue';
import EmailIcon from '../icons/Auth/type/EmailIcon.vue';
import BancoFalabellaApp from '../icons/Bank-App/BancoFalabellaApp.vue';
import BanorteApp from '../icons/Bank-App/BanorteApp.vue';
import BbvaApp from '../icons/Bank-App/BbvaApp.vue';
import authTypes from '../../../sharedTypes/authTypes';

export default {
  props: {
    authType: String,
  },
  computed: {
    ...mapGetters([
      'authDeviceName',
      'authCoordinatesName',
      'isBancoFalabella',
      'isBancoBanorte',
      'isBancoBBVA',
    ]),
    deviceIcon() {
      if (this.isBancoFalabella) {
        return BancoFalabellaApp;
      }
      if (this.isBancoBanorte) {
        return BanorteApp;
      }
      if (this.isBancoBBVA) {
        return BbvaApp;
      }
      return DeviceIcon;
    },
    authIcon() {
      const authIcons = {
        SMS: SmsIcon,
        CARD: CardIcon,
        DEVICE: this.deviceIcon,
        EMAIL: EmailIcon,
      };
      return authIcons[this.authType];
    },
    deviceActionText() {
      if (this.isBancoFalabella) {
        return 'Ingresa la <b>clave dinámica</b> que genera tu';
      }
      if (this.isBancoBanorte) {
        return 'Ingresa el <b>Token Celular</b> que genera tu';
      }
      if (this.isBancoBBVA) {
        return 'Ingresa el <b>Token Celular</b> que genera tu';
      }
      return 'Ingresa el código que genera tu';
    },
    actionText() {
      const texts = {
        SMS: 'Ingresa el código enviado a tu celular por',
        CARD: 'Ingresa las coordenadas de tu',
        DEVICE: this.deviceActionText,
        EMAIL: 'Ingresa el código enviado por',
      };
      return texts[this.authType] ?? '';
    },
    nameText() {
      const texts = {
        SMS: 'SMS',
        CARD: this.authCoordinatesName,
        DEVICE: this.authDeviceName,
        EMAIL: 'Correo Electrónico',
      };
      return texts[this.authType] ?? '';
    },
    extraText() {
      const texts = {
        SMS: '',
        CARD: '',
        DEVICE: '',
        EMAIL: 'a <b>tu email</b>',
      };
      return texts[this.authType] ?? '';
    },
    authActionText() {
      return `${this.actionText} <b>${this.nameText}</b> ${this.extraText}`;
    },
    isAuthAvailable() {
      const availableAuths = [authTypes.SMS, authTypes.CARD, authTypes.DEVICE, authTypes.EMAIL];
      return availableAuths.some((authType) => authType === this.authType);
    },
  },
  components: {
    SmsIcon,
    CardIcon,
    DeviceIcon,
    EmailIcon,
    BancoFalabellaApp,
  },
};
</script>
