const requireConstants = require.context('.', false, /\.js$/);

const constants = {};

requireConstants.keys().forEach((filename) => {
  const constantsName = filename.replace(/(\.\/|\.js)/g, '');
  constants[constantsName] = requireConstants(filename).default || requireConstants(filename);
});

export default constants;
