<template>
  <widget-layout>
    <div class="outer-container">
      <div class="items-center p-5 w-full">
        <FintocHome
          :isWindowSmall="isWindowSmall"
          :organizationId="organizationId"
        />
        <div class="flex flex-col items-start p-0 mt-10 w-full">
          <div id="homeTitle" v-if="!isPayments" :class="(isWindowSmall ? 'px-3 font-M font-400 text-center color-txt-heading leading-tight' : 'px-3 font-L font-400 text-center color-txt-heading leading-tight')">
            {{ organizationName }} se conecta con {{ institutionName }} usando<a target="_blank" href="https://fintoc.com/" class="font-700"> Fintoc </a> </div>
          <div id="homeTitlePayments" v-if="isPayments" :class="(isWindowSmall ? 'px-3 font-M font-400 text-center color-txt-heading leading-tight' : 'px-3 font-L font-400 text-center color-txt-heading leading-tight')">
            {{ organizationName }} recibe transferencias usando<span class="font-700 cursor-pointer" @click="showFintocDrawer"> Fintoc </span> </div>
        </div>

        <div class="flex flex-col items-start p-0 mt-10 w-full">
          <div class="flex items-start p-0 w-full">
            <div class="flex items-start p-0 w-full">
              <div class="flex items-start pt-2 pb-0 px-5 text-primary-main">
                <Shield :class="(isWindowSmall ? 'h-5' : '')"/>
              </div>
              <div class="flex flex-col items-start mr-5">
                <div :class="(isWindowSmall ? 'color-txt-heading font-S font-600' : 'color-txt-heading font-M font-600')"> Seguro </div>
                <div :class="(isWindowSmall ? 'font-XS font-400 color-txt-body' : 'font-S font-400 color-txt-body')"> Tu conexión está encriptada de extremo a extremo. </div>
              </div>
            </div>
          </div>
          <div class="flex items-start p-0 w-full">
            <div class="flex items-start p-0 w-full">
              <div class="flex items-start pt-2 pb-0 px-5 mt-4 text-primary-main">
                <lock v-if="!isPayments" :class="(isWindowSmall ? 'h-5' : '')"/>
                <Lightning v-if="isPayments" :class="(isWindowSmall ? 'h-5' : '')"/>
              </div>
              <div class="flex flex-col items-start mt-4 mr-5">
                <div id="homeSecondTextTitle" :class="(isWindowSmall ? 'color-txt-heading font-S font-600' : 'color-txt-heading font-M font-600')"> {{ secondTextTitle }} </div>
                <div id="homeSecondTextSubtitle" :class="(isWindowSmall ? 'font-XS font-400 color-txt-body' : 'font-S font-400 color-txt-body')"> {{ secondTextSubtitle }} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          :disabled="loading"
          data-cy="continue"
          text="Continuar"
          @click.native="debounceTransitionToBanks()"
        />
        <LegalTerms class="my-3 mt-2"/>
      </div>
      <Sandbox/>
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Debounce from '../../mixins/debounce';
import constants from '../../../constants';
import LegalTerms from './LegalTerms.vue';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import products from '../../../../sharedTypes/products';
import FintocHome from './FintocHome.vue';
import Shield from '../../icons/Shield.vue';
import Lock from '../../icons/Lock.vue';
import Lightning from '../../icons/Lightning.vue';
import ActionButton from '../../layout/ActionButton.vue';
import Sandbox from '../../Sandbox.vue';
import AnalyticsEvents from '../../../constants/analytics_events';
import Tracking from '../../../helpers/tracking';

export default {
  mixins: [Debounce],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['bodyConfig']),
    ...mapState({
      product: (state) => state.config.product,
      holderType: (state) => state.config.holderType,
      selectedCountry: (state) => state.config.selectedCountry,
      organizationName: (state) => state.config.organizationName,
      organizationId: (state) => state.config.organizationId,
      windowWidth: (state) => state.widget.windowWidth,
      windowHeight: (state) => state.widget.windowHeight,
    }),
    isWindowSmall() {
      return (this.windowHeight < 640 || this.windowWidth < 300);
    },
    secondTextTitle() {
      if (this.product === products.PAYMENTS) {
        return this.bodyConfig.speedTextTitle;
      }
      return 'Privado';
    },
    secondTextSubtitle() {
      if (this.isPayments) {
        return this.bodyConfig.speedTextSubtitle;
      }
      return this.bodyConfig.privacyText;
    },
    isPayments() {
      return this.product === products.PAYMENTS;
    },
    institutionName() {
      if (typeof this.bodyConfig.institution === 'function') {
        return this.bodyConfig.institution(this.selectedCountry);
      }

      return this.bodyConfig.institution;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'toggleFintocDrawer']),
    debounceTransitionToBanks() {
      this.loading = true;
      this.debounce(this.transitionToBanks);
    },
    transitionToBanks() {
      this.loading = true;
      const fiscalProducts = [
        products.INVOICES,
        products.TAX_RETURNS,
        products.INCOME,
        products.TAX_STATEMENTS,
      ];

      if ([products.SUBSCRIPTION_INTENTS, products.MOVEMENTS, products.SUBSCRIPTION, products.PAYMENTS].includes(this.product)) {
        this.transitionToNextState({ transition: constants.transitions.OPEN_BANKS });
      }
      if (fiscalProducts.includes(this.product)) {
        this.transitionToNextState({ transition: constants.transitions.OPEN_FISCAL_AUTHORITIES });
      }
    },
    showFintocDrawer() {
      Tracking.trackEvent(
        AnalyticsEvents.DRAWER_OPENED,
        { drawer: 'footer' },
      );
      this.toggleFintocDrawer();
    },
  },
  components: {
    LegalTerms,
    WidgetLayout,
    FintocHome,
    Shield,
    Lock,
    Lightning,
    ActionButton,
    Sandbox,
  },
};
</script>

<style>
.check-background {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 58px;
  height: 58px;
  min-height: 58px;
  min-width: 58px;
  max-width: none;
  max-height: none;

  background: #E7F7E9;
  border-radius: 32.64px;
}

.outer-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100vw;
  max-width: 360px;
  overflow: visible;
}
</style>
