<template >
  <widget-layout>
    <div class="outer-container">
      <div class="flex flex-col items-center pt-5 px-5 pb-3 w-screen max-w-90">
        <CheckVerified class="check-background"/>
        <div class="flex flex-col items-center w-full my-5 mx-0" id='info-title'>
          <div class="font-M font-700 color-txt-heading"> ¡Transferencia exitosa! </div>
          <div class="font-S font-400 color-txt-body py-1" > Detalles del pago por transferencia </div>
          <div class="font-XS font-400 color-txt-disable"> {{ parsedDateTime() }} </div>
        </div>
        <div class="flex flex-col py-1.5 px-0 w-full m-0 bg-white">
          <div v-if="(operationNumber !== null)" class="flex justify-between w-full" id='operation-number'>
            <div class="font-XS font-400 color-txt-sec-and-cap"> Número de operación </div>
            <div class="font-XS font-400 color-txt-heading"> {{ operationNumber }} </div>
          </div>
          <div v-if="(operationNumber !== null)" class="w-full h-0.5 my-1.5 mx-0 border border-divider-color" />

          <div class="flex justify-between w-full" id='sender-bank'>
            <div class="font-XS font-400 color-txt-sec-and-cap"> Desde banco </div>
            <div class="font-XS font-400 color-txt-heading"> {{ institutionName }} </div>
          </div>
          <div class="w-full h-0.5 my-1.5 mx-0 border border-divider-color" />

          <div class="flex justify-between w-full" id='sender-account-number'>
            <div class="font-XS font-400 color-txt-sec-and-cap"> Cuenta </div>
            <div class="font-XS font-400 color-txt-heading"> {{ hideSensitiveInformation(this.accountNumber) }} </div>
          </div>
          <div class="w-full h-0.5 my-1.5 mx-0 border border-divider-color" />

          <div class="flex justify-between w-full" id='recipient-holder-name'>
            <div class="font-XS font-400 color-txt-sec-and-cap"> Destinatario </div>
            <div class="font-XS font-400 color-txt-heading text-right"> {{ recipientHolderName }} </div>
          </div>
          <div class="w-full h-0.5 my-1.5 mx-0 border border-divider-color" />

          <div class="flex justify-between w-full" id='partial-amount'>
            <div class="font-XS font-400 color-txt-sec-and-cap"> Monto </div>
            <div class="font-XS font-400 color-txt-heading"> {{ parsedAmount(this.amount) }} </div>
          </div>
          <div v-if="isBancoEstadoComission">
            <div class="w-full h-0.5 my-1.5 mx-0 border border-divider-color" />

            <div class="flex justify-between w-full" id='comission'>
              <div class="font-XS font-400 color-txt-sec-and-cap"> Comisión para el banco </div>
              <div class="font-XS font-400 color-txt-heading"> ${{ calculateCommission }} </div>
            </div>
            <div class="w-full h-0.5 my-1.5 mx-0 border border-divider-color" />

            <div class="flex justify-between w-full" id='total-amount'>
              <div class="font-XS font-400 color-txt-sec-and-cap"> Monto total a pagar </div>
              <div class="font-XS font-400 color-txt-heading"> {{ parsedAmountWithComission }} </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          v-if="voucherState"
          id='voucher-button'
          class="mb-2"
          :secondary="true"
          text="Descargar comprobante"
          @click.native="downloadVoucher()"
        />
        <ActionButton
          id='finish-payment-button'
          text="¡Listo!"
          @click.native="finishPayment()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import ActionButton from '../layout/ActionButton.vue';
import CheckVerified from '../icons/CheckVerified.vue';
import PaymentsMixin from '../mixins/payments';

import AnalyticsEvents from '../../constants/analytics_events';
import Analytics from '../../helpers/analytics';
import optimizelyFlags from '../../helpers/optimizely';

export default {
  mixins: [PaymentsMixin],
  data() {
    return {
      voucherState: false,
    };
  },
  computed: {
    ...mapGetters(['institutionName', 'isBancoEstadoComission', 'parsedAmountWithComission', 'calculateCommission']),
    ...mapState({
      recipientHolderName: (state) => state.payments.recipientHolderName,
      accountNumber: (state) => state.payments.accountNumber,
      amount: (state) => state.payments.amount,
      paymentDateTime: (state) => state.payments.datetime,
      operationNumber: (state) => state.payments.referenceId,
      paymentUrl: (state) => state.payments.url,
      analyticsEventProperties: (state) => Analytics.getEventPropertiesFromRootState(state),
    }),
  },
  methods: {
    ...mapActions(['widgetFinished', 'getPaymentVoucherUrl', 'getPaymentIntent']),
    async finishPayment() {
      await this.getPaymentIntent();
      this.widgetFinished();
    },
    parsedDateTime() {
      const date = new Date(this.paymentDateTime);
      const dayOfMonth = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const hour = (date.getHours() < 10 ? '0' : '') + date.getHours();
      const min = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
      const parsedDate = `${dayOfMonth}/${month}/${year} – ${hour}:${min}`;
      return parsedDate;
    },
    async requestPaymentsVoucher() {
      try {
        await this.getPaymentVoucherUrl();
        this.updateVoucherIfAvailable();
      } catch (error) {
        Sentry.captureException(error);
        // We were only console logging this and ignoring cancel axios request (error.__CANCEL__)
      }
    },
    downloadVoucher() {
      if (this.paymentUrl !== (null || undefined)) {
        window.analytics.track(
          AnalyticsEvents.PAYMENT_VOUCHER_CLICKED, this.analyticsEventProperties,
        );
        window.open(this.paymentUrl, 'Download');
      }
    },
    updateVoucherIfAvailable() {
      if (this.paymentUrl !== null) {
        this.voucherState = true;
      } else {
        this.voucherState = false;
      }
    },
  },
  created() {
    optimizelyFlags.trackEvent('conversion');
    this.requestPaymentsVoucher();
  },
  components: {
    WidgetLayout,
    ActionButton,
    Footer,
    CheckVerified,
  },

};
</script>
