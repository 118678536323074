<template>
  <div class="flex flex-row items-center space-x-2 justify-start">
    <Info class="text-secondary-color" />
    <div class="text-xs text-secondary-color"> {{ text }} </div>
    </div>
</template>

<script>
import Info from '../icons/Info.vue';

export default {
  name: 'InfoOneLine',
  components: {
    Info,
  },
  props: {
    text: String,
  },
};
</script>
