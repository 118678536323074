<template>
  <div v-if="isPayment">
    <div
      v-if="isFirstMfa"
      data-test="description"
      class="text-secondary-color text-xs text-center pb-4"
    >
      {{ description }}
    </div>
    <InfoOneLine
      v-else
      :text="description"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import products from '../../../sharedTypes/products';
import constants from '../../constants';
import InfoOneLine from '../views.common/InfoOneLine.vue';

export default {
  computed: {
    ...mapGetters(['isPayment']),
    ...mapState({
      stateContext: (state) => state.widget.currentStateContext,
      product: (state) => state.config.product,
      contactMfaCounter: (state) => state.payments.contactMfaCounter,
      paymentMfaCounter: (state) => state.payments.paymentMfaCounter,
    }),
    isFirstMfa() {
      const isFirstContactMfa = this.contactMfaCounter === 0 && this.stateContext === constants.state_context.CONTACT_AUTH;
      const isFirstPaymentMfa = this.paymentMfaCounter === 0 && this.stateContext === constants.state_context.PAYMENT_AUTH;
      return isFirstContactMfa || isFirstPaymentMfa;
    },
    description() {
      switch (this.product) {
        case products.PAYMENTS: {
          if (this.stateContext === constants.state_context.CONTACT_AUTH) {
            if (this.contactMfaCounter === 0) {
              return 'Este paso solo tendrás que hacerlo la primera vez con un nuevo destinatario';
            }
            return 'Tu banco requiere un segundo método de autenticación para un nuevo destinatario';
          }
          if (this.paymentMfaCounter === 0) {
            return '';
          }
          return 'Tu banco requiere un segundo método de autenticación para autorizar una transferencia';
        }
        default: {
          return '';
        }
      }
    },
  },
  components: {
    InfoOneLine,
  },
};
</script>
