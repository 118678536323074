<template>
  <div v-if="mode === 'test'"
    id="sandbox-footer"
    :class="(isWindowHeightSmall? 'relative bg-gray-700 text-bold text-center text-white py-2 text-sm font-medium'
    :'fixed bottom-0 w-full bg-gray-700 text-bold text-center text-white py-2 text-sm font-medium')">
    {{ sandboxText }}
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import constants from '../constants';

export default {
  computed: {
    ...mapGetters(['isWindowHeightSmall']),
    ...mapState({
      mode: (state) => state.config.mode,
      currentState: (state) => state.widget.currentState,
      entityId: (state) => state.widget.entityIdSelected,
    }),
    testUsername() {
      if (this.entityId.includes('mx')) {
        return 'Nombre de usuario: 4000002760003184';
      }
      return 'RUT: 415792638';
    },
    sandboxText() {
      const testModeText = 'Estás en el ambiente de prueba';
      if (this.currentState !== constants.states.LOGIN) {
        return testModeText;
      }
      const credentialsText = `Credenciales » ${this.testUsername} | Clave: jonsnow`;
      if (this.isWindowHeightSmall) {
        return credentialsText;
      }

      return `${testModeText}. ${credentialsText}`;
    },
  },
};
</script>
