/* eslint-disable import/prefer-default-export */

import products from '../../sharedTypes/products';
import { getBanksConfig, getAvailableProducts } from '../helpers/frozen-state/banks';
import { getFiscalAuthoritiesConfig } from '../helpers/frozen-state/fiscalAuthorities';
import { frozenStateName, getFrozenStateConfig, isFrozen } from '../helpers/frozen-state/utils';
import optimizely from '../helpers/optimizely';

const PAYMENT_INTENT = {
  status: 'created',
  amount: 3500,
  createdAt: '2021-12-22T14:58:58.474Z',
  nextAction: 'next action',
  referenceId: 'reference id',
  errorReason: '',
  authOptions: 'auth options',
  recipientHolderName: 'Frozen State Recipient',
  recipientAccount: {
    institutionId: 'some_institution_id',
  },
};

const setupCommonConfig = async (store, product) => {
  console.log('Loading common config...');

  store.commit('setProduct', product);
  store.dispatch('setStateMachine', { product });

  console.log('Common config loaded');
  console.log('--------------------------------');
};

const setupCommonBanksConfig = (store) => {
  console.log('Loading common banks config...');

  store.commit('getBanks', getAvailableProducts());

  console.log('Common banks config loaded');
  console.log('--------------------------------');
};

const setupCommonFiscalAuthoritiesConfig = async (store) => {
  console.log('Loading common fiscal authorities config...');

  const fiscalAuthorities = await getFiscalAuthoritiesConfig();

  store.commit('getFiscalAuthorities', fiscalAuthorities);

  console.log('Common fiscal authorities config loaded');
  console.log('--------------------------------');
};

const setupPaymentsConfig = (store) => {
  console.log('Loading payments config...');

  store.commit('getProductBanksConfigs', getBanksConfig());

  store.commit('getPaymentStatus', PAYMENT_INTENT);
  store.commit('getPaymentIntent', PAYMENT_INTENT);

  console.log('Payments config loaded');
  console.log('--------------------------------');
};

const setupProductConfig = (store, frozenState) => {
  switch (frozenState.product) {
    case products.PAYMENTS: {
      return setupPaymentsConfig(store);
    }
    default: {
      return null;
    }
  }
};

const loadFrozenConfig = (store, frozenConfig) => {
  console.log('Loading frozen config...');

  store.commit('loadPaymentsFrozenState', frozenConfig.payments);
  store.commit('loadLinksFrozenState', frozenConfig.links);
  store.commit('loadRefreshIntentsFrozenState', frozenConfig.refreshIntents);
  store.commit('loadSubscriptionIntentsFrozenState', frozenConfig.subscriptionIntents);
  store.commit('loadSubscriptionsFrozenState', frozenConfig.subscriptions);

  console.log('Frozen config loaded');
  console.log('--------------------------------');
};

const setupCoreConfig = (store, frozenState) => {
  console.log('Loading core wizard config...');

  store.commit('configurateWidget', {
    holderType: 'INDIVIDUAL',
    publicKey: 'public key',
    widgetToken: 'widget token',
    webhookUrl: 'https://fintoc.com',
    product: frozenState.product,
    mode: 'live',
    userId: 'user id',
    companyName: 'Frozen Company',
    defaultCountry: 'cl',
    anonimizedEntites: false,
    requireAmountLimit: false,
    hotjarEnabled: false,
  });

  store.commit('loadConfigFrozenState', frozenState.frozenConfig.config);
  store.commit('loadWidgetFrozenState', frozenState.frozenConfig.widget);

  console.log('Core wizard config loaded');
  console.log('--------------------------------');
};

export const setupFrozenState = async (store) => {
  if (!isFrozen() || (process.env.NODE_ENV !== 'development')) {
    return;
  }

  console.log('--------------------------------');
  console.log('Setting up the frozen state...');
  console.log('--------------------------------');

  const frozenStateConfig = await getFrozenStateConfig(frozenStateName());

  await setupCommonConfig(store, frozenStateConfig.product);

  setupCommonBanksConfig(store);
  await setupCommonFiscalAuthoritiesConfig(store);

  optimizely.init({
    userId: 'test',
    companyName: 'test',
    defaultCountry: 'test',
    product: 'PAYMENTS',
  });

  setupCoreConfig(store, frozenStateConfig);

  setupProductConfig(store, frozenStateConfig);

  loadFrozenConfig(store, frozenStateConfig.frozenConfig);

  store.commit('setState', {
    state: frozenStateConfig.state,
    stateContext: frozenStateConfig.stateContext,
  });

  store.commit('widgetOpened');
};
