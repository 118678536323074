import constants from '../../constants/index';
import holderTypes from '../../../sharedTypes/holderTypes';

export default function buildErrorMessage(data) {
  if (data.errorType === constants.errors.LINK_INTENT_REJECTED_INVALID_CREDENTIALS) {
    if (isEstadoIndividual(data)) {
      return errorMessages(data).linkEstadoIndividualInvalidCredentials;
    }
    if (isTributaryPasswordNotSetError(data)) {
      return errorMessages(data).tributaryPasswordNotSet;
    }
    return errorMessages(data).linkInvalidCredentials;
  }
  if (data.errorType === constants.errors.LINK_INTENT_MFA_REQUIRED) {
    return errorMessages(data).linkMfaRequired;
  }
  if (data.errorType === constants.errors.LINK_INTENT_REJECTED_CREDENTIALS_LOCKED) {
    return errorMessages(data).linkLockedCredentials;
  }
  if (data.errorType === constants.errors.INVALID_MFA) {
    return errorMessages(data).linkInvalidMfa;
  }
  if (data.errorType === constants.errors.LINK_INTENT_FAILED) {
    return errorMessages(data).linkGenericFailed;
  }

  return errorMessages(data).defaultError;
}

function isEstadoIndividual(data) {
  return data.holderType === holderTypes.INDIVIDUAL && data.entityId === 'cl_banco_estado';
}

function isTributaryPasswordNotSetError(data) {
  return data.errorReason === 'tributary_password_not_set_error';
}

function errorMessages(data) {
  return {
    linkInvalidCredentials: {
      title: 'Las credenciales ingresadas son incorrectas.',
      description: '',
    },
    linkEstadoIndividualInvalidCredentials: {
      title: 'Las credenciales ingresadas son incorrectas.',
      description: 'Recuerda que debes ingresar la clave del portal web',
    },
    linkMfaRequired: {
      title: `Desactiva la autenticación de dos factores para esta cuenta en tu banco ${data.bankTextEntitySelected}`,
      description: 'Fintoc no se puede conectar a cuentas con el segundo factor activado. Desactiva y reintenta el proceso.',
    },
    linkLockedCredentials: {
      title: 'La clave ingresada se encuentra bloqueada.',
      description: 'Puedes desbloquear tu cuenta en la página web de tu institución o contactándolos directamente.',
    },
    linkInvalidMfa: {
      title: 'Lo sentimos, ingresaste un token inválido. Vuelve a intentarlo en un rato más',
      description: 'Por seguridad debes esperar 2 hrs para poder intentarlo otra vez',
    },
    linkGenericFailed: {
      title: 'Tuvimos un problema conectándonos',
      description: 'Intenta más tarde',
    },
    tributaryPasswordNotSet: {
      title: 'Crea tu clave tributaria en el SII',
      description: 'No tienes clave tributaria para operar en el sitio web del SII. Créala y vuelve para reintentar.',
    },
    defaultError: {
      title: 'Tuvimos un problema conectándonos',
      description: '',
    },
  };
}
