<template>
  <widget-layout>
    <div class="token-steps">
      <p>1 - Abre tu <strong>app BBVA</strong></p>
      <p>2 - Haz click en <span class="font-bold italic">Token móvil</span></p>
      <p>3 - Click en <span class="font-bold italic">Generar código</span></p>
      <p>4 - Ingresa el código que muestra la app BBVA</p>
    </div>
    <div class="flex flex-row mt-8 mb-2 items-center justify-center gap-2">
      <img :src="require('@/assets/images/reset_password_helper6a.svg')"/>
      <img :src="require('@/assets/images/reset_password_helper6b.png')"/>
      </div>
      <img :src="require('@/assets/images/token_helper.png')" />

    <div class="buttons-container">
      <ActionButton
        text="¡Listo!"
        @click.native="closeHelper"
      />
    </div>
  </widget-layout>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetLayout from '../../layout/WidgetLayout.vue';
import ActionButton from '../../layout/ActionButton.vue';
import constants from '../../../constants';

export default {
  methods: {
    closeHelper() {
      this.transitionToNextState({ transition: constants.transitions.CLOSE_WIDGET });
    },
    ...mapActions(['transitionToNextState']),
  },
  components: {
    WidgetLayout,
    ActionButton,
  },
};
</script>

<style>
.token-steps {
  width: 320px;
  height: 60px;
  margin-top: 20px;

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;

  text-align: center;
  letter-spacing: -0.006em;

  color: #4A4672
}

.token-images {
  position: relative;
  height: 332.9px;
  top: 20px;
  border-radius: 8px;
}

</style>
