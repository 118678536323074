<template>
<svg width="56" height="57" viewBox="0 0 56 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 28.103C0 12.6391 12.536 0.103027 28 0.103027C43.464 0.103027 56 12.6391 56 28.103C56 43.567 43.464 56.103 28 56.103C12.536 56.103 0 43.567 0 28.103Z" :fill="backgroundColor"/>
<g clip-path="url(#clip0_281_4968)">
<path d="M28 8.10303C16.9531 8.10303 8 17.0562 8 28.103C8 39.1499 16.9531 48.103 28 48.103C39.0469 48.103 48 39.1499 48 28.103C48 17.0562 39.0469 8.10303 28 8.10303ZM36.3047 35.0171C36.9736 35.8069 36.8748 36.9913 36.0837 37.6601C35.7344 37.9546 35.3047 38.103 34.875 38.103C34.3428 38.103 33.8133 37.8772 33.4422 37.439L28 31.0093L22.5547 37.439C22.1875 37.8765 21.6563 38.103 21.0547 38.103C20.6953 38.103 20.2656 37.9546 19.9141 37.6577C19.1234 36.9888 19.0242 35.8046 19.6931 35.0147L25.5469 28.103L19.6953 21.189C19.0234 20.3999 19.125 19.2124 19.9141 18.5483C20.7031 17.8765 21.8906 17.978 22.5547 18.7671L28 25.1968L33.443 18.764C34.1107 17.9733 35.2961 17.8726 36.0859 18.543C36.8766 19.2119 36.9758 20.3962 36.3069 21.186L30.4531 28.103L36.3047 35.0171Z" :fill="circleColor"/>
</g>
<defs>
<clipPath id="clip0_281_4968">
<rect width="40" height="40" fill="white" transform="translate(8 8.10303)"/>
</clipPath>
</defs>
</svg>
</template>

<script>
export default {
  name: 'Error',
  props: {
    backgroundColor: String,
    circleColor: String,
  },
};
</script>
