import constants from '../constants';
import StateMachine from '../helpers/state-machine';

const { states, transitions } = constants;

export default function createPaymentsStateMachine() {
  return new StateMachine({
    id: 'payments-state-machine',
    initial: states.INTRODUCTION,
    states: {
      [states.INTRODUCTION]: {
        on: {
          [transitions.OPEN_BANKS]: states.BANK_SELECTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Transfiere con tu banco',
          close: true,
        },
        body: {
          institution: 'tu banco',
          speedTextTitle: 'Rápido',
          speedTextSubtitle: 'Transfiere fácil en menos de un minuto.',
        },
      },
      [states.BANK_SELECTION]: {
        on: {
          [transitions.SELECT_BANK]: states.LOGIN,
          [transitions.PAYMENT_DATA_VALIDATION_FOR_SELECTED_BANK_FAILED]: states.PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK,
          [transitions.GO_BACK]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: true,
          title: 'Selecciona tu banco',
          close: true,
        },
      },
      [states.PAYMENTS_INVALID_DATA_FOR_SELECTED_BANK]: {
        on: {
          [transitions.GO_BACK]: states.BANK_SELECTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: true,
          title: 'Revisa los datos de transferencia',
          close: false,
        },
      },
      [states.LOGIN]: {
        on: {
          [transitions.LINK_INTENT_CREATE_SUCCEEDED]: states.LOADING_LINK_INTENT,
          [transitions.LINK_INTENT_CREATE_FAILED]: states.ERROR_VIEW,
          [transitions.GO_BACK]: states.BANK_SELECTION,
          [transitions.GO_HOME]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.PAYMENTS_NO_RETRY_ERROR]: states.PAYMENTS_ERROR,
        },
        header: {
          back: true,
          title: 'Ingresa a tu banco',
          close: true,
        },
      },
      [states.LOADING_LINK_INTENT]: {
        on: {
          [transitions.LINK_INTENT_SUCCESSFUL]: states.PAYMENTS_CONFIRMATION_REQUIRED,
          [transitions.LINK_INTENT_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.PAYMENTS_INSUFFICIENT_FUNDS_FAILED]: states.PAYMENTS_ERROR,
        },
        header: {
          back: false,
          title: 'Conectándonos',
          close: true,
        },
      },
      [states.PAYMENTS_CONFIRMATION_REQUIRED]: {
        on: {
          [transitions.PAYMENTS_CONFIRMED_SUCCEEDED]: states.PROCESSING_PAYMENTS,
          [transitions.PAYMENTS_CONFIRMED_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Selecciona tu cuenta',
          close: true,
        },
      },
      [states.PROCESSING_PAYMENTS]: {
        on: {
          [transitions.PROCESSING_PAYMENTS_SUCCEEDED]: states.PAYMENTS_CREATED,
          [transitions.PROCESSING_PAYMENTS_FAILED]: states.PAYMENTS_ERROR,
          [transitions.PAYMENTS_SELECTING_AUTH]: states.PAYMENTS_SELECTING_AUTH,
          [transitions.PAYMENTS_APP_AUTH_REQUIRED]: states.APP_AUTH_REQUIRED,
          [transitions.PAYMENTS_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.PAYMENTS_CARD_AUTH_REQUIRED]: states.CARD_AUTH_REQUIRED,
          [transitions.PAYMENTS_SMS_AUTH_REQUIRED]: states.SMS_AUTH_REQUIRED,
          [transitions.PAYMENTS_EMAIL_AUTH_REQUIRED]: states.EMAIL_AUTH_REQUIRED,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.PAYMENTS_SELECTING_AUTH]: {
        on: {
          [transitions.PAYMENTS_SELECTING_SUCCEEDED]: states.PROCESSING_PAYMENTS,
          [transitions.PAYMENTS_SELECTING_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.APP_AUTH_REQUIRED]: {
        on: {
          [transitions.PAYMENTS_APP_AUTH_REQUIRED]: states.APP_AUTH_REQUIRED,
          [transitions.PAYMENTS_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.PAYMENTS_CARD_AUTH_REQUIRED]: states.CARD_AUTH_REQUIRED,
          [transitions.PAYMENTS_SMS_AUTH_REQUIRED]: states.SMS_AUTH_REQUIRED,
          [transitions.PAYMENTS_EMAIL_AUTH_REQUIRED]: states.EMAIL_AUTH_REQUIRED,
          [transitions.APP_AUTH_SUCCEEDED]: states.PAYMENTS_PROCESSING_ACTION,
          [transitions.WAITING_ACTION_SUCCEEDED]: states.PAYMENTS_CREATED,
          [transitions.WAITING_ACTION_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.PAYMENTS_SELECTING_AUTH]: states.PAYMENTS_SELECTING_AUTH,
          [transitions.PROCESSING_ACTION_SUCCEEDED]: states.PAYMENTS_CREATED,
          [transitions.PROCESSING_ACTION_FAILED]: states.PAYMENTS_ERROR,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.DEVICE_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.PAYMENTS_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.PAYMENTS_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.CARD_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.PAYMENTS_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.PAYMENTS_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.SMS_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.PAYMENTS_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.PAYMENTS_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.EMAIL_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.PAYMENTS_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.PAYMENTS_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.PAYMENTS_PROCESSING_ACTION]: {
        on: {
          [transitions.PAYMENTS_APP_AUTH_REQUIRED]: states.APP_AUTH_REQUIRED,
          [transitions.PAYMENTS_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.PAYMENTS_CARD_AUTH_REQUIRED]: states.CARD_AUTH_REQUIRED,
          [transitions.PAYMENTS_SMS_AUTH_REQUIRED]: states.SMS_AUTH_REQUIRED,
          [transitions.PAYMENTS_EMAIL_AUTH_REQUIRED]: states.EMAIL_AUTH_REQUIRED,
          [transitions.PAYMENTS_SELECTING_AUTH]: states.PAYMENTS_SELECTING_AUTH,
          [transitions.PROCESSING_ACTION_SUCCEEDED]: states.PAYMENTS_CREATED,
          [transitions.PROCESSING_ACTION_FAILED]: states.PAYMENTS_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.PAYMENTS_CREATED]: {
        type: 'final',
        header: {
          back: false,
          title: 'Transferencia exitosa',
          close: false,
        },
      },
      [states.PAYMENTS_ERROR]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.ERROR_VIEW]: {
        on: {
          [transitions.RETRY_LINK_INTENT]: states.LOGIN,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Tuvimos un problema',
          close: true,
        },
      },
      [states.WIDGET_CLOSED]: {
        type: 'final',
        header: {
          back: false,
          title: '',
          close: false,
        },
      },
    },
  });
}
