import products from '../../sharedTypes/products';
import api from '../api';

const initialState = {
  accounts: null,
  holderId: null,
  linkIntentId: null,
  link: null,
  password: null,
  status: null,
  temporaryLinkToken: null,
  username: null,
  secondFactorType: null,
  challenges: null,
  captchaURL: null,
  reason: null,
};

const getters = {
};

const mutations = {
  loadLinksFrozenState(state, payload) {
    Object.assign(state, { ...(payload || {}) });
  },
  createLinkIntent(state, payload) {
    Object.assign(state, {
      holderId: payload.credentials.holderId,
      password: payload.credentials.password,
      username: payload.credentials.username,
      linkIntentId: payload.id,
    });
  },
  setLinkIntentStatus(state, payload) {
    Object.assign(state, {
      status: payload.status,
      reason: payload.reason,
    });
  },
  setLinkIntentNextAction(state, { nextAction }) {
    state.secondFactorType = nextAction ? nextAction.type : null;
    state.challenges = nextAction ? nextAction.challenges : null;
    state.captchaURL = nextAction ? nextAction.captchaURL : null;
  },
  getLinkIntent(state, payload) {
    if (payload.link != null) {
      Object.assign(state, {
        accounts: payload.link.accounts,
        temporaryLinkToken: payload.link.temporaryLinkToken,
        link: payload.link,
        reason: payload.reason,
      });
    }
  },
  linkIntentLoading(state) {
    Object.assign(state, { loading: true });
  },
  saveUsername(state, { username }) {
    Object.assign(state, { username });
  },
};

const actions = {
  async createLinkIntent({ rootState, commit }, data) {
    const {
      callbackUrl, mode, product, holderType, publicKey, widgetToken,
    } = rootState.config;
    const { entityIdSelected: institutionId } = rootState.widget;
    const { idempotencyKey, ...credentials } = data;
    const response = await api.linkIntent.create({
      ...credentials,
      callbackUrl,
      mode,
      product: product === products.SUBSCRIPTION_INTENTS ? products.SUBSCRIPTION : product,
      holderType,
      publicKey,
      institutionId,
      widgetToken,
      idempotencyKey,
    });
    commit('createLinkIntent', { credentials, ...response.data });
  },
  async getLinkIntent({ rootState, commit, state }) {
    const { publicKey } = rootState.config;
    const { linkIntentId } = state;
    const response = await api.linkIntent.get({ linkIntentId, publicKey });
    commit('setLinkIntentStatus', response.data);
    commit('setLinkIntentNextAction', response.data);
    commit('getLinkIntent', response.data);
  },
  linkIntentLoading({ commit }) {
    commit('linkIntentLoading');
  },
  async setLinkIntentStatus({ commit }, payload) {
    commit('setLinkIntentStatus', payload);
  },
  async sendLinkIntentSecondAuth({ rootState, commit, state }, { secondFactor }) {
    const { publicKey } = rootState.config;
    const { linkIntentId } = state;
    const response = await api.linkIntent.update({
      linkIntentId,
      secondFactor,
      publicKey,
    });
    commit('setLinkIntentStatus', response.data);
    commit('setLinkIntentNextAction', response.data);
    commit('getLinkIntent', response.data);
  },
  async saveUsername({ commit }, { username }) {
    commit('saveUsername', { username });
  },
};

export default {
  state: initialState,
  getters,
  mutations,
  actions,
};
