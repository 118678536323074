<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M23.8839 23.18L21.3839 20.18C21.337 20.1237 21.2782 20.0784 21.2119 20.0473C21.1455 20.0162 21.0731 20 20.9999 20H2.99982C2.92653 20 2.85415 20.0162 2.78779 20.0473C2.72143 20.0784 2.66272 20.1237 2.61582 20.18L0.115813 23.18C0.0550084 23.253 0.0162609 23.3418 0.00410786 23.436C-0.00804518 23.5303 0.00689932 23.626 0.0471914 23.7121C0.0874835 23.7981 0.151455 23.8709 0.231616 23.9219C0.311776 23.9729 0.404807 24 0.499814 24H23.4999C23.5949 24 23.6879 23.9729 23.7681 23.9219C23.8482 23.8709 23.9122 23.7981 23.9525 23.7121C23.9928 23.626 24.0077 23.5303 23.9956 23.436C23.9834 23.3418 23.9447 23.253 23.8839 23.18Z" fill="#4A4672"/>
    <path d="M2.99976 10.4999H6.99976V18.9999H2.99976V10.4999Z" fill="#4A4672"/>
    <path d="M10.0012 10.5H14.0012V19H10.0012V10.5Z" fill="#4A4672"/>
    <path d="M16.9995 10.4999H20.9995V18.9999H16.9995V10.4999Z" fill="#4A4672"/>
    <path d="M2.08497 9.27725C2.13058 9.3458 2.19242 9.40202 2.265 9.44093C2.33757 9.47983 2.41862 9.50021 2.50097 9.50025H21.501C21.5834 9.50021 21.6644 9.47983 21.737 9.44093C21.8096 9.40202 21.8714 9.3458 21.917 9.27725L22.917 7.77725C22.9671 7.70195 22.9959 7.61448 23.0002 7.52414C23.0045 7.4338 22.9842 7.34398 22.9416 7.26424C22.8989 7.18451 22.8354 7.11785 22.7578 7.07136C22.6802 7.02487 22.5915 7.00029 22.501 7.00024H1.50096C1.41052 7.00029 1.32179 7.02487 1.24421 7.07136C1.16663 7.11785 1.10311 7.18451 1.06042 7.26424C1.01773 7.34398 0.99747 7.4338 1.00179 7.52414C1.00611 7.61448 1.03486 7.70195 1.08496 7.77725L2.08497 9.27725Z" fill="#4A4672"/>
    <path d="M1.49953 5.99996H22.4996C22.6133 6.00024 22.7236 5.96178 22.8125 5.89091C22.9014 5.82004 22.9635 5.72099 22.9885 5.61011C23.0136 5.49922 23.0001 5.38311 22.9503 5.28092C22.9005 5.17873 22.8173 5.09656 22.7146 5.04796L12.2146 0.0479488C12.1473 0.0163708 12.0739 0 11.9996 0C11.9252 0 11.8518 0.0163708 11.7846 0.0479488L1.28453 5.04796C1.18176 5.09656 1.09863 5.17873 1.04882 5.28092C0.999023 5.38311 0.985532 5.49922 1.01057 5.61011C1.03562 5.72099 1.0977 5.82004 1.18658 5.89091C1.27547 5.96178 1.38585 6.00024 1.49953 5.99996Z" fill="#4A4672"/>
  </svg>
</template>

<script>

export default {
  props: {
    color: String,
  },
};
</script>
