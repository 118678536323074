<template >
  <widget-layout includeScrollArrow>
    <div class="outer-container px-4 justify-start space-y-8">
      <AuthInfo />

      <div class="w-full gap-4 pb-1" :class="{'grid grid-cols-2': isMoreThanOneOption}">
        <AuthSelectButton v-if="isAppAvailable" authType="app" @click.native="debounceSelectAuth('app')" :text="authAppName" />
        <AuthSelectButton v-if="isDeviceAvailable" authType="device" @click.native="debounceSelectAuth('device')" :text="authDeviceName" />
        <AuthSelectButton v-if="isSMSAvailable" authType="sms" @click.native="debounceSelectAuth('sms')" :text="smsTitle" />
        <AuthSelectButton v-if="isCoordinatesAvailable" authType="coordinates" @click.native="debounceSelectAuth('coordinates')" :text="authCoordinatesName" />
      </div>
      <AuthDescription />
    </div>
    <template #footer>
      <Footer v-if="true"/>
    </template>
  </widget-layout>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import * as Sentry from '@sentry/vue';
import { v1 as uuidv1 } from 'uuid';
import Debounce from '../mixins/debounce';
import utils from '../../helpers/utils';
import constants from '../../constants';
import WidgetLayout from '../layout/WidgetLayout.vue';
import Footer from '../layout/Footer.vue';
import PaymentsMixin from '../mixins/payments';
import AuthSelectButton from '../layout/AuthSelectButton.vue';
import AuthInfo from '../widget-views/AuthInfo.vue';
import AuthDescription from '../widget-views/AuthDescription.vue';

const { setFrozenFriendlyTimeout } = utils;

export default {
  mixins: [PaymentsMixin, Debounce],
  data() {
    return {
      polling: null,
      loading: false,
      idempotencyKey: '',
    };
  },
  computed: {
    ...mapGetters([
      'authDeviceName',
      'authCoordinatesName',
      'authAppName',
    ]),
    ...mapState({
      paymentStatus: (state) => state.payments.status,
      secondFactorType: (state) => state.payments.secondFactorType,
      amount: (state) => state.payments.amount.toLocaleString('es-CL', { style: 'currency', currency: 'CLP' }),
      authOptions: (state) => state.payments.authOptions,
      stateContext: (state) => state.widget.currentStateContext,
    }),
    isDeviceAvailable() {
      return this.authOptions.indexOf('device') !== -1;
    },
    isAppAvailable() {
      return this.authOptions.indexOf('app') !== -1;
    },
    isSMSAvailable() {
      return this.authOptions.indexOf('sms') !== -1;
    },
    isCoordinatesAvailable() {
      return this.authOptions.indexOf('coordinates') !== -1;
    },
    isMoreThanOneOption() {
      return this.authOptions.length > 1;
    },
    noAuthAvailable() {
      return !(this.isDeviceAvailable || this.isAppAvailable || this.isSMSAvailable || this.isCoordinatesAvailable);
    },
    smsTitle() {
      return 'SMS';
    },
  },
  methods: {
    ...mapActions(['transitionToNextState', 'getPaymentStatus', 'sendPaymentSelectedAuth']),
    clearPolling() {
      clearInterval(this.polling);
      this.polling = null;
    },
    transitionToFailed() {
      this.transitionToNextState({
        transition: constants.transitions.PAYMENTS_SELECTING_FAILED,
      });
    },
    transitionToSelectingSucceeded() {
      this.transitionToNextState({
        transition: constants.transitions.PAYMENTS_SELECTING_SUCCEEDED,
      });
    },
    async requestPaymentsStatus() {
      try {
        await this.getPaymentStatus();
      } catch (error) {
        Sentry.captureException(error);
        clearInterval(this.polling);
        this.transitionToFailed();
      }
    },
    debounceSelectAuth(auth) {
      if (!this.loading) {
        this.debounce(this.selectAuth, 100, auth);
      }
      this.loading = true;
    },
    async selectAuth(auth) {
      this.loading = true;
      await this.sendPaymentSelectedAuth({ auth, idempotencyKey: this.idempotencyKey });
      this.transitionToSelectingSucceeded();
    },
    pollingTimeout() {
      if (this.polling !== null) {
        this.transitionToFailed();
      }
    },
    pollPaymentIntent() {
      this.polling = setInterval(() => {
        this.requestPaymentsStatus();
      }, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_RATE_SEC);
      setFrozenFriendlyTimeout(this.pollingTimeout, process.env.VUE_APP_PAYMENTS_INTENT_POLLING_TIMEOUT_SEC);
    },
  },
  beforeDestroy() {
    this.clearPolling();
  },
  created() {
    if (this.noAuthAvailable) {
      this.transitionToFailed();
    } else {
      this.pollPaymentIntent();
    }
  },
  mounted() {
    this.idempotencyKey = uuidv1();
  },
  watch: {
    paymentStatus(status) {
      if (status === 'failed') {
        this.transitionToFailed();
      }

      if (status === 'unknown') {
        this.transitionToFailed();
      }
    },
  },
  components: {
    WidgetLayout,
    Footer,
    AuthSelectButton,
    AuthInfo,
    AuthDescription,
  },
};
</script>
