import constants from '../constants';
import StateMachine from '../helpers/state-machine';

const { states, transitions } = constants;

export default function createRefreshIntentStateMachine() {
  return new StateMachine({
    id: 'refresh-intent-state-machine',
    initial: states.REFRESH_INTENT_CREATED,
    states: {
      [states.REFRESH_INTENT_CREATED]: {
        on: {
          [transitions.REFRESH_INTENT_SUCCESSFUL]: states.REFRESH_INTENT_SUCCEEDED,
          [transitions.REFRESH_INTENT_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.REFRESH_INTENT_APP_AUTH_REQUIRED]: states.APP_AUTH_REQUIRED,
          [transitions.REFRESH_INTENT_CAPTCHA_AUTH_REQUIRED]: states.CAPTCHA_AUTH_REQUIRED,
          [transitions.REFRESH_INTENT_DEVICE_AUTH_REQUIRED]: states.DEVICE_AUTH_REQUIRED,
          [transitions.REFRESH_INTENT_CARD_AUTH_REQUIRED]: states.CARD_AUTH_REQUIRED,
          [transitions.REFRESH_INTENT_SMS_AUTH_REQUIRED]: states.SMS_AUTH_REQUIRED,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Conectándonos',
          close: true,
        },
      },
      [states.APP_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_SUCCEEDED]: states.REFRESH_INTENT_SUCCEEDED,
          [transitions.WAITING_ACTION_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.CAPTCHA_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.REFRESH_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: true,
          title: 'Ingresa el captcha',
          close: true,
        },
      },
      [states.DEVICE_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.REFRESH_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
          [transitions.OPEN_TOKEN_HELPER]: states.TOKEN_HELPER,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.CARD_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.REFRESH_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.SMS_AUTH_REQUIRED]: {
        on: {
          [transitions.WAITING_ACTION_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.SECOND_FACTOR_SUCCEEDED]: states.REFRESH_INTENT_PROCESSING_ACTION,
          [transitions.SECOND_FACTOR_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: '',
          close: true,
        },
      },
      [states.TOKEN_HELPER]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.DEVICE_AUTH_REQUIRED,
        },
        header: {
          back: true,
          title: '¿Dónde encuentro el token?',
          close: true,
        },
      },
      [states.REFRESH_INTENT_PROCESSING_ACTION]: {
        on: {
          [transitions.REFRESH_INTENT_SUCCESSFUL]: states.REFRESH_INTENT_SUCCEEDED,
          [transitions.PROCESSING_ACTION_SUCCEEDED]: states.REFRESH_INTENT_FETCHING_DATA,
          [transitions.PROCESSING_ACTION_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Procesando segundo factor',
          close: true,
        },
      },
      [states.REFRESH_INTENT_FETCHING_DATA]: {
        on: {
          [transitions.REFRESH_INTENT_SUCCESSFUL]: states.REFRESH_INTENT_SUCCEEDED,
          [transitions.REFRESH_INTENT_FAILED]: states.REFRESH_INTENT_ERROR,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Obteniendo datos',
          close: true,
        },
      },
      [states.REFRESH_INTENT_SUCCEEDED]: {
        type: 'final',
        header: {
          back: false,
          title: 'Actualización exitosa',
          close: false,
        },
      },
      [states.REFRESH_INTENT_ERROR]: {
        on: {
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Tuvimos un problema',
          close: true,
        },
      },
      [states.WIDGET_CLOSED]: {
        type: 'final',
        header: {
          back: false,
          title: '',
          close: false,
        },
      },
    },
  });
}
