import axiosInstance from './axiosInstance';

const SII = 'cl_fiscal_sii';
const SAT = 'mx_fiscal_sat';

async function getBanks({ publicKey }) {
  return axiosInstance.get('/internal/v1/widget/available_products',
    {
      headers: {
        Authorization: publicKey,
      },
    });
}

const fiscalAuthorities = [
  {
    publicId: SII,
    name: 'Sistema de Impuestos Internos',
    products: {
      invoices: {
        business: true,
        individual: true,
      },
      income: {
        business: true,
        individual: true,
      },
      tax_returns: {
        business: true,
        individual: true,
      },
      tax_statements: {
        business: true,
        individual: true,
      },
    },
  },
  {
    publicId: SAT,
    name: 'Servicio de Administración Tributaria',
    products: {
      invoices: {
        business: true,
        individual: false,
      },
      income: {
        business: false,
        individual: false,
      },
      tax_returns: {
        business: false,
        individual: false,
      },
      tax_statements: {
        business: false,
        individual: false,
      },
    },
  },
];

async function getFiscalAuthorities() {
  return { data: { fiscalAuthorities } };
}

async function getConfig({ publicKey, widgetToken }) {
  const path = '/internal/v1/widget_config';
  const queryParams = widgetToken ? `?widget_token=${widgetToken}` : '';
  return axiosInstance.get(`${path}${queryParams}`,
    {
      headers: {
        Authorization: publicKey,
      },
    });
}

async function getProductBanksConfigs(widgetToken, publicKey, holderType) {
  const path = '/internal/v1/widget/banks_configs';
  return axiosInstance.get(path,
    {
      headers: {
        Authorization: publicKey,
      },
      params: {
        widget_token: widgetToken,
        holder_type: holderType,
      },
    });
}

export default {
  getBanks,
  getFiscalAuthorities,
  getConfig,
  getProductBanksConfigs,
};
