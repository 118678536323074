<template>
  <div class="frame color-bg-warn-surf min-h-18">
      <Info class="left-info-icon text-warning-main" />
      <div>
        <div class="font-XS font-700 color-txt-body"> {{title}} </div>
        <div class="font-XS font-400 color-txt-sec-and-cap"> {{subtitle1}}
          <span class="font-XS font-700 color-txt-sec-and-cap"> {{subtitleBold}} </span>
          <span class="font-XS font-400 color-txt-sec-and-cap"> {{subtitle2}} </span>
        </div>
      </div>
    </div>
</template>

<script>
import Info from '../icons/Info.vue';

export default {
  name: 'InfoWarning',
  components: {
    Info,
  },
  props: {
    title: String,
    subtitle1: String,
    subtitleBold: String,
    subtitle2: String,
  },
};
</script>

<style>

.left-info-icon {
  height: 20px;
  width: 20px;
  margin: 0px 8px 0px 0px;
}

.frame {
  margin: 16px 0px 0px 0px;
  flex-direction: row;
  align-items: top;
  position: static;
  display: flex;
  border-radius: 6px;
  padding: 8px;
}

</style>
