import constants from '../constants';
import StateMachine from '../helpers/state-machine';

const { states, transitions } = constants;

export default function createFiscalLinkStateMachine() {
  return new StateMachine({
    id: 'fiscal-link-state-machine',
    initial: states.INTRODUCTION,
    states: {
      [states.INTRODUCTION]: {
        on: {
          [transitions.OPEN_FISCAL_AUTHORITIES]: states.FISCAL_AUTHORITIES,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: {
            default: (country) => {
              switch (country) {
                case 'mx':
                  return 'Conéctate con el SAT';
                case 'cl':
                  return 'Conéctate con el SII';
                default:
                  return 'Conéctate con tu institución';
              }
            },
          },
          close: true,
        },
        body: {
          institution: (country) => {
            switch (country) {
              case 'mx':
                return 'el SAT';
              case 'cl':
                return 'el SII';
              default:
                return 'tu institución';
            }
          },
          privacyText: 'Tus credenciales no serán compartidas con nadie.',
        },
      },
      [states.FISCAL_AUTHORITIES]: {
        on: {
          [transitions.SELECT_FISCAL_AUTHORITY]: states.LOGIN,
        },
      },
      [states.LOGIN]: {
        on: {
          [transitions.LINK_INTENT_CREATE_SUCCEEDED]: states.LOADING_LINK_INTENT,
          [transitions.LINK_INTENT_CREATE_FAILED]: states.ERROR_VIEW,
          [transitions.GO_BACK]: states.INTRODUCTION,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: true,
          title: 'Ingresa tus credenciales',
          close: true,
        },
      },
      [states.LOADING_LINK_INTENT]: {
        on: {
          [transitions.LINK_INTENT_SUCCESSFUL]: states.LINK_CREATED,
          [transitions.LINK_INTENT_FAILED]: states.ERROR_VIEW,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Conectándonos',
          close: true,
        },
      },
      [states.LINK_CREATED]: {
        type: 'final',
        header: {
          back: false,
          title: 'Conexión exitosa',
          close: false,
        },
      },
      [states.ERROR_VIEW]: {
        on: {
          [transitions.RETRY_LINK_INTENT]: states.LOGIN,
          [transitions.CLOSE_WIDGET]: states.WIDGET_CLOSED,
        },
        header: {
          back: false,
          title: 'Tuvimos un problema',
          close: true,
        },
      },
      [states.WIDGET_CLOSED]: {
        type: 'final',
      },
    },
  });
}
