<template>
  <div class="w-full h-full absolute overflow-hidden rounded-xl will-change-transform">
    <div data-test="drawer-background"
      class=" bg-gray-900 duration-500 ease-out transform absolute inset-0"
      :class="{'opacity-50': showDrawer, 'opacity-0': !showDrawer}"
      @click="closeDrawer"
    />
    <div data-test="drawer-content"
      :class="`absolute w-full bg-white bottom-0 duration-500 ease-out transform rounded-lg min-h-70 ${height}`"
      :style="{'--tw-translate-y': translateY}">
      <slot />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    showDrawer: Boolean,
    height: {
      type: String,
      default: 'h-5/6',
    },
  },
  computed: {
    translateY() {
      return this.showDrawer ? '0%' : '100%';
    },
  },
  methods: {
    closeDrawer() {
      this.$emit('close');
    },
  },
};
</script>
