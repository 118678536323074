const { onlyLettersAndDigits } = require('../../validators/common');

export default {
  methods: {
    hasValidFieldValueStructure(fieldConfig, value) {
      let valid = true;
      const errorMessage = [];

      if (fieldConfig.fixedLength > 0) {
        if (value.length !== fieldConfig.fixedLength) {
          valid = false;
          errorMessage.push(`Input length must be ${fieldConfig.fixedLength}`);
        }
      }

      if (fieldConfig.textType === 'alphanumeric') {
        if (!onlyLettersAndDigits(value)) {
          valid = false;
          errorMessage.push(`Input characters must be only of type ${fieldConfig.textType}`);
        }
      }

      return {
        valid,
        errorMessage,
      };
    },
  },
};
