<template>
  <div class="relative">
    <div class="flex flex-row items-center absolute justify-center h-20 left-0 right-0 z-1">
      <div
        class="flex items-center justify-center"
        :class="isWindowSmall ? 'w-18 min-w-18 h-18 min-h-18': 'w-23 min-w-23 h-23 min-h-23'"
      >
        <LogoBase
          class="z-2 mt-2"
          :backgroundColor="backgroundColor"
        />
        <div
          class="absolute z-3"
          :is="currentComponent"
          :class="isWindowSmall ? 'w-9 min-w-9 h-9 min-h-9 mb-px': 'w-12 min-w-12 h-12 min-h-12 mb-1'"
        />
      </div>
      <FintocShield class="mt-2" :class="isWindowSmall ? 'w-18 min-w-18 mx-6': 'w-23 min-w-23 mx-6'"/>
      <div
        class="flex items-center justify-center"
        :class="isWindowSmall ? 'w-18 min-w-18 h-18 min-h-18': 'w-23 min-w-23 h-23 min-h-23'"
      >
        <LogoBase class="z-2 mt-2" />
        <Bank
          class="absolute z-3"
          :class="isWindowSmall ? 'w-9 min-w-9 h-9 min-h-9 mb-px': 'w-12 min-w-12 h-12 min-h-12 mb-1'"
        />
      </div>
    </div>
    <DottedLine class="z-0 h-20 m-auto"/>
  </div>

</template>

<script>
import Phone from '../../icons/FintocHome/Phone.vue';
import FintocShield from '../../icons/FintocHome/FintocShield.vue';
import Bank from '../../icons/FintocHome/Bank.vue';
import DottedLine from '../../icons/FintocHome/DottedLine.vue';
import LogoBase from '../../icons/FintocHome/LogoBase.vue';

import Fintual from '../../icons/FintocHome/Fintual.vue';
import Cardda from '../../icons/FintocHome/Cardda.vue';
import Rappi from '../../icons/FintocHome/Rappi.vue';
import SoyFocus from '../../icons/FintocHome/SoyFocus.vue';
import Migrante from '../../icons/FintocHome/Migrante.vue';
import Tenpo from '../../icons/FintocHome/Tenpo.vue';
import AsesorSeguros from '../../icons/FintocHome/AsesorSeguros.vue';
import Dale from '../../icons/FintocHome/Dale.vue';
import Teleton from '../../icons/FintocHome/Teleton.vue';

export default {
  name: 'FintocHome',
  props: {
    organizationId: String,
    isWindowSmall: Boolean,
  },
  computed: {
    currentComponent() {
      const icons = {
        '9w5NjMqpFXaZ7BdY': 'Fintual',
        '9w5NjMqpFjaZ7BdY': 'Cardda',
        org_aQXRmZV6F2VZWoxd: 'Rappi',
        org_Ap8r3MWPFBpvlq57: 'SoyFocus',
        org_DjWBZ0XFYOXZ48Nm: 'Migrante',
        org_p8r3MWPF8gWvlq57: 'Tenpo',
        org_jqdnMBOF5QLvgJxK: 'Tenpo',
        eBw18kwJFrevzNLj: 'AsesorSeguros',
        org_oaOxM3EFxpxkmg30: 'Dale',
        org_mg1XvNaF5aRMoJpY: 'Teleton',
      };
      const icon = icons[this.organizationId];
      if (icon !== null && icon !== undefined && this.$options.components[icon]) { return icon; }
      return 'phone';
    },
    backgroundColor() {
      const colors = {
        org_aQXRmZV6F2VZWoxd: 'black',
      };
      const color = colors[this.organizationId];
      return color || 'white';
    },
  },
  components: {
    Phone,
    FintocShield,
    Bank,
    DottedLine,
    LogoBase,
    Fintual,
    Cardda,
    Rappi,
    SoyFocus,
    Migrante,
    Tenpo,
    AsesorSeguros,
    Dale,
    Teleton,
  },
};
</script>
