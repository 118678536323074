<template>
  <widget-layout>
    <div class="outer-container">
      <div class="container-ris">
        <EntityLogo />
        <CheckVerified class="frame-img"/>

        <div class="intro-succeeded mt-6">
          <div class="font-M font-700 color-txt-heading">
            Actualización exitosa
          </div>
          <div class="font-S font-400 color-txt-body text-center marginLR-20">
            Se han obtenido correctamente los movimientos de tu banco.
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="px-5 pt-3">
        <ActionButton
          text="Terminar"
          @click.native="widgetFinished()"
        />
      </div>
      <Footer />
    </template>
  </widget-layout>
</template>

<script>
import { mapActions } from 'vuex';
import WidgetLayout from '../layout/WidgetLayout.vue';
import ActionButton from '../layout/ActionButton.vue';
import Footer from '../layout/Footer.vue';
import EntityLogo from '../layout/EntityLogo.vue';
import CheckVerified from '../icons/CheckVerified.vue';

export default {
  methods: {
    ...mapActions(['widgetFinished']),
  },
  components: {
    ActionButton,
    EntityLogo,
    Footer,
    WidgetLayout,
    CheckVerified,
  },
};
</script>

<style scoped>

.frame-img {
  height: 56px;
}

.intro-succeeded {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  width: 100%;
}

.container-ris {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 20px 0px;
  width: 100%;
}
</style>
