import * as Sentry from '@sentry/vue';
import store from '../store';
import messageTypes from '../../sharedTypes/messages';
import * as messages from './messages';
import errors from '../errors';

async function widgetConfiguration(data) {
  const {
    holderType,
    product,
    publicKey,
    webhookUrl,
    selectedInstitution,
    country,
    username,
    productConfig,
    widgetToken,
  } = data.configuration;

  try {
    await store.dispatch('configurateWidget', {
      holderType, product, publicKey, webhookUrl, selectedInstitution, country, username, productConfig, widgetToken,
    });
  } catch (error) {
    Sentry.captureException(error);
    const message = {};
    if (error instanceof errors.InvalidSelectedInstitution) {
      message.status = null;
      message.metadata = { code: error.code, message: error.message };
    } else if (error.response) {
      message.status = error.response.status;
      message.metadata = error.response.data.error;
    } else if (!error.status) {
      message.status = 500;
      message.metadata = { code: 'service_unavailable', message: 'Service Unavailable' };
    }
    messages.widgetConfigurationInvalid(message);
  }
}

async function receiveMessage(event) {
  if (event.origin === window.origin) { return; }
  const { data } = event;
  switch (data.type) {
    case (messageTypes.WIDGET_CONFIGURATION_REQUEST): {
      widgetConfiguration(data);
      break;
    }
    case (messageTypes.WIDGET_OPENED): {
      store.dispatch('widgetOpened');
      break;
    }
    case (messageTypes.WIDGET_CLOSED_FROM_CLIENT): {
      store.dispatch('widgetClosedFromClient');
      break;
    }
    default: {
      break;
    }
  }
}

export default function setListeners() {
  window.addEventListener('message', receiveMessage, false);
  store.dispatch('sendLoadMessageToParent');
}
