<template>
<div class="flex relative bg-primary-surface rounded-full pl-1.375 py-1 m-4 items-center space-x-2 min-w-18.5">
  <svg class="w-5 h-5 -rotate-90">
    <circle
      class="text-primary-main"
      cx="10"
      cy="10"
      r="8"
      fill="transparent"
      stroke="currentColor"
      stroke-width="3"
    />
    <circle
      cx="10"
      cy="10"
      r="8"
      fill="transparent"
      stroke="white"
      stroke-width="3.3"
      :stroke-dasharray="circumference"
      :stroke-dashoffset="offset"
    />
  </svg>
  <div class="text-body-color font-semibold text-sm"> {{ formattedTime }} </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import utils from '../../helpers/utils';
// import constants from '../../constants';

const { setFrozenFriendlyTimeout } = utils;

const POLLING_TIMEOUT = parseInt(process.env.VUE_APP_SUBSCRIPION_WAITING_FOR_ACTION_TIMEOUT_SEC, 10); // seconds

export default {
  props: {
    inputTime: Number,
  },
  data() {
    return {
      polling: null,
      time: this.inputTime || POLLING_TIMEOUT,
      totalTime: 0,
      radius: 8,
    };
  },
  computed: {
    formattedTime() {
      const { time } = this;
      if (time <= 0) {
        return '0:00';
      }
      const minutes = Math.floor(time / 60);
      let seconds = time % 60;

      if (seconds < 10) {
        seconds = `0${seconds}`;
      }

      return `${minutes}:${seconds}`;
    },
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    progress() {
      return this.circumference * ((this.totalTime - this.time) / this.totalTime);
    },
    offset() {
      return this.circumference - this.progress;
    },
  },
  methods: {
    ...mapActions(['transitionToNextState']),
    setTime() {
      this.time -= 1;
    },
    clearPolling() {
      if (this.polling !== null) {
        clearInterval(this.timerInterval);
        this.polling = null;
      }
    },
    pollingTimeout() {
      if (this.polling !== null) {
        // this.transitionToNextState({ transition: constants.transitions.WAITING_ACTION_FAILED });
      }
      this.clearPolling();
    },
    resetTimer() {
      this.time = this.inputTime || POLLING_TIMEOUT;
      setFrozenFriendlyTimeout(
        this.pollingTimeout,
        POLLING_TIMEOUT * 1000,
        this.resetTimer,
      );
    },
    startTimer() {
      this.totalTime = this.time;
      this.polling = setInterval(this.setTime, 1000);
      setFrozenFriendlyTimeout(
        this.pollingTimeout,
        POLLING_TIMEOUT * 1000,
        this.resetTimer,
      );
    },
  },
  mounted() {
    this.startTimer();
  },
  beforeDestroy() {
    this.clearPolling();
  },
};
</script>
